"use strict";

var _interopRequireDefault = require("/var/jenkins_home/workspace/sihong_test_front_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("/var/jenkins_home/workspace/sihong_test_front_mall/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));
var _createForOfIteratorHelper2 = _interopRequireDefault(require("/var/jenkins_home/workspace/sihong_test_front_mall/node_modules/@babel/runtime-corejs2/helpers/createForOfIteratorHelper.js"));
require("core-js/modules/es6.function.name");
var _ad = require("@/api/ad");
var _storage = require("@/api/storage");
var _auth = require("@/utils/auth");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// Secondary package based on el-pagination
var _default = exports.default = {
  name: 'ProAd',
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      uploadPath: _storage.uploadPath,
      list: [],
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 20,
        name: undefined,
        content: undefined,
        sort: 'add_time',
        order: 'desc'
      },
      dataForm: {
        id: undefined,
        name: undefined,
        content: undefined,
        url: undefined,
        link: undefined,
        position: 1,
        enabled: true
      },
      dialogFormVisible: false,
      dialogStatus: '',
      textMap: {
        update: '编辑',
        create: '创建'
      },
      rules: {
        name: [{
          required: true,
          message: '广告标题不能为空',
          trigger: 'blur'
        }],
        content: [{
          required: true,
          message: '广告内容不能为空',
          trigger: 'blur'
        }],
        url: [{
          required: true,
          message: '广告链接不能为空',
          trigger: 'blur'
        }]
      },
      downloadLoading: false
    };
  },
  computed: {
    headers: function headers() {
      return {
        'Authorization': (0, _auth.getToken)()
      };
    }
  },
  created: function created() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;
      this.listLoading = true;
      (0, _ad.listAd)(this.listQuery).then(function (response) {
        _this.list = response.data.data.list;
        _this.total = response.data.data.total;
        _this.listLoading = false;
      }).catch(function () {
        _this.list = [];
        _this.total = 0;
        _this.listLoading = false;
      });
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    resetForm: function resetForm() {
      this.dataForm = {
        id: undefined,
        name: undefined,
        content: undefined,
        url: undefined,
        link: undefined,
        position: 1,
        enabled: true
      };
    },
    handleCreate: function handleCreate() {
      var _this2 = this;
      this.resetForm();
      this.dialogStatus = 'create';
      this.dialogFormVisible = true;
      this.$nextTick(function () {
        _this2.$refs['dataForm'].clearValidate();
      });
    },
    uploadUrl: function uploadUrl(response) {
      this.dataForm.url = response.data.url;
    },
    checkFileSize: function checkFileSize(file) {
      if (file.size > 1048576) {
        this.$message.error("".concat(file.name, "\u6587\u4EF6\u5927\u4E8E1024KB\uFF0C\u8BF7\u9009\u62E9\u5C0F\u4E8E1024KB\u5927\u5C0F\u7684\u56FE\u7247"));
        return false;
      }
      return true;
    },
    createData: function createData() {
      var _this3 = this;
      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          (0, _ad.createAd)(_this3.dataForm).then(function (response) {
            _this3.list.unshift(response.data.data);
            _this3.dialogFormVisible = false;
            _this3.$notify.success({
              title: '成功',
              message: '创建成功'
            });
          }).catch(function (response) {
            _this3.$notify.error({
              title: '失败',
              message: response.data.errmsg
            });
          });
        }
      });
    },
    handleUpdate: function handleUpdate(row) {
      var _this4 = this;
      this.dataForm = Object.assign({}, row);
      this.dialogStatus = 'update';
      this.dialogFormVisible = true;
      this.$nextTick(function () {
        _this4.$refs['dataForm'].clearValidate();
      });
    },
    updateData: function updateData() {
      var _this5 = this;
      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          (0, _ad.updateAd)(_this5.dataForm).then(function () {
            var _iterator = (0, _createForOfIteratorHelper2.default)(_this5.list),
              _step;
            try {
              for (_iterator.s(); !(_step = _iterator.n()).done;) {
                var v = _step.value;
                if (v.id === _this5.dataForm.id) {
                  var index = _this5.list.indexOf(v);
                  _this5.list.splice(index, 1, _this5.dataForm);
                  break;
                }
              }
            } catch (err) {
              _iterator.e(err);
            } finally {
              _iterator.f();
            }
            _this5.dialogFormVisible = false;
            _this5.$notify.success({
              title: '成功',
              message: '更新广告成功'
            });
          }).catch(function (response) {
            _this5.$notify.error({
              title: '失败',
              message: response.data.errmsg
            });
          });
        }
      });
    },
    handleDelete: function handleDelete(row) {
      var _this6 = this;
      this.$confirm('是否要进行删除操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _ad.deleteAd)(row).then(function (response) {
          _this6.$notify.success({
            title: '成功',
            message: '删除成功'
          });
          var index = _this6.list.indexOf(row);
          _this6.list.splice(index, 1);
        }).catch(function (response) {
          _this6.$notify.error({
            title: '失败',
            message: response.data.errmsg
          });
        });
      });
    },
    handleDownload: function handleDownload() {
      var _this7 = this;
      this.downloadLoading = true;
      Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/vendor/Export2Excel'));
      }).then(function (excel) {
        var tHeader = ['广告ID', '广告标题', '广告内容', '广告图片', '广告位置', '活动链接', '是否启用'];
        var filterVal = ['id', 'name', 'content', 'url', 'postion', 'link', 'enabled'];
        excel.export_json_to_excel2(tHeader, _this7.list, filterVal, '广告信息');
        _this7.downloadLoading = false;
      });
    }
  }
};