"use strict";

var _interopRequireDefault = require("/var/jenkins_home/workspace/sihong_test_front_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.constantRouterMap = exports.asyncRouterMap = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("/var/jenkins_home/workspace/sihong_test_front_mall/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));
var _vue = _interopRequireDefault(require("vue"));
var _vueRouter = _interopRequireDefault(require("vue-router"));
var _Layout = _interopRequireDefault(require("@/views/layout/Layout"));
_vue.default.use(_vueRouter.default);

/* Layout */

/** note: Submenu only appear when children.length>=1
 *  detail see  https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 **/

/**
* hidden: true                   if `hidden:true` will not show in the sidebar(default is false)
* alwaysShow: true               if set true, will always show the root menu, whatever its child routes length
*                                if not set alwaysShow, only more than one route under the children
*                                it will becomes nested mode, otherwise not show the root menu
* redirect: noredirect           if `redirect:noredirect` will no redirect in the breadcrumb
* name:'router-name'             the name is used by <keep-alive> (must set!!!)
* meta : {
    perms: ['GET /aaa','POST /bbb']     will control the page perms (you can set multiple perms)
    title: 'title'               the name show in submenu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar,
    noCache: true                if true ,the page will no be cached(default is false)
  }
**/
var constantRouterMap = exports.constantRouterMap = [{
  path: '/redirect',
  component: _Layout.default,
  hidden: true,
  children: [{
    path: '/redirect/:path*',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/redirect/index'));
      });
    }
  }]
}, {
  path: '/login',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/login/index'));
    });
  },
  hidden: true
}, {
  path: '/auth-redirect',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/login/authredirect'));
    });
  },
  hidden: true
}, {
  path: '/404',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/errorPage/404'));
    });
  },
  hidden: true
}, {
  path: '/401',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/errorPage/401'));
    });
  },
  hidden: true
}, {
  path: '',
  component: _Layout.default,
  redirect: 'dashboard',
  children: [{
    path: 'dashboard',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/dashboard/index'));
      });
    },
    name: 'Dashboard',
    meta: {
      title: '首页',
      icon: 'dashboard',
      affix: true
    }
  }]
}];
var _default = exports.default = new _vueRouter.default({
  // mode: 'history', // require service support
  scrollBehavior: function scrollBehavior() {
    return {
      y: 0
    };
  },
  routes: constantRouterMap
});
var asyncRouterMap = exports.asyncRouterMap = [{
  path: '/user',
  component: _Layout.default,
  redirect: 'noredirect',
  alwaysShow: true,
  name: 'userManage',
  meta: {
    title: '用户管理',
    icon: 'chart'
  },
  children: [{
    path: 'user',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/user/user'));
      });
    },
    name: 'UserList',
    meta: {
      perms: ['GET /admin/user/list'],
      title: '会员管理',
      noCache: true
    }
  }, {
    path: 'card',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/user/card'));
      });
    },
    name: 'UserCard',
    meta: {
      perms: ['POST /admin/card/list'],
      title: '名片管理',
      noCache: true
    }
  }, {
    path: 'address',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/user/address'));
      });
    },
    name: 'UserAddress',
    meta: {
      perms: ['GET /admin/address/list'],
      title: '收货地址',
      noCache: true
    }
  }, {
    path: 'collect',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/user/collect'));
      });
    },
    name: 'UserCollect',
    meta: {
      perms: ['GET /admin/collect/list'],
      title: '会员收藏',
      noCache: true
    }
  }, {
    path: 'footprint',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/user/footprint'));
      });
    },
    name: 'UserFootprint',
    meta: {
      perms: ['GET /admin/footprint/list'],
      title: '会员足迹',
      noCache: true
    }
  }, {
    path: 'history',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/user/history'));
      });
    },
    name: 'UserHistory',
    meta: {
      perms: ['GET /admin/history/list'],
      title: '搜索历史',
      noCache: true
    }
  }, {
    path: 'feedback',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/user/feedback'));
      });
    },
    name: 'UserFeedback',
    meta: {
      perms: ['GET /admin/feedback/list'],
      title: '意见反馈',
      noCache: true
    }
  }]
}, {
  path: '/mall',
  component: _Layout.default,
  redirect: 'noredirect',
  alwaysShow: true,
  name: 'mallManage',
  meta: {
    title: '商城管理',
    icon: 'chart'
  },
  children: [{
    path: 'region',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/mall/region'));
      });
    },
    name: 'MallRegion',
    meta: {
      perms: ['GET /admin/region/list'],
      title: '行政区域',
      noCache: true
    }
  }, {
    path: 'brand',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/mall/brand'));
      });
    },
    name: 'MallBrand',
    meta: {
      perms: ['GET /admin/brand/list', 'POST /admin/brand/create', 'GET /admin/brand/read', 'POST /admin/brand/update', 'POST /admin/brand/delete'],
      title: '品牌制造商',
      noCache: true
    }
  }, {
    path: 'category',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/mall/category'));
      });
    },
    name: 'MallCategory',
    meta: {
      perms: ['GET /admin/category/list', 'POST /admin/category/create', 'GET /admin/category/read', 'POST /admin/category/update', 'POST /admin/category/delete'],
      title: '商品类目',
      noCache: true
    }
  }, {
    path: 'order',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/mall/order'));
      });
    },
    name: 'MallOrder',
    meta: {
      perms: ['GET /admin/order/list', 'GET /admin/order/detail', 'POST /admin/order/ship', 'POST /admin/order/refund', 'POST /admin/order/delete', 'POST /admin/order/reply'],
      title: '订单管理',
      noCache: true
    }
  }, {
    path: 'orderdetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/mall/orderdetail'));
      });
    },
    name: 'MallOrderDetail',
    meta: {
      perms: ['GET /admin/order/detail'],
      title: '订单详情',
      noCache: true
    },
    hidden: true
  }, {
    path: 'aftersale',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/mall/aftersale'));
      });
    },
    name: 'MallAftersale',
    meta: {
      perms: ['GET /admin/aftersale/list', 'GET /admin/aftersale/detail', 'POST /admin/order/receive', 'POST /admin/aftersale/complete', 'POST /admin/aftersale/reject'],
      title: '售后管理',
      noCache: true
    }
  }, {
    path: 'aftersaledetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/mall/aftersaledetail'));
      });
    },
    name: 'MallAftersaleDetail',
    meta: {
      perms: ['GET /admin/aftersale/detail'],
      title: '售后详情',
      noCache: true
    },
    hidden: true
  }, {
    path: 'issue',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/mall/issue'));
      });
    },
    name: 'MallIssue',
    meta: {
      perms: ['GET /admin/issue/list', 'POST /admin/issue/create', 'GET /admin/issue/read', 'POST /admin/issue/update', 'POST /admin/issue/delete'],
      title: '通用问题',
      noCache: true
    }
  }, {
    path: 'keyword',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/mall/keyword'));
      });
    },
    name: 'MallKeyword',
    meta: {
      perms: ['GET /admin/keyword/list', 'POST /admin/keyword/create', 'GET /admin/keyword/read', 'POST /admin/keyword/update', 'POST /admin/keyword/delete'],
      title: '关键词',
      noCache: true
    }
  }, {
    path: 'colorspec',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/mall/colorSpecifications'));
      });
    },
    name: 'MallColorSpecifications',
    meta: {
      perms: ['POST /admin/spec/list', 'POST /admin/spec/read', 'POST /admin/spec/update', 'POST /admin/spec/delete', 'POST /admin/spec/create'],
      title: '颜色规格',
      noCache: true
    }
  }, {
    path: 'spec',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/mall/spec'));
      });
    },
    name: 'MallSpec',
    meta: {
      perms: ['POST /admin/spec/list', 'POST /admin/spec/read', 'POST /admin/spec/update', 'POST /admin/spec/delete', 'POST /admin/spec/createSpecMultiLang'],
      title: '尺寸规格',
      noCache: true
    }
  }]
}, {
  path: '/wareHouse',
  component: _Layout.default,
  redirect: 'noredirect',
  alwaysShow: true,
  name: 'wareHouseManage',
  meta: {
    title: '仓库管理',
    icon: 'chart'
  },
  children: [{
    path: 'houseList',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/wareHouse/houseList.vue'));
      });
    },
    name: 'wareHouseList',
    meta: {
      perms: ['POST /admin/goods/inventoryinbound', 'POST /admin/goods/inventoryoutbound', 'POST /admin/goods/inventorytransfer', 'POST /admin/goods/inventorylist'],
      title: '仓库列表',
      noCache: true
    }
  }, {
    path: 'houseListDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/wareHouse/houseListDetail.vue'));
      });
    },
    name: 'houseListDetail',
    meta: {
      perms: ['POST /admin/goods/inventoryinbound', 'POST /admin/goods/inventoryoutbound', 'POST /admin/goods/inventorytransfer', 'POST /admin/goods/inventorylist'],
      title: '仓库详情',
      noCache: true
    },
    hidden: true
  }, {
    path: 'invertory',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/wareHouse/inventory.vue'));
      });
    },
    name: 'wareInvertory',
    meta: {
      perms: ['POST /admin/goods/inventorylistBill'],
      title: '流水查询',
      noCache: true
    }
  }, {
    path: 'productReturn',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/wareHouse/productReturn.vue'));
      });
    },
    name: 'wareProduct',
    meta: {
      perms: ['POST /admin/goods/inventorylistReturn'],
      title: '商品归还',
      noCache: true
    }
  }, {
    path: 'recordQuery',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/wareHouse/recordQuery.vue'));
      });
    },
    name: 'recordQuery',
    meta: {
      perms: ['POST /admin/goods/inventorylistReturn'],
      title: '记录查询',
      noCache: true
    },
    hidden: true
  }]
}, {
  path: '/goods',
  component: _Layout.default,
  redirect: 'noredirect',
  alwaysShow: true,
  name: 'goodsManage',
  meta: {
    title: '商品管理',
    icon: 'chart'
  },
  children: [{
    path: 'list',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/goods/list'));
      });
    },
    name: 'GoodsList',
    meta: {
      perms: ['GET /admin/goods/list', 'POST /admin/goods/delete'],
      title: '商品列表',
      noCache: false
    }
  }, {
    path: 'create',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/goods/create'));
      });
    },
    name: 'GoodsCreate',
    meta: {
      perms: ['POST /admin/goods/create'],
      title: '商品上架',
      noCache: false
    }
  }, {
    path: 'edit',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/goods/edit'));
      });
    },
    name: 'GoodsEdit',
    meta: {
      perms: ['GET /admin/goods/detail', 'POST /admin/goods/update', 'POST /admin/goods/catAndBrand'],
      title: '商品编辑',
      noCache: true
    },
    hidden: true
  }, {
    path: 'comment',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/goods/comment'));
      });
    },
    name: 'GoodsComment',
    meta: {
      perms: ['GET /admin/comment/list', 'POST /admin/comment/delete'],
      title: '商品评论',
      noCache: true
    }
  }, {
    path: 'stock',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/goods/Stock'));
      });
    },
    name: 'GoodsStock',
    meta: {
      perms: ['GET /admin/comment/list', 'POST /admin/comment/delete'],
      title: '商品库存',
      noCache: true
    }
  }
  // {
  //   path: 'test',
  //   component: () => import('@/views/goods/test'),
  //   name: 'goodstest',
  //   meta: {
  //     perms: ['POST /admin/goods/test'],
  //     title: '测试',
  //     noCache: false
  //   }
  // },
  ]
}, {
  path: '/promotion',
  component: _Layout.default,
  redirect: 'noredirect',
  alwaysShow: true,
  name: 'promotionManage',
  meta: {
    title: '推广管理',
    icon: 'chart'
  },
  children: [{
    path: 'ad',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/promotion/ad'));
      });
    },
    name: 'ProAd',
    meta: {
      perms: ['GET /admin/ad/list', 'POST /admin/ad/create', 'GET /admin/ad/read', 'POST /admin/ad/update', 'POST /admin/ad/delete'],
      title: '广告管理',
      noCache: true
    }
  }, {
    path: 'coupon',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/promotion/coupon'));
      });
    },
    name: 'ProCoupon',
    meta: {
      perms: ['GET /admin/coupon/list', 'POST /admin/coupon/create', 'POST /admin/coupon/update', 'POST /admin/coupon/delete'],
      title: '优惠券管理',
      noCache: true
    }
  }, {
    path: 'couponDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/promotion/couponDetail'));
      });
    },
    name: 'ProCouponDetail',
    meta: {
      perms: ['GET /admin/coupon/list', 'GET /admin/coupon/listuser'],
      title: '优惠券详情',
      noCache: true
    },
    hidden: true
  }, {
    path: 'topic',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/promotion/topic'));
      });
    },
    name: 'ProTopic',
    meta: {
      perms: ['GET /admin/topic/list', 'POST /admin/topic/create', 'GET /admin/topic/read', 'POST /admin/topic/update', 'POST /admin/topic/delete'],
      title: '专题管理',
      noCache: true
    }
  }, {
    path: 'topic-create',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/promotion/topicCreate'));
      });
    },
    name: 'ProTopicCreate',
    meta: {
      perms: ['POST /admin/topic/create'],
      title: '专题创建',
      noCache: true
    },
    hidden: true
  }, {
    path: 'topic-edit',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/promotion/topicEdit'));
      });
    },
    name: 'ProTopicEdit',
    meta: {
      perms: ['GET /admin/topic/read', 'POST /admin/topic/update'],
      title: '专题编辑',
      noCache: true
    },
    hidden: true
  }, {
    path: 'groupon-rule',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/promotion/grouponRule'));
      });
    },
    name: 'ProGrouponRule',
    meta: {
      perms: ['GET /admin/groupon/list', 'POST /admin/groupon/create', 'POST /admin/groupon/update', 'POST /admin/groupon/delete'],
      title: '团购规则',
      noCache: true
    }
  }, {
    path: 'groupon-activity',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/promotion/grouponActivity'));
      });
    },
    name: 'ProGrouponActivity',
    meta: {
      perms: ['GET /admin/groupon/listRecord'],
      title: '团购活动',
      noCache: true
    }
  }]
}, {
  path: '/select',
  component: _Layout.default,
  redirect: 'noredirect',
  alwaysShow: true,
  name: 'selectManage',
  meta: {
    title: '选款管理',
    icon: 'chart'
  },
  children: [{
    path: 'anthology',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/select/anthology'));
      });
    },
    name: 'SelectAnthology',
    meta: {
      perms: ['GET /admin/select/anthology'],
      title: '选集管理',
      noCache: true
    }
  }, {
    path: 'addAnthology',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/select/anthologyDetail'));
      });
    },
    name: 'SelectAnthologyDetail',
    meta: {
      perms: ['GET /admin/select/anthologyDetail'],
      title: '添加选集',
      noCache: false
    },
    hidden: true
  }, {
    path: 'editAnthology',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/select/anthologyDetail'));
      });
    },
    name: 'SelectAnthologyDetail',
    meta: {
      perms: ['GET /admin/select/anthologyDetail'],
      title: '编辑选集',
      noCache: false
    },
    hidden: true
  }, {
    path: 'lookAnthology',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/select/anthologyDetail'));
      });
    },
    name: 'SelectAnthologyDetail',
    meta: {
      perms: ['GET /admin/select/anthologyDetail'],
      title: '查看选集',
      noCache: false
    },
    hidden: true
  }, {
    path: 'company',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/select/company'));
      });
    },
    name: 'SelectCompany',
    meta: {
      perms: ['GET /admin/select/company'],
      title: '公司简介',
      noCache: true
    }
  }, {
    path: 'authSet',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/select/authSet'));
      });
    },
    name: 'SelectAuthSet',
    meta: {
      perms: ['GET /admin/select/authSet'],
      title: '入口配置',
      noCache: true
    }
  }]
}, {
  path: '/sys',
  component: _Layout.default,
  redirect: 'noredirect',
  alwaysShow: true,
  name: 'sysManage',
  meta: {
    title: '系统管理',
    icon: 'chart'
  },
  children: [{
    path: 'admin',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/sys/admin'));
      });
    },
    name: 'SysAdmin',
    meta: {
      perms: ['GET /admin/admin/list', 'POST /admin/admin/create', 'POST /admin/admin/update', 'POST /admin/admin/delete'],
      title: '管理员',
      noCache: true
    }
  }, {
    path: 'notice',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/sys/notice'));
      });
    },
    name: 'SysNotice',
    meta: {
      perms: ['GET /admin/notice/list', 'POST /admin/notice/create', 'POST /admin/notice/update', 'POST /admin/notice/delete'],
      title: '通知管理',
      noCache: true
    }
  }, {
    path: 'log',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/sys/log'));
      });
    },
    name: 'SysLog',
    meta: {
      perms: ['GET /admin/log/list'],
      title: '操作日志',
      noCache: true
    }
  }, {
    path: 'role',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/sys/role'));
      });
    },
    name: 'SysRole',
    meta: {
      perms: ['GET /admin/role/list', 'POST /admin/role/create', 'POST /admin/role/update', 'POST /admin/role/delete', 'GET /admin/role/permissions', 'POST /admin/role/permissions'],
      title: '角色管理',
      noCache: true
    }
  }, {
    path: 'os',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/sys/os'));
      });
    },
    name: 'SysOs',
    meta: {
      perms: ['GET /admin/storage/list', 'POST /admin/storage/create', 'POST /admin/storage/update', 'POST /admin/storage/delete'],
      title: '对象存储',
      noCache: true
    }
  }]
}, {
  path: '/config',
  component: _Layout.default,
  redirect: 'noredirect',
  alwaysShow: true,
  name: 'configManage',
  meta: {
    title: '配置管理',
    icon: 'chart'
  },
  children: [{
    path: 'mall',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/config/mall'));
      });
    },
    name: 'ConfigMall',
    meta: {
      perms: ['GET /admin/config/mall', 'POST /admin/config/mall'],
      title: '商场配置',
      noCache: true
    }
  }, {
    path: 'express',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/config/express'));
      });
    },
    name: 'ConfigExpress',
    meta: {
      perms: ['GET /admin/config/express', 'POST /admin/config/express'],
      title: '运费配置',
      noCache: true
    }
  }, {
    path: 'order',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/config/order'));
      });
    },
    name: 'ConfigOrder',
    meta: {
      perms: ['GET /admin/config/order', 'POST /admin/config/order'],
      title: '订单配置',
      noCache: true
    }
  }, {
    path: 'wx',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/config/wx'));
      });
    },
    name: 'ConfigWx',
    meta: {
      perms: ['GET /admin/config/wx', 'POST /admin/config/wx'],
      title: '小程序配置',
      noCache: true
    }
  }, {
    path: 'level',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/config/level'));
      });
    },
    name: 'ConfigLevel',
    meta: {
      perms: ['GET /admin/config/level', 'POST /admin/config/level'],
      title: '等级配置',
      noCache: true
    }
  }, {
    path: 'ladder',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/config/ladder'));
      });
    },
    name: 'ConfigLadder',
    meta: {
      perms: ['GET /admin/config/ladder', 'POST /admin/config/ladder'],
      title: '价格配置',
      noCache: true
    }
  }, {
    path: 'printTemplateConfig',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/config/printTemplateConfig'));
      });
    },
    name: 'TemplateDesign',
    meta: {
      perms: ['POST /admin/config/printTemplate'],
      title: '打印模板配置',
      noCache: true
    }
  }, {
    path: 'EmailTemplateConfig',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/config/EmailTemplateConfig'));
      });
    },
    name: 'TemplateDesign',
    meta: {
      perms: ['POST /admin/email/template/page', 'POST /admin/email/template/save', 'GET /admin/email/template/delete', 'POST /admin/email/template/listScene'],
      title: '邮件模板配置',
      noCache: true
    }
  }, {
    path: 'EmailEnv',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/config/EmailEnv'));
      });
    },
    name: 'TemplateDesign',
    meta: {
      perms: ['POST /admin/email/template/save', 'GET /admin/email/template/getById', 'POST /admin/email/template/listScene'],
      title: '模板应用场景',
      noCache: true
    }
  }, {
    path: 'EmailTemplateDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/config/EmailTemplateDetail'));
      });
    },
    name: 'TemplateDesign',
    meta: {
      perms: ['POST /admin/email/template/save', 'GET /admin/email/template/getById', 'POST /admin/email/template/listScene'],
      title: '邮件模板详情',
      noCache: true
    },
    hidden: true
  },
  // {
  //   path: 'address',
  //   component: () => import('@/views/config/address'),
  //   name: 'SelectAuthSet',
  //   meta: {
  //     perms: ['GET /admin/config/address'],
  //     title: '商家地址管理',
  //     noCache: true
  //   }
  // }
  {
    path: 'dictionary',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/config/dictionary.vue'));
      });
    },
    name: 'TemplateDesign',
    meta: {
      perms: ['POST /admin/dict/setCategory'],
      title: '数据字典',
      noCache: true
    }
  }]
}, {
  path: '/stat',
  component: _Layout.default,
  redirect: 'noredirect',
  alwaysShow: true,
  name: 'statManage',
  meta: {
    title: '统计报表',
    icon: 'chart'
  },
  children: [{
    path: 'user',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/stat/user'));
      });
    },
    name: 'StatUser',
    meta: {
      perms: ['GET /admin/stat/user'],
      title: '用户统计',
      noCache: true
    }
  }, {
    path: 'order',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/stat/order'));
      });
    },
    name: 'StatOrder',
    meta: {
      perms: ['GET /admin/stat/order'],
      title: '订单统计',
      noCache: true
    }
  }, {
    path: 'goods',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/stat/goods'));
      });
    },
    name: 'StatGoods',
    meta: {
      perms: ['GET /admin/stat/goods'],
      title: '商品统计',
      noCache: true
    }
  }]
}, {
  path: '/content',
  component: _Layout.default,
  redirect: 'noredirect',
  alwaysShow: true,
  name: 'contManage',
  meta: {
    title: '内容管理',
    icon: 'chart'
  },
  children: [{
    path: '/index/banner',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/cont/Banner'));
      });
    },
    name: 'bannerIndex',
    meta: {
      perms: ['GET /admin/cms/delete', 'POST /admin/cms/update'],
      title: 'Banner管理(首页供应链)',
      noCache: true
    }
  }, {
    path: '/index/product',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/cont/index/product'));
      });
    },
    name: 'productIndex',
    meta: {
      perms: ['POST /admin/cms/create', 'GET /admin/cms/delete', 'POST /admin/cms/update'],
      title: '首页-产品',
      noCache: true,
      btn: [{
        value: '2',
        tit: '新增-成衣'
      }, {
        value: '3',
        tit: '新增-纱线'
      }, {
        value: '4',
        tit: '新增-面料'
      }]
    }
  }, {
    path: '/index/designer',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/cont/index/product'));
      });
    },
    name: 'designerIndex',
    meta: {
      perms: ['GET /admin/cms/delete', 'POST /admin/cms/update'],
      title: '首页-设计师',
      noCache: true,
      btn: [{
        value: '5',
        tit: '新增-日韩设计师'
      }, {
        value: '6',
        tit: '新增-中国设计师'
      }, {
        value: '7',
        tit: '新增-欧美设计师'
      }]
    }
  }, {
    path: '/index/environment',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/cont/index/product'));
      });
    },
    name: 'environmentIndex',
    meta: {
      perms: ['GET /admin/cms/delete', 'POST /admin/cms/update'],
      title: '首页-可持续',
      noCache: true,
      btn: [{
        value: '9',
        tit: '首页-环境'
      }, {
        value: '10',
        tit: '首页-行业'
      }, {
        value: '11',
        tit: '首页-企业'
      }, {
        value: '12',
        tit: '首页-图文'
      }]
    }
  }, {
    path: '/trends/wei',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/cont/index/wei'));
      });
    },
    name: 'weiList',
    meta: {
      perms: ['POST /admin/cms/create', 'GET /admin/cms/delete', 'POST /admin/cms/update'],
      title: '首页-微博-微信',
      noCache: true
    }
  }, {
    path: '/trends/list',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/cont/trends/list'));
      });
    },
    name: 'trendsList',
    meta: {
      perms: ['POST /admin/cms/create', 'GET /admin/cms/delete', 'POST /admin/cms/update'],
      title: '潮流管理',
      noCache: true,
      type: 18
    }
  }, {
    path: '/trends/event',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/cont/trends/list'));
      });
    },
    name: 'eventsList',
    meta: {
      perms: ['POST /admin/cms/create', 'GET /admin/cms/delete', 'POST /admin/cms/update'],
      title: '企业活动',
      noCache: true,
      type: 28
    }
  }, {
    path: '/trends/fashion',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/cont/trends/fashion'));
      });
    },
    name: 'fashion',
    meta: {
      perms: ['POST /admin/cms/create', 'GET /admin/cms/delete', 'POST /admin/cms/update'],
      title: '时装秀-介绍图',
      noCache: true
    }
  }, {
    path: '/trends/fashion-now',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/cont/trends/list'));
      });
    },
    name: 'fashion-now',
    meta: {
      perms: ['POST /admin/cms/create', 'GET /admin/cms/delete', 'POST /admin/cms/update'],
      title: '时装秀-now',
      noCache: true,
      type: 23
    }
  }, {
    path: '/trends/fashion-mans',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/cont/trends/list'));
      });
    },
    name: 'fashion-mans',
    meta: {
      perms: ['POST /admin/cms/create', 'GET /admin/cms/delete', 'POST /admin/cms/update'],
      title: '时装秀-mans',
      noCache: true,
      type: 24
    }
  }, {
    path: '/trends/fashion-woman',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/cont/trends/list'));
      });
    },
    name: 'fashion-woman',
    meta: {
      perms: ['POST /admin/cms/create', 'GET /admin/cms/delete', 'POST /admin/cms/update'],
      title: '时装秀-woman',
      noCache: true,
      type: 25
    }
  }, {
    path: '/trends/fashion-other',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/cont/trends/list'));
      });
    },
    name: 'fashion-other',
    meta: {
      perms: ['POST /admin/cms/create', 'GET /admin/cms/delete', 'POST /admin/cms/update'],
      title: '时装秀-other',
      noCache: true,
      type: 26
    }
  }, {
    path: '/index/about',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/cont/index/product'));
      });
    },
    name: 'abputIndex',
    meta: {
      perms: ['POST /admin/cms/create', 'GET /admin/cms/delete', 'POST /admin/cms/update'],
      // perms: ['GET /admin/stat/user'],
      title: '关于我们(使命、总部）',
      noCache: true,
      btn: [{
        value: '30',
        tit: '新增-使命'
      }, {
        value: '32',
        tit: '新增-总部'
      }]
    }
  }, {
    path: '/index/partner',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/cont/about/partners'));
      });
    },
    name: 'paIndex',
    meta: {
      perms: ['POST /admin/cms/create', 'GET /admin/cms/delete', 'POST /admin/cms/update'],
      // perms: ['GET /admin/stat/user'],
      title: '关于我们-合作伙伴',
      noCache: true
    }
  }, {
    path: '/index/list',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/cont/about/picList'));
      });
    },
    name: 'listIndex',
    meta: {
      perms: ['POST /admin/cms/create', 'GET /admin/cms/delete', 'POST /admin/cms/update'],
      // perms: ['GET /admin/stat/user'],
      title: '关于我们-图片列表',
      noCache: true
    }
  }, {
    path: '/trends/faq',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/cont/faq'));
      });
    },
    name: 'weiList',
    meta: {
      perms: ['POST /admin/cms/create', 'GET /admin/cms/delete', 'POST /admin/cms/update'],
      title: '常见问题',
      noCache: true
    }
  }, {
    path: '/trends/bo',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/cont/trends/list'));
      });
    },
    name: 'eventsList',
    meta: {
      perms: ['POST /admin/cms/create', 'GET /admin/cms/delete', 'POST /admin/cms/update'],
      title: '社交平台iframe-微博',
      noCache: true,
      type: 34
    }
  }, {
    path: '/trends/xin',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/cont/trends/list'));
      });
    },
    name: 'eventsList',
    meta: {
      perms: ['POST /admin/cms/create', 'GET /admin/cms/delete', 'POST /admin/cms/update'],
      title: '社交平台iframe-微信',
      noCache: true,
      type: 35
    }
  }, {
    path: '/equity',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/cont/equity.vue'));
      });
    },
    name: 'equity',
    meta: {
      // perms: ['GET /admin/stat/user'],
      perms: ['POST /admin/cms/create', 'GET /admin/cms/delete', 'POST /admin/cms/update'],
      title: '权益政策',
      noCache: true,
      btn: [{
        value: '38',
        tit: '隐私声明'
      }, {
        value: '39',
        tit: '用户协议'
      }, {
        value: '40',
        tit: '服务条款'
      }, {
        value: '41',
        tit: '隐私政策'
      }, {
        value: '42',
        tit: 'Cookie政策'
      }]
    }
  }]
}, {
  path: '/rulesManager',
  component: _Layout.default,
  redirect: 'noredirect',
  children: [{
    path: 'index',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/rulesManager/Index.vue'));
      });
    },
    name: 'RulesManager',
    meta: {
      title: '规则管理',
      perms: ['GET /admin/rule/listMenu'],
      icon: 'chart',
      affix: true
    }
  }]
}, {
  path: '/exchangeRate',
  component: _Layout.default,
  redirect: 'noredirect',
  children: [{
    path: 'index',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/exchangeRate/index.vue'));
      });
    },
    name: 'ExchangeRate',
    meta: {
      title: '汇率',
      perms: ['GET /admin/currency/currencyRatesList'],
      icon: 'chart',
      affix: true
    }
  }]
}, {
  path: '/returnRefund',
  component: _Layout.default,
  redirect: 'noredirect',
  children: [{
    path: 'index',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/returnRefund/index.vue'));
      });
    },
    name: 'ReturnRefund',
    meta: {
      title: '退货退款',
      perms: ['GET /admin/refund/list'],
      icon: 'chart',
      affix: true
    }
  }, {
    path: 'detail/:id',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/returnRefund/detail.vue'));
      });
    },
    name: 'ReturnRefundDetail',
    meta: {
      title: '退货退款详情',
      noCache: true
    },
    hidden: true
  }]
},
// {
//   path: 'external-link',
//   component: Layout,
//   redirect: 'noredirect',
//   alwaysShow: true,
//   name: 'externalLink',
//   meta: {
//     title: '外链',
//     icon: 'link'
//   },
//   children: [
//     {
//       path: 'https://cloud.tencent.com/product/cos',
//       meta: { title: '腾讯云存储', icon: 'link' }
//     },
//     {
//       path: 'https://cloud.tencent.com/product/sms',
//       meta: { title: '腾讯云短信', icon: 'link' }
//     },
//     {
//       path: 'https://pay.weixin.qq.com/index.php/core/home/login',
//       meta: { title: '微信支付', icon: 'link' }
//     },
//     {
//       path: 'https://mpkf.weixin.qq.com/',
//       meta: { title: '小程序客服', icon: 'link' }
//     },
//     {
//       path: 'https://www.alibabacloud.com/zh/product/oss',
//       meta: { title: '阿里云存储', icon: 'link' }
//     },
//     {
//       path: 'https://www.qiniu.com/products/kodo',
//       meta: { title: '七牛云存储', icon: 'link' }
//     },
//     {
//       path: 'http://www.kdniao.com/api-track',
//       meta: { title: '快递鸟', icon: 'link' }
//     }
//   ]
// },
{
  path: '/profile',
  component: _Layout.default,
  redirect: 'noredirect',
  alwaysShow: true,
  children: [{
    path: 'password',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/profile/password'));
      });
    },
    name: 'ProfilePassword',
    meta: {
      title: '修改密码',
      noCache: true
    }
  }, {
    path: 'notice',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/profile/notice'));
      });
    },
    name: 'ProfileNotice',
    meta: {
      title: '通知中心',
      noCache: true
    }
  }],
  hidden: true
}, {
  path: '*',
  redirect: '/404',
  hidden: true
}];