"use strict";

var _interopRequireDefault = require("/var/jenkins_home/workspace/sihong_test_front_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.cmsUpdateOrSaveBatch = cmsUpdateOrSaveBatch;
exports.deleteApi = deleteApi;
exports.getTagList = getTagList;
exports.languageTranslation = languageTranslation;
exports.listApi = listApi;
exports.pageApi = pageApi;
exports.tagCreate = tagCreate;
exports.tagDelete = tagDelete;
exports.trendsCreateApi = trendsCreateApi;
exports.updateApi = updateApi;
exports.updateOrSaveBatch = updateOrSaveBatch;
var _request = _interopRequireDefault(require("@/utils/request"));
var _request2 = _interopRequireDefault(require("@/utils/request2"));
function trendsCreateApi(data) {
  return (0, _request.default)({
    url: '/cms/create',
    method: 'post',
    data: data
  });
}
function listApi(params) {
  return (0, _request.default)({
    url: '/cms/list',
    method: 'get',
    params: params
  });
}
// 分页
function pageApi(params) {
  return (0, _request.default)({
    url: '/cms/page',
    method: 'get',
    params: params
  });
}
// 编辑
function updateApi(data) {
  return (0, _request.default)({
    url: '/cms/update',
    method: 'post',
    data: data
  });
}
// 删除
function deleteApi(params) {
  return (0, _request.default)({
    url: '/cms/delete',
    method: 'get',
    params: params
  });
}
// 一键自动翻译
function languageTranslation(params) {
  return (0, _request2.default)({
    method: 'get',
    params: params,
    url: '/translate/fy'
  });
}

// 新建标签
function tagCreate(data) {
  return (0, _request2.default)({
    method: 'post',
    data: data,
    url: '/tag/createOrUpdate'
  });
}

// 新建标签
function getTagList(params) {
  return (0, _request2.default)({
    method: 'get',
    params: params,
    url: '/tag/list'
  });
}

// 更新标签
function updateOrSaveBatch(data) {
  return (0, _request2.default)({
    method: 'post',
    data: data,
    url: '/tag/updateOrSaveBatch'
  });
}

// 更新标签
function tagDelete(params) {
  return (0, _request2.default)({
    method: 'get',
    params: params,
    url: '/tag/delete'
  });
}

// 更新标签
function cmsUpdateOrSaveBatch(data) {
  return (0, _request2.default)({
    method: 'post',
    data: data,
    url: '/cms/updateOrSaveBatch'
  });
}