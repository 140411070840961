"use strict";

var _interopRequireDefault = require("/var/jenkins_home/workspace/sihong_test_front_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.search");
var _objectSpread2 = _interopRequireDefault(require("/var/jenkins_home/workspace/sihong_test_front_mall/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _inStorage = _interopRequireDefault(require("@/components/wareHouse/inStorage.vue"));
var _outStorage = _interopRequireDefault(require("@/components/wareHouse/outStorage.vue"));
var _transfer = _interopRequireDefault(require("@/components/wareHouse/transfer.vue"));
var _houseDetail = _interopRequireDefault(require("@/components/wareHouse/houseDetail.vue"));
var _warehouse = require("@/api/warehouse");
var _multiLanguageDisplay = require("@/utils/multiLanguageDisplay");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    Pagination: _Pagination.default,
    InStorage: _inStorage.default,
    OutStorage: _outStorage.default,
    Transfer: _transfer.default,
    HouseDetail: _houseDetail.default
  },
  data: function data() {
    return {
      multiLanguageDisplay: _multiLanguageDisplay.multiLanguageDisplay,
      search: {
        goodsName: '',
        styleNo: '',
        color: '',
        size: '',
        category: '',
        warehouse: '',
        //仓库
        location: '' //库位号
      },
      selectList: [],
      list: [],
      listLoading: true,
      total: 0,
      pageNo: 1,
      pageSize: 10
    };
  },
  created: function created() {},
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    init: function init(val) {
      this.getList();
    },
    handleClose: function handleClose() {
      this.dialogVisible = false;
    },
    lookDetail: function lookDetail(row) {
      this.getListDetail(row.productId, row.locationId);
    },
    getListDetail: function getListDetail(productId, locationId) {
      var _this = this;
      var data = {
        productId: productId,
        locationId: locationId
      };
      (0, _warehouse.inventoryDetailApi)(data).then(function (data) {
        var res = data.data;
        if (res.errno == 0) {
          _this.$refs.houseDetail.open(res.data);
        }
      });
    },
    // 查找
    onSearch: function onSearch() {
      this.pageNo = 1;
      this.getList();
    },
    onClear: function onClear() {
      this.search = {
        goodsName: '',
        styleNo: '',
        color: '',
        size: '',
        category: '',
        warehouse: '',
        //仓库
        location: '' //库位号
      };
      this.pageNo = 1;
      this.getList();
    },
    // 进入详情
    toDetail: function toDetail() {},
    // 获取列表数据
    getList: function getList() {
      var _this2 = this;
      this.listLoading = true;
      var data = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.search), {}, {
        pageNo: this.pageNo,
        pageSize: this.pageSize
      });
      (0, _warehouse.listLocationApi)(data).then(function (data) {
        var res = data.data;
        console.log('res', res);
        if (res.errno == 0) {
          _this2.listLoading = false;
          _this2.list = res.data.list;
          _this2.total = res.data.total;
        }
      });
    },
    // 选择事件
    handleSelectionChange: function handleSelectionChange(val) {
      this.selectList = val;
    },
    // 入库
    toInStorage: function toInStorage() {
      this.$refs.instorage.open();
    },
    // 出库
    toOutStorage: function toOutStorage() {
      console.log('this.selectList', this.selectList);
      this.$refs.outStorage.open(this.selectList);
    },
    // 调拨
    toTransfer: function toTransfer() {
      this.$refs.transfer.open(this.selectList);
    },
    // 返回列表
    returnList: function returnList() {
      this.$router.push('/wareHouse/houseList');
    }
  }
};