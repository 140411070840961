"use strict";

var _interopRequireDefault = require("/var/jenkins_home/workspace/sihong_test_front_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/es6.regexp.split");
var _goods = require("@/api/goods");
var _storage = require("@/api/storage");
var _mall = require("@/api/mall");
var _ImgDrag = _interopRequireDefault(require("@/components/ImgDrag"));
var _Tinymce = _interopRequireDefault(require("@/components/Tinymce"));
var _TemplateEdit = _interopRequireDefault(require("@/components/BaseTemplate/TemplateEdit"));
var _previewTemp = _interopRequireDefault(require("@/components/BaseTemplate/previewTemp"));
var _elementUi = require("element-ui");
var _auth = require("@/utils/auth");
var _multiLanguageDisplay = require("@/utils/multiLanguageDisplay");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'GoodsCreate',
  components: {
    ImageDrag: _ImgDrag.default,
    Tinymce: _Tinymce.default,
    TemplateEdit: _TemplateEdit.default,
    PreviewTemp: _previewTemp.default
  },
  data: function data() {
    var validateGoodsSn = function validateGoodsSn(rule, value, callback) {
      var myreg = /[^\a-\z\A-\Z0-\9_\- /\\]/g;
      if (value.length > 30) {
        callback(new Error('商品款号长度过长,最多输入30字符！'));
      } else if (myreg.test(value)) {
        callback(new Error('商品款号请输入数字、字母、部分符号：_ - \\ / '));
      } else {
        callback();
      }
    };
    var priceFactor = function priceFactor(rule, value, callback) {
      if (value !== '') {
        var data = value.toString().split('.');
        if (data.length === 2 && data[1].length > 2) {
          callback(new Error('价格最多保留2位小数!'));
        }
      } else {
        callback();
      }
    };
    return {
      multiLanguageDisplay: _multiLanguageDisplay.multiLanguageDisplay,
      // 拖拽图片列表
      file_list: [],
      // 图片上传路径
      uploadPath: _storage.uploadPath,
      // 关键词
      newKeywordVisible: false,
      newKeyword: '',
      keywords: [],
      // 商品分类、品牌
      categoryList: [],
      brandList: [],
      // 商品基本信息
      goods: {
        picUrl: null,
        gallery: [],
        presale: false,
        isHot: false,
        isNew: true,
        isOnSale: true,
        currency: 'USD',
        imageGif: null
      },
      unitOptions: ['USD', 'CNY'],
      commonPrice: 0.0,
      // 商品多规格选择
      value: '',
      specList: [],
      selectedSpecList: [],
      // 商品规格
      // multipleSpec: false,
      specForm: {
        specification: '',
        value: '',
        picUrl: ''
      },
      specifications: [],
      attrValVisiable: false,
      attrValForm: {
        id: 0,
        name: '',
        value: '',
        inputList: ''
      },
      // 商品库存
      productVisiable: false,
      productForm: {
        id: 0,
        specifications: [],
        price: 0.0,
        number: 0,
        url: ''
      },
      products: [],
      commonStock: '',
      // 商品参数
      attributes: [],
      // 字段验证
      rules: {
        name: [{
          required: true,
          message: '商品名称不能为空',
          trigger: 'blur'
        }],
        goodsSn: [{
          required: true,
          message: '商品款号不能为空',
          trigger: 'blur'
        }, {
          validator: validateGoodsSn,
          trigger: 'blur'
        }],
        counterPrice: [{
          required: true,
          message: '市场售价不能为空',
          trigger: 'blur'
        }, {
          validator: priceFactor,
          trigger: 'blur'
        }],
        livePrice: [{
          validator: priceFactor,
          trigger: 'blur'
        }],
        picUrl: [{
          required: true,
          message: '商品图片不能为空',
          trigger: 'blur'
        }],
        gallery: [{
          required: true,
          message: '宣传画廊不能为空',
          trigger: 'blur'
        }],
        categoryId: [{
          required: true,
          message: '商品分类不能为空',
          trigger: 'blur'
        }],
        detail: [{
          required: true,
          message: '商品详情不能为空',
          trigger: 'blur'
        }],
        attribute: [{
          required: true,
          message: '商品详情不能为空',
          trigger: 'blur'
        }]
      },
      sellHeight: 400,
      // 商品详情编辑方式——默认文本编辑
      activeGoods: 'textEdit',
      // 商品详情弹窗显示
      detailVisible: false,
      goodsDetailList: []
    };
  },
  computed: {
    // 头部信息
    headers: function headers() {
      return {
        Authorization: (0, _auth.getToken)()
      };
    }
  },
  created: function created() {
    this.init();
  },
  methods: {
    addLabelAttribute: function addLabelAttribute(arr) {
      var _this = this;
      arr.forEach(function (item) {
        item.label = _this.multiLanguageDisplay(item.label);
        if (item.children && item.children.length) {
          _this.addLabelAttribute(item.children);
        }
      });
    },
    // 数据初始化
    init: function init() {
      var _this2 = this;
      // 获取商品分类和品牌商
      (0, _goods.listCatAndBrand)().then(function (response) {
        _this2.categoryList = response.data.data.categoryList;
        _this2.addLabelAttribute(_this2.categoryList);
        _this2.brandList = response.data.data.brandList;
      });
      // 获取商品规格列表
      (0, _mall.listSpec)({
        page: 1,
        limit: 1000,
        sort: 'sort',
        order: 'desc'
      }).then(function (response) {
        var self = _this2;
        response.data.data.list.forEach(function (val, index, arr) {
          self.specList.push({
            id: val.id,
            name: val.name,
            items: JSON.parse(val.inputList),
            checkItem: []
          });
        });
      }).catch(function (errmsg) {
        self.specList = [];
      });
    },
    // 市场售价监听改变
    counterPriceChange: function counterPriceChange() {
      var self = this;
      self.commonPrice = this.goods.counterPrice;
      for (var i = 0; i < this.products.length; i++) {
        this.products[i].price = self.commonPrice;
      }
    },
    // 商品分类
    handleCategoryChange: function handleCategoryChange(value) {
      this.goods.categoryId = value[value.length - 1];
    },
    // 关键词:删除关键词
    handleClose: function handleClose(tag) {
      this.keywords.splice(this.keywords.indexOf(tag), 1);
      this.goods.keywords = this.keywords.toString();
    },
    // 关键词:新增关键词Input框:Input获取焦点
    showInput: function showInput() {
      var _this3 = this;
      this.newKeywordVisible = true;
      this.$nextTick(function (_) {
        _this3.$refs.newKeywordInput.$refs.input.focus();
      });
    },
    // 关键词:新增关键词：Input失去焦点
    handleInputConfirm: function handleInputConfirm() {
      var newKeyword = this.newKeyword;
      if (newKeyword) {
        this.keywords.push(newKeyword);
        this.goods.keywords = this.keywords.toString();
      }
      this.newKeywordVisible = false;
      this.newKeyword = '';
    },
    // 商品图片：上传成功回调函数
    uploadPicUrl: function uploadPicUrl(response) {
      this.goods.picUrl = response.data.url;
    },
    uploadPicUrl3D: function uploadPicUrl3D(response) {
      this.goods.imageGif = response.data.url;
    },
    // 画廊：图片拖拽排序、图片上传前格式、大小限制
    beforeAvatarUpload: function beforeAvatarUpload(file) {
      // const type_arr = ['image/jpeg', 'image/png']
      // const type = file.type
      // if (!type_arr.includes(type)) {
      //   this.$message.error('图片格式不正确,只支持jpg和png类型图片')
      //   return false
      // }
      var isLt10M = file.size / 1024 / 1024 < 10;
      if (!isLt10M) {
        this.$message.error('图片大小不超过10M');
        return false;
      }
      return true;
    },
    // 画廊：图片上传成功
    bannerPicSuccess: function bannerPicSuccess(response, file, fileList) {
      if (response.errno === 0) {
        this.file_list.push(response.data.url);
      }
    },
    // 画廊：图片上传失败
    uploadError: function uploadError() {
      this.$message.error('上传失败，请重新上传');
    },
    // 画廊：拖拽保存图片
    updateFile: function updateFile(val) {
      this.file_list = val;
      console.log(this.file_list);
    },
    // 商品规格:单选框绑定值变化事件
    // specChanged: function(label) {
    //   if (label === false) {
    //     this.specifications = [
    //       { specification: '规格', value: '标准', picUrl: '' }
    //     ]
    //     this.products = [
    //       {
    //         id: 0,
    //         specifications: ['标准'],
    //         price: this.commonPrice,
    //         number: 0,
    //         url: ''
    //       }
    //     ]
    //   } else {
    //     this.specifications = []
    //     this.products = []
    //   }
    // },
    // 商品规格:选中某规格，显示规则值多选
    handleAttrChange: function handleAttrChange(value) {
      var self = this;
      if (self.selectedSpecList.length >= 2) {
        this.$message.error('商品规格最多支持两种,请移除其他规格再尝试！');
        return;
      }
      this.specList.forEach(function (val, index, arr) {
        if (val.name === value) {
          var _index = self.selectedSpecList.indexOf(val);
          if (_index > -1) return;
          self.selectedSpecList.push(val);
        }
      });
    },
    // 商品规格:移除某规格
    handleRemoveAttr: function handleRemoveAttr(value) {
      var index = this.selectedSpecList.indexOf(value);
      this.selectedSpecList.splice(index, 1);
      this.handleSelectSpec();
    },
    // 商品规格:确认选中的规格，生成商品库存列表
    handleSelectSpec: function handleSelectSpec() {
      this.specifications = [];
      var self = this;
      this.selectedSpecList.forEach(function (val, index, arr) {
        val.checkItem.forEach(function (item, index, arr) {
          var specForm = {
            specification: val.name,
            value: item,
            picUrl: ''
          };
          self.specifications.push(specForm);
        });
      });
      this.specToProduct();
    },
    // 商品规格:新增商品规格值弹窗
    handleAddAttrVal: function handleAddAttrVal(item) {
      var inputList = item.items.join('，');
      this.attrValForm = {
        id: item.id,
        name: item.name,
        value: '',
        inputList: inputList
      };
      this.attrValVisiable = true;
    },
    // 商品规格:新增商品规格值
    handleAttrValAdd: function handleAttrValAdd() {
      var _this4 = this;
      var self = this;
      if (this.attrValForm.value === '') {
        this.$message.error('规则值不能为空');
        return false;
      }
      var items = this.attrValForm.inputList.split('，');
      if (items.indexOf(this.attrValForm.value) > -1) {
        this.$message.error('规则值已存在');
        return false;
      }
      this.attrValForm.inputList = this.attrValForm.inputList + '，' + this.attrValForm.value;
      (0, _mall.updateSpec)({
        id: this.attrValForm.id,
        name: this.attrValForm.name,
        inputList: this.attrValForm.inputList
      }).then(function () {
        _this4.selectedSpecList.forEach(function (val, index, arr) {
          if (val.id === self.attrValForm.id) {
            val.items.push(self.attrValForm.value);
          }
        });
        _this4.attrValVisiable = false;
        _this4.$notify.success({
          title: '成功',
          message: '添加成功'
        });
      }).catch(function (response) {
        _this4.$notify.error({
          title: '失败',
          message: response.data.errmsg
        });
      });
    },
    // 商品库存：根据商品规则生成商品库存列表
    specToProduct: function specToProduct() {
      if (this.specifications.length === 0) {
        this.products = [];
        return;
      }
      // 根据specifications创建临时规格列表
      var specValues = [];
      var spec = this.specifications[0].specification;
      var values = [];
      values.push(0);
      for (var i = 1; i < this.specifications.length; i++) {
        var aspec = this.specifications[i].specification;
        if (aspec === spec) {
          values.push(i);
        } else {
          specValues.push(values);
          spec = aspec;
          values = [];
          values.push(i);
        }
      }
      specValues.push(values);

      // 根据临时规格列表生产货品规格
      // 算法基于 https://blog.csdn.net/tyhj_sf/article/details/53893125
      var productsIndex = 0;
      var products = [];
      var combination = [];
      var n = specValues.length;
      for (var s = 0; s < n; s++) {
        combination[s] = 0;
      }
      var index = 0;
      var isContinue = false;
      do {
        var specifications = [];
        for (var x = 0; x < n; x++) {
          var z = specValues[x][combination[x]];
          specifications.push(this.specifications[z].value);
        }
        products[productsIndex] = {
          id: productsIndex,
          specifications: specifications,
          price: this.commonPrice,
          number: 0,
          url: ''
        };
        productsIndex++;
        index++;
        combination[n - 1] = index;
        for (var j = n - 1; j >= 0; j--) {
          if (combination[j] >= specValues[j].length) {
            combination[j] = 0;
            index = 0;
            if (j - 1 >= 0) {
              combination[j - 1] = combination[j - 1] + 1;
            }
          }
        }
        isContinue = false;
        for (var p = 0; p < n; p++) {
          if (combination[p] !== 0) {
            isContinue = true;
          }
        }
      } while (isContinue);
      this.products = products;
    },
    // 商品库存：点击设置按钮;显示设置商品库存弹窗
    handleProductShow: function handleProductShow(row) {
      this.productForm = Object.assign({}, row);
      this.productVisiable = true;
    },
    // 商品库存:图片上传成功回调事件
    uploadProductUrl: function uploadProductUrl(response) {
      this.productForm.url = response.data.url;
    },
    // 商品库存:确认修改商品库存
    handleProductEdit: function handleProductEdit() {
      for (var i = 0; i < this.products.length; i++) {
        var v = this.products[i];
        if (v.id === this.productForm.id) {
          this.products.splice(i, 1, this.productForm);
          break;
        }
      }
      this.productVisiable = false;
    },
    // 商品库存：同步库存
    handleSynStock: function handleSynStock() {
      var self = this;
      this.products.forEach(function (item, index, array) {
        item.number = self.commonStock;
      });
    },
    // 商品库存：删除某库存
    handleDeleteStock: function handleDeleteStock(row) {
      var index = this.products.indexOf(row);
      this.products.splice(index, 1);
    },
    // 商品参数：点击添加按钮;显示设置商品参数弹窗
    handleAttributeAdd: function handleAttributeAdd() {
      this.attributes.push({
        attribute: '',
        value: ''
      });
    },
    // 商品参数：删除商品参数
    handleAttributeDelete: function handleAttributeDelete(row) {
      var index = this.attributes.indexOf(row);
      this.attributes.splice(index, 1);
    },
    // 取消上架
    handleCancel: function handleCancel() {
      this.$router.push({
        path: '/goods/list'
      });
    },
    // 确认上架
    handlePublish: function handlePublish() {
      var _this5 = this;
      // 拖拽图片
      this.goods.gallery = this.file_list;
      // 判断编辑方式去选择信息内容
      if (this.activeGoods === 'detailEdit') {
        this.goods.detail = document.querySelector('.preview').outerHTML;
        this.goods.detailType = 1;
      } else {
        this.goodsDetailList = '';
      }
      this.goods.detailTemplate = JSON.stringify(this.goodsDetailList);
      // 数据验证
      var isVaild = this.dataIsVaild();
      if (!isVaild) return;
      // attributes验证
      var isAttrVaild = this.attrVaild();
      if (!isAttrVaild) return;
      var finalGoods = {
        goods: this.goods,
        specifications: this.specifications,
        products: this.products,
        attributes: this.attributes
      };
      (0, _goods.publishGoods)(finalGoods).then(function (response) {
        _this5.$notify.success({
          title: '成功',
          message: '创建成功'
        });
        _this5.$router.push({
          path: '/goods/list'
        });
      }).catch(function (response) {
        _elementUi.MessageBox.alert('业务错误：' + response.data.errmsg, '警告', {
          confirmButtonText: '确定',
          type: 'error'
        });
      });
    },
    // 商品上架数据验证
    dataIsVaild: function dataIsVaild() {
      if (this.goods.name === undefined || this.goods.name === '') {
        this.$message.error('商品名称不能为空');
        return false;
      }
      if (this.goods.goodsSn === undefined || this.goods.goodsSn === '') {
        this.$message.error('商品款号不能为空');
        return false;
      }
      if (this.goods.goodsSn.length > 30) {
        this.$message.error('商品款号长度过长,最多输入30字符！');
        return false;
      }
      var myreg = /[^\a-\z\A-\Z0-\9_\- /\\]/g;
      if (myreg.test(this.goods.goodsSn)) {
        this.$message.error('商品款号请输入数字、字母、部分符号：_ - \\ / ');
        return false;
      }
      if (this.goods.counterPrice === undefined || this.goods.counterPrice === '') {
        this.$message.error('市场售价不能为空');
        return false;
      }
      if (this.goods.picUrl === undefined || this.goods.picUrl === '') {
        this.$message.error('商品图片不能为空');
        return false;
      }
      if (this.goods.gallery === undefined || this.goods.gallery === '') {
        this.$message.error('宣传画廊不能为空');
        return false;
      }
      if (this.goods.categoryId === undefined || this.goods.categoryId === '') {
        this.$message.error('商品分类不能为空');
        return false;
      }
      if (this.specifications.length === 0) {
        this.$message.error('商品规格不能为空');
        return false;
      }
      if (this.products.length === 0) {
        this.$message.error('商品库存不能为空');
        return false;
      }
      if (this.goods.detail === undefined || this.goods.detail === '') {
        this.$message.error('商品详情不能为空');
        return false;
      }
      return true;
    },
    // attributes验证
    attrVaild: function attrVaild() {
      var isOk = true;
      for (var i = 0; i < this.attributes.length; i++) {
        if (this.attributes[i].attribute === '' || this.attributes[i].value === '') {
          this.$message.error('商品参数名称、值不可为空');
          isOk = false;
          break;
        }
      }
      return isOk;
    },
    // 商品详情选择编辑方式
    handleClick: function handleClick(tab, ev) {
      console.log('点击' + tab.index);
    },
    getSellHeight: function getSellHeight(ev) {
      this.sellHeight = ev;
    }
  }
};