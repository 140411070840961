"use strict";

var _interopRequireDefault = require("/var/jenkins_home/workspace/sihong_test_front_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createAd = createAd;
exports.deleteAd = deleteAd;
exports.listAd = listAd;
exports.readAd = readAd;
exports.updateAd = updateAd;
var _request = _interopRequireDefault(require("@/utils/request"));
function listAd(query) {
  return (0, _request.default)({
    url: '/ad/list',
    method: 'get',
    params: query
  });
}
function createAd(data) {
  return (0, _request.default)({
    url: '/ad/create',
    method: 'post',
    data: data
  });
}
function readAd(data) {
  return (0, _request.default)({
    url: '/ad/read',
    method: 'get',
    data: data
  });
}
function updateAd(data) {
  return (0, _request.default)({
    url: '/ad/update',
    method: 'post',
    data: data
  });
}
function deleteAd(data) {
  return (0, _request.default)({
    url: '/ad/delete',
    method: 'post',
    data: data
  });
}