var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        [
          _c(
            "div",
            { staticClass: "add" },
            [
              _vm.type === 23 || _vm.type === 18
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.routeLabel },
                    },
                    [_vm._v("管理标签")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.add()
                    },
                  },
                },
                [_vm._v("新增")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            { attrs: { data: _vm.dataSource, border: "" } },
            [
              _c("el-table-column", {
                attrs: { align: "center", label: "排序", prop: "id" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("div", { staticClass: "handle_move" }, [
                          _c(
                            "svg",
                            {
                              attrs: {
                                width: "17.000000",
                                height: "28.000000",
                                viewBox: "0 0 17 28",
                                fill: "none",
                                xmlns: "http://www.w3.org/2000/svg",
                                "xmlns:xlink": "http://www.w3.org/1999/xlink",
                              },
                            },
                            [
                              _c("desc", [
                                _vm._v(
                                  "\n                  Created with Pixso.\n              "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("defs"),
                              _vm._v(" "),
                              _c("circle", {
                                attrs: {
                                  id: "椭圆 1",
                                  cx: "3.000000",
                                  cy: "3.000000",
                                  r: "3.000000",
                                  fill: "#282828",
                                  "fill-opacity": "1.000000",
                                },
                              }),
                              _vm._v(" "),
                              _c("circle", {
                                attrs: {
                                  id: "椭圆 1",
                                  cx: "3.000000",
                                  cy: "14.000000",
                                  r: "3.000000",
                                  fill: "#282828",
                                  "fill-opacity": "1.000000",
                                },
                              }),
                              _vm._v(" "),
                              _c("circle", {
                                attrs: {
                                  id: "椭圆 1",
                                  cx: "3.000000",
                                  cy: "25.000000",
                                  r: "3.000000",
                                  fill: "#282828",
                                  "fill-opacity": "1.000000",
                                },
                              }),
                              _vm._v(" "),
                              _c("circle", {
                                attrs: {
                                  id: "椭圆 1",
                                  cx: "14.000000",
                                  cy: "3.000000",
                                  r: "3.000000",
                                  fill: "#282828",
                                  "fill-opacity": "1.000000",
                                },
                              }),
                              _vm._v(" "),
                              _c("circle", {
                                attrs: {
                                  id: "椭圆 1",
                                  cx: "14.000000",
                                  cy: "14.000000",
                                  r: "3.000000",
                                  fill: "#282828",
                                  "fill-opacity": "1.000000",
                                },
                              }),
                              _vm._v(" "),
                              _c("circle", {
                                attrs: {
                                  id: "椭圆 1",
                                  cx: "14.000000",
                                  cy: "25.000000",
                                  r: "3.000000",
                                  fill: "#282828",
                                  "fill-opacity": "1.000000",
                                },
                              }),
                            ]
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "日期", prop: "date" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              scope.row.date ? scope.row.date.split(" ")[0] : ""
                            ) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "图片", width: "150", prop: "url" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("img", {
                          staticClass: "img",
                          attrs: { src: scope.row.url, alt: "" },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _vm.type === 23 || _vm.type === 18
                ? _c("el-table-column", {
                    attrs: { label: "标签", prop: "tagList" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    scope.row.tagList
                                      ? scope.row.tagList
                                          .map(function (item) {
                                            return _vm.multiLanguageDisplay(
                                              item.name
                                            )
                                          })
                                          .join(",")
                                      : ""
                                  ) +
                                  "\n        "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3385502720
                    ),
                  })
                : _c("el-table-column", {
                    attrs: { label: "邮政编码", prop: "zipCode" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              "\n          " +
                                _vm._s(scope.row.zipCode) +
                                "\n        "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "标题", prop: "title" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.multiLanguageDisplay(scope.row.title)) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "创作者", prop: "brief" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.multiLanguageDisplay(scope.row.brief)) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "描述", prop: "text" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.multiLanguageDisplay(scope.row.text)) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "启用状态", prop: "enable" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-switch", {
                          attrs: { "active-color": "#13ce66" },
                          on: {
                            change: function ($event) {
                              return _vm.onEnable($event, scope.row)
                            },
                          },
                          model: {
                            value: scope.row.enable,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "enable", $$v)
                            },
                            expression: "scope.row.enable",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.edit(scope.row)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.deleteItem(scope.row)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("el-pagination", {
            staticClass: "page",
            attrs: {
              background: "",
              "current-page": _vm.currentPage,
              "page-size": _vm.pageSize,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.tit,
            visible: _vm.dialogVisible,
            width: "900px",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _vm.dialogVisible
            ? _c(
                "el-form",
                {
                  ref: "form",
                  attrs: { model: _vm.form, "label-width": "80px" },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "日期" } },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  type: "daterange",
                                  "value-format": "yyyy-MM-dd HH:mm:ss",
                                  "range-separator": "至",
                                  "start-placeholder": "开始日期",
                                  "end-placeholder": "结束日期",
                                },
                                model: {
                                  value: _vm.form.date,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "date", $$v)
                                  },
                                  expression: "form.date",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "标题" } },
                            [
                              _c("MultiLanguageInp", {
                                attrs: { "inp-data-j-s-o-n": _vm.form.title },
                                on: {
                                  updateInp: function (val) {
                                    return (_vm.form.title = val)
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "创作者" } },
                            [
                              _c("MultiLanguageInp", {
                                attrs: { "inp-data-j-s-o-n": _vm.form.brief },
                                on: {
                                  updateInp: function (val) {
                                    return (_vm.form.brief = val)
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "启用状态" } },
                            [
                              _c("el-switch", {
                                attrs: { "active-color": "#13ce66" },
                                model: {
                                  value: _vm.form.enable,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "enable", $$v)
                                  },
                                  expression: "form.enable",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "缩略图" } },
                            [
                              _c(
                                "el-upload",
                                {
                                  staticClass: "avatar-uploader",
                                  attrs: {
                                    headers: _vm.headers,
                                    action: _vm.uploadPath,
                                    "show-file-list": false,
                                    "on-success": _vm.handleAvatarSuccess,
                                  },
                                },
                                [
                                  _vm.form.url
                                    ? _c("img", {
                                        staticClass: "avatar",
                                        attrs: { src: _vm.form.url },
                                      })
                                    : _c("i", {
                                        staticClass:
                                          "el-icon-plus avatar-uploader-icon avatar",
                                      }),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "描述" } },
                            [
                              _c("MultiLanguageInp", {
                                attrs: {
                                  type: "textarea",
                                  "inp-data-j-s-o-n": _vm.form.text,
                                },
                                on: {
                                  updateInp: function (val) {
                                    return (_vm.form.text = val)
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.type === 23 || _vm.type === 18
                            ? _c(
                                "el-form-item",
                                { attrs: { label: "标签" } },
                                [
                                  _c("el-cascader", {
                                    attrs: {
                                      "popper-class": "disableLevel",
                                      placeholder: "",
                                      options: _vm.tag,
                                      props: _vm.props,
                                      "collapse-tags": "",
                                      clearable: "",
                                    },
                                    on: { change: _vm.handleSelectionChange },
                                    model: {
                                      value: _vm.selectedKeys,
                                      callback: function ($$v) {
                                        _vm.selectedKeys = $$v
                                      },
                                      expression: "selectedKeys",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "详情" } },
                            [
                              _c(
                                "el-tabs",
                                {
                                  attrs: { type: "card" },
                                  on: { "tab-click": _vm.handleClick },
                                  model: {
                                    value: _vm.activeName,
                                    callback: function ($$v) {
                                      _vm.activeName = $$v
                                    },
                                    expression: "activeName",
                                  },
                                },
                                [
                                  _c(
                                    "el-tab-pane",
                                    { attrs: { label: "中文", name: "zh" } },
                                    [
                                      _c("tinymce", {
                                        attrs: { width: 680, height: 800 },
                                        model: {
                                          value: _vm.form.contentZh,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.form, "contentZh", $$v)
                                          },
                                          expression: "form.contentZh",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-tab-pane",
                                    { attrs: { label: "英文", name: "en" } },
                                    [
                                      _c("tinymce", {
                                        attrs: { width: 680, height: 800 },
                                        model: {
                                          value: _vm.form.contentEn,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.form, "contentEn", $$v)
                                          },
                                          expression: "form.contentEn",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-tab-pane",
                                    {
                                      attrs: { label: "西班牙语", name: "es" },
                                    },
                                    [
                                      _c("tinymce", {
                                        attrs: { width: 680, height: 800 },
                                        model: {
                                          value: _vm.form.contentEs,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.form, "contentEs", $$v)
                                          },
                                          expression: "form.contentEs",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "图片墙" } },
                        [
                          _c(
                            "el-upload",
                            {
                              staticClass: "upload-demo",
                              attrs: {
                                action: _vm.uploadPath,
                                "on-remove": _vm.handleRemove,
                                "file-list": _vm.fileList,
                                "list-type": "picture",
                                "on-success": _vm.handleSuccess,
                                headers: _vm.headers,
                              },
                            },
                            [
                              _c(
                                "el-button",
                                { attrs: { size: "small", type: "primary" } },
                                [_vm._v("点击上传")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.sure()
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }