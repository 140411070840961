"use strict";

var _interopRequireDefault = require("/var/jenkins_home/workspace/sihong_test_front_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.deleteBatchApi = deleteBatchApi;
exports.deleteRetApi = deleteRetApi;
exports.destinationApi = destinationApi;
exports.inboundApi = inboundApi;
exports.inboundEditexportApi = inboundEditexportApi;
exports.inventoryDetailApi = inventoryDetailApi;
exports.inventoryListApi = inventoryListApi;
exports.listBillApi = listBillApi;
exports.listBillDetailApi = listBillDetailApi;
exports.listLocationApi = listLocationApi;
exports.listLocationListApi = listLocationListApi;
exports.listReturnApi = listReturnApi;
exports.listReturnDetailApi = listReturnDetailApi;
exports.operationApi = operationApi;
exports.outboundApi = outboundApi;
exports.outboundDestinationApi = outboundDestinationApi;
exports.outboundEditApi = outboundEditApi;
exports.querySpeApi = querySpeApi;
exports.retApi = retApi;
exports.retEditApi = retEditApi;
exports.sourceApi = sourceApi;
exports.syleleListApi = syleleListApi;
exports.transferApi = transferApi;
exports.transferEditApi = transferEditApi;
exports.warehouseListApi = warehouseListApi;
var _request = _interopRequireDefault(require("@/utils/request"));
// 商品来源列表
function sourceApi(data) {
  return (0, _request.default)({
    url: '/goods/source/list',
    method: 'post',
    data: data
  });
}
// 仓库列表
function warehouseListApi(data) {
  return (0, _request.default)({
    url: '/warehouse/list',
    method: 'post',
    data: data
  });
}
// 出库方向列表
function destinationApi(data) {
  return (0, _request.default)({
    url: '/warehouse/destination/list',
    method: 'post',
    data: data
  });
}
// 出/入库类型列表 type==1 入库类型  type==2 出库类型
function operationApi(data) {
  return (0, _request.default)({
    url: '/warehouse/operation/list',
    method: 'post',
    data: data
  });
}
// 出库方向
function outboundDestinationApi(data) {
  return (0, _request.default)({
    url: '/warehouse/destination/list',
    method: 'post',
    data: data
  });
}
// 款号列表
function syleleListApi(data) {
  return (0, _request.default)({
    url: '/goods/inventory/listStyleNo',
    method: 'post',
    data: data
  });
}
// 库位列表

function listLocationListApi(data) {
  return (0, _request.default)({
    url: '/warehouse/listLocation',
    method: 'post',
    data: data
  });
}
// 仓库列表-总体
function inventoryListApi(data) {
  return (0, _request.default)({
    url: '/goods/inventory/list',
    method: 'post',
    data: data
  });
}
// 仓库列表-查看详情
function inventoryDetailApi(data) {
  return (0, _request.default)({
    url: '/goods/inventory/queryProductInventoryDetail',
    method: 'post',
    data: data
  });
}
// 仓库列表-库位
function listLocationApi(data) {
  return (0, _request.default)({
    url: '/goods/inventory/listLocation',
    method: 'post',
    data: data
  });
}
// 仓库列表--入库
function inboundApi(data) {
  return (0, _request.default)({
    url: '/goods/inventory/inbound',
    method: 'post',
    data: data
  });
}
// 仓库列表--入库--根据款号查找规格
function querySpeApi(data) {
  return (0, _request.default)({
    url: '/goods/inventory/querySpecification',
    method: 'post',
    data: data
  });
}
// 仓库列表--出库
function outboundApi(data) {
  return (0, _request.default)({
    url: '/goods/inventory/outbound',
    method: 'post',
    data: data
  });
}

// 仓库列表-调拨
function transferApi(data) {
  return (0, _request.default)({
    url: '/goods/inventory/transfer',
    method: 'post',
    data: data
  });
}
// 流水查询 ---列表

function listBillApi(data) {
  return (0, _request.default)({
    url: '/goods/inventory/listBill',
    method: 'post',
    data: data
  });
}
// 流水查询--详情

function listBillDetailApi(data) {
  return (0, _request.default)({
    url: '/goods/inventory/queryBillDetail',
    method: 'post',
    data: data
  });
}
// 流水查询--入库修改
function inboundEditexportApi(data) {
  return (0, _request.default)({
    url: '/goods/inventory/inboundEdit',
    method: 'post',
    data: data
  });
}
// 流水查询--出库修改

function outboundEditApi(data) {
  return (0, _request.default)({
    url: '/goods/inventory/outboundEdit',
    method: 'post',
    data: data
  });
}
// 流水查询--调拨修改

function transferEditApi(data) {
  return (0, _request.default)({
    url: '/goods/inventory/transferEdit',
    method: 'post',
    data: data
  });
}

// 商品归还--列表
function listReturnApi(data) {
  return (0, _request.default)({
    url: '/goods/inventory/listReturn',
    method: 'post',
    data: data
  });
}
// 商品归还--归还记录
function listReturnDetailApi(data) {
  return (0, _request.default)({
    url: '/goods/inventory/listReturnDetail',
    method: 'post',
    data: data
  });
}
// 商品归还--归还入库
function retApi(data) {
  return (0, _request.default)({
    url: '/goods/inventory/ret',
    method: 'post',
    data: data
  });
}
// 商品归还 --归还入库修改
function retEditApi(data) {
  return (0, _request.default)({
    url: '/goods/inventory/retEdit',
    method: 'post',
    data: data
  });
}
// 批量删除出入库单
function deleteBatchApi(data) {
  return (0, _request.default)({
    url: '/goods/inventory/deleteBatch',
    method: 'post',
    data: data
  });
}
// 删除借出单
function deleteRetApi(data) {
  return (0, _request.default)({
    url: '/goods/inventory/deleteRet',
    method: 'post',
    data: data
  });
}