var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "字典编辑",
            visible: _vm.dialogVisible,
            width: "30%",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.form, "label-width": "80px" } },
            [
              _c("el-form-item", { attrs: { label: "字典名称" } }, [
                _c("span", [_vm._v(_vm._s(_vm.form.name))]),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "字典配置" } },
                [
                  _c(
                    "el-input",
                    {
                      model: {
                        value: _vm.form.value,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "value", $$v)
                        },
                        expression: "form.value",
                      },
                    },
                    [
                      _c(
                        "span",
                        { attrs: { slot: "suffix" }, slot: "suffix" },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.form.unit) +
                              "\n          "
                          ),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "备注信息" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea" },
                    model: {
                      value: _vm.form.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "remark", $$v)
                      },
                      expression: "form.remark",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleOk } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }