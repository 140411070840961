"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _dictionary = require("@/api/dictionary");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'MallEditDictionary',
  data: function data() {
    return {
      dialogVisible: false,
      form: {
        id: '',
        name: '',
        value: '',
        remark: '',
        unit: ''
      }
    };
  },
  mounted: function mounted() {},
  methods: {
    open: function open(item) {
      // 编辑
      if (item.isEdit) {
        this.form.name = item.name;
        this.form.value = item.value;
        this.form.remark = item.remark;
        this.form.unit = item.unit;
        this.form.id = item.id;
      }
      this.dialogVisible = true;
    },
    handleClose: function handleClose() {
      this.dialogVisible = false;
    },
    handleOk: function handleOk() {
      var _this = this;
      var params = {
        id: this.form.id,
        value: this.form.value,
        remark: this.form.remark
      };
      (0, _dictionary.editApi)(params).then(function (res) {
        if (res.data.errno == 0) {
          _this.dialogVisible = false;
          _this.$message({
            message: '编辑成功',
            type: 'success'
          });
          _this.$emit('onUpdate', 1);
        }
      });
    }
  }
};