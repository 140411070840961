"use strict";

var _interopRequireDefault = require("/var/jenkins_home/workspace/sihong_test_front_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _editPicTemp = _interopRequireDefault(require("@/components/BaseTemplate/components/editPicTemp"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'PicBase',
  components: {
    EditPicTemp: _editPicTemp.default
  },
  props: ['detail'],
  data: function data() {
    return {
      editPic: true,
      isDragDown: false,
      picClientX: 0,
      picClientY: 0,
      aspectRatio: this.detail.width / this.detail.height // 宽高比
    };
  },
  mounted: function mounted() {
    var that = this;
    document.body.addEventListener('mousedown', function (ev) {
      ev.stopPropagation();
      that.editPic = false;
    });
    document.body.addEventListener('mouseup', function (ev) {
      ev.stopPropagation();
      that.isDragDown = false;
    });
  },
  methods: {
    isEditPic: function isEditPic() {
      this.editPic = true;
    },
    // 拖拽开始
    dragPicDown: function dragPicDown(ev) {
      console.log(ev.target);
      console.log('拖拽开始');
      if (this.editPic) {
        ev.stopPropagation();
        this.isDragDown = true;
        this.picClientX = ev.clientX;
        this.picClientY = ev.clientY;
      }
    },
    // 拖拽移动
    dragPicMove: function dragPicMove(ev) {
      ev.stopPropagation();
      if (this.isDragDown) {
        console.log('拖拽移动');
        this.detail.left = this.detail.left + (ev.clientX - this.picClientX);
        this.detail.top = this.detail.top + (ev.clientY - this.picClientY);
        this.picClientX = ev.clientX;
        this.picClientY = ev.clientY;
      }
    }
  }
};