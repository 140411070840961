var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c("h3", [_vm._v("邮件模板详情")]),
          _vm._v(" "),
          _c(
            "el-form",
            {
              ref: "EmailTemplate",
              attrs: {
                rules: _vm.rules,
                model: _vm.EmailTemplate,
                "label-width": "90px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "模板名称", prop: "name" } },
                [
                  _c("el-input", {
                    staticClass: "input-width",
                    attrs: { placeholder: "请输入名称" },
                    model: {
                      value: _vm.EmailTemplate.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.EmailTemplate, "name", $$v)
                      },
                      expression: "EmailTemplate.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "应用场景", prop: "sceneCode" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "input-width",
                      attrs: {
                        value: _vm.EmailTemplate.sceneCode,
                        placeholder: "请选择应用场景",
                        filterable: "",
                      },
                      on: { change: _vm.selectChange },
                    },
                    _vm._l(_vm.sceneList, function (item) {
                      return _c("el-option", {
                        key: item.code,
                        attrs: { label: item.name, value: item.code },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "模板内容", prop: "content" } },
                [
                  _c(
                    "el-tabs",
                    {
                      attrs: { type: "card" },
                      on: { "tab-click": _vm.handleClick },
                      model: {
                        value: _vm.activeName,
                        callback: function ($$v) {
                          _vm.activeName = $$v
                        },
                        expression: "activeName",
                      },
                    },
                    [
                      _c(
                        "el-tab-pane",
                        { attrs: { label: "中文", name: "zh" } },
                        [
                          _c("tinymce", {
                            ref: "zh-tinymce",
                            attrs: {
                              id: "zh-tinymce",
                              width: 680,
                              height: 400,
                              "need-add-code": true,
                              "code-list": _vm.codeList,
                            },
                            on: {
                              nodechange: _vm.tinymceNodeChange,
                              change: _vm.tinymceChange,
                            },
                            model: {
                              value: _vm.EmailTemplate.contentZh,
                              callback: function ($$v) {
                                _vm.$set(_vm.EmailTemplate, "contentZh", $$v)
                              },
                              expression: "EmailTemplate.contentZh",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-tab-pane",
                        { attrs: { label: "英文", name: "en" } },
                        [
                          _c("tinymce", {
                            ref: "en-tinymce",
                            attrs: {
                              id: "en-tinymce",
                              width: 680,
                              height: 400,
                              "need-add-code": true,
                              "code-list": _vm.codeList,
                            },
                            on: {
                              nodechange: _vm.tinymceNodeChange,
                              change: _vm.tinymceChange,
                            },
                            model: {
                              value: _vm.EmailTemplate.contentEn,
                              callback: function ($$v) {
                                _vm.$set(_vm.EmailTemplate, "contentEn", $$v)
                              },
                              expression: "EmailTemplate.contentEn",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "场景说明", prop: "remark" } },
                [
                  _c("el-input", {
                    staticClass: "input-width",
                    attrs: {
                      type: "textarea",
                      placeholder: "模板使用场景、示例等说明",
                    },
                    model: {
                      value: _vm.EmailTemplate.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.EmailTemplate, "remark", $$v)
                      },
                      expression: "EmailTemplate.remark",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "是否启用", prop: "isEnable" } },
                [
                  _c("el-switch", {
                    model: {
                      value: _vm.EmailTemplate.isEnable,
                      callback: function ($$v) {
                        _vm.$set(_vm.EmailTemplate, "isEnable", $$v)
                      },
                      expression: "EmailTemplate.isEnable",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "op-container" },
        [
          _c("el-button", { on: { click: _vm.handleCancel } }, [
            _vm._v("取消"),
          ]),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { loading: _vm.loading, type: "primary" },
              on: { click: _vm.handleEdit },
            },
            [_vm._v("\n      保存\n    ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }