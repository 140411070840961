var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.visible,
        title: "线下订单修改状态审核",
        width: "900px",
        "close-on-click-modal": false,
        "destroy-on-close": true,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        close: _vm.close,
        opened: _vm.opened,
      },
    },
    [
      _c(
        "el-row",
        [
          _c("el-col", { attrs: { span: 12 } }, [
            _c("div", { staticClass: "flex" }, [
              _c("span", { staticClass: "xiu" }, [_vm._v("状态审核修改")]),
              _vm._v(" "),
              _vm.info1.auditType == 1
                ? _c(
                    "div",
                    { staticClass: "flex" },
                    [
                      _c("el-tag", [_vm._v("待付款")]),
                      _vm._v(" "),
                      _c("img", {
                        staticClass: "jian",
                        attrs: {
                          src: require("../../../assets//image/jian.png"),
                          alt: "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-tag", [_vm._v("待付尾款")]),
                    ],
                    1
                  )
                : _c(
                    "div",
                    { staticClass: "flex" },
                    [
                      _c("el-tag", [_vm._v("待付尾款")]),
                      _vm._v(" "),
                      _c("img", {
                        staticClass: "jian",
                        attrs: {
                          src: require("../../../assets//image/jian.png"),
                          alt: "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-tag", [_vm._v("待发货")]),
                    ],
                    1
                  ),
            ]),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { attrs: { type: "flex", gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c("p", { staticClass: "ti" }, [_vm._v("财务：")]),
              _vm._v(" "),
              _vm.visible
                ? _c(
                    "el-form",
                    {
                      ref: "shipForm",
                      attrs: {
                        "status-icon": "",
                        "label-position": "left",
                        "label-width": "100px",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.pz("订单应付总金额", _vm.info1.currency),
                            prop: "shipChannel",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { disabled: "", value: _vm.info1.amount },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.info1.auditType != 3
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.pz(
                                  "订单应付定金",
                                  _vm.info1.currency
                                ),
                                prop: "shipChannel",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  disabled: "",
                                  value: _vm.info1.earnestMoney,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.info1.auditType != 3
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.pz(
                                  "订单应付尾款",
                                  _vm.info1.currency
                                ),
                                prop: "shipChannel",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  disabled: "",
                                  value: _vm.info1.balancePayment,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.pz("实收金额", _vm.info1.currency),
                            prop: "shipChannel",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              disabled: "",
                              value: _vm.info1.amountOfPayment,
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label:
                              _vm.auditTypeText(_vm.info1.auditType) +
                              "收款回单",
                            prop: "shipChannel",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "flex-s" },
                            [
                              _c("el-input", {
                                attrs: {
                                  value: _vm.info1.fileUrl,
                                  disabled: "",
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "a",
                                {
                                  staticClass: "dowloadbtn",
                                  attrs: {
                                    href: _vm.info1.fileUrl,
                                    download: "file_name",
                                  },
                                },
                                [_vm._v("下载")]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c("p", { staticClass: "ti" }, [_vm._v("买家：")]),
              _vm._v(" "),
              _vm.visible
                ? _c(
                    "el-form",
                    {
                      ref: "shipForm",
                      attrs: {
                        "status-icon": "",
                        "label-position": "left",
                        "label-width": "100px",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.pz("订单应付总金额", _vm.info2.currency),
                            prop: "shipChannel",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { disabled: "", value: _vm.info2.amount },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.info2.auditType != 3
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.pz(
                                  "订单应付定金",
                                  _vm.info2.currency
                                ),
                                prop: "shipChannel",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  disabled: "",
                                  value: _vm.info2.earnestMoney,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.info2.auditType != 3
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.pz(
                                  "订单应付尾款",
                                  _vm.info2.currency
                                ),
                                prop: "shipChannel",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  disabled: "",
                                  value: _vm.info2.balancePayment,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.pz("实付金额", _vm.info2.currency),
                            prop: "shipChannel",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              disabled: "",
                              value: _vm.info2.amountOfPayment,
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label:
                              _vm.auditTypeText(_vm.info1.auditType) +
                              "付款回单",
                            prop: "shipChannel",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "flex-s" },
                            [
                              _c("el-input", {
                                attrs: {
                                  disabled: "",
                                  value: _vm.info2.fileUrl,
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "a",
                                {
                                  staticClass: "dowloadbtn",
                                  attrs: {
                                    href: _vm.info2.fileUrl,
                                    download: "file_name",
                                  },
                                },
                                [_vm._v("下载")]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "btns" },
        [
          _c(
            "el-button",
            {
              staticClass: "an left",
              attrs: { plain: "", type: "primary" },
              on: { click: _vm.cancel },
            },
            [_vm._v("审核拒绝")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "an right",
              attrs: { type: "primary" },
              on: { click: _vm.success },
            },
            [_vm._v("审核通过")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            width: "500px",
            title: "提示",
            visible: _vm.visible2,
            "append-to-body": "",
            "close-on-click-modal": false,
            "destroy-on-close": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.visible2 = $event
            },
          },
        },
        [
          _vm.visible2
            ? _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.form,
                    "status-icon": "",
                    "label-position": "top",
                    "label-width": "0px",
                    rules: _vm.rules,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "roleTypes" } },
                    [
                      _c(
                        "el-checkbox-group",
                        {
                          model: {
                            value: _vm.form.roleTypes,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "roleTypes", $$v)
                            },
                            expression: "form.roleTypes",
                          },
                        },
                        [
                          _c(
                            "el-checkbox",
                            { attrs: { label: "2", border: "", name: "2" } },
                            [_vm._v("财务")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-checkbox",
                            { attrs: { label: "1", border: "", name: "1" } },
                            [_vm._v("买家")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "reason" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          placeholder: "请填写拒绝原因",
                          maxlength: "200",
                        },
                        model: {
                          value: _vm.form.reason,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "reason", $$v)
                          },
                          expression: "form.reason",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "btns" },
            [
              _c(
                "el-button",
                {
                  staticClass: "an left",
                  attrs: { plain: "", type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.visible2 = false
                    },
                  },
                },
                [_vm._v("返回")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "an right",
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.confirm("form")
                    },
                  },
                },
                [_vm._v("确认")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }