"use strict";

var _interopRequireDefault = require("/var/jenkins_home/workspace/sihong_test_front_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.editApi = editApi;
exports.getCategoryApi = getCategoryApi;
exports.listApi = listApi;
exports.setCategoryApi = setCategoryApi;
var _request = _interopRequireDefault(require("@/utils/request"));
function listApi(data) {
  return (0, _request.default)({
    url: '/dict/list',
    method: 'post',
    data: data
  });
}
function editApi(data) {
  return (0, _request.default)({
    url: '/dict/edit',
    method: 'post',
    data: data
  });
}
function setCategoryApi(data) {
  return (0, _request.default)({
    url: '/dict/setCategory',
    method: 'post',
    data: data
  });
}
function getCategoryApi(data) {
  return (0, _request.default)({
    url: '/dict/listCategory',
    method: 'post',
    data: data
  });
}