"use strict";

var _interopRequireDefault = require("/var/jenkins_home/workspace/sihong_test_front_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/var/jenkins_home/workspace/sihong_test_front_mall/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
var _router = require("@/router");
/**
 * 通过meta.perms判断是否与当前用户权限匹配
 * @param perms
 * @param route
 */
function hasPermission(perms, route) {
  if (route.meta && route.meta.perms) {
    return perms.some(function (perm) {
      return route.meta.perms.includes(perm);
    });
  } else {
    return true;
  }
}

/**
 * 递归过滤异步路由表，返回符合用户角色权限的路由表
 * @param routes asyncRouterMap
 * @param perms
 */
function filterAsyncRouter(routes, perms) {
  var res = [];
  routes.forEach(function (route) {
    var tmp = (0, _objectSpread2.default)({}, route);
    if (tmp.children) {
      tmp.children = filterAsyncRouter(tmp.children, perms);
      if (tmp.children && tmp.children.length > 0) {
        res.push(tmp);
      }
    } else {
      if (hasPermission(perms, tmp)) {
        res.push(tmp);
      }
    }
  });
  return res;
}
var permission = {
  state: {
    routers: _router.constantRouterMap,
    addRouters: []
  },
  mutations: {
    SET_ROUTERS: function SET_ROUTERS(state, routers) {
      state.addRouters = routers;
      state.routers = _router.constantRouterMap.concat(routers);
    }
  },
  actions: {
    GenerateRoutes: function GenerateRoutes(_ref, data) {
      var commit = _ref.commit;
      return new Promise(function (resolve) {
        var perms = data.perms;
        var accessedRouters;
        if (perms.includes('*')) {
          accessedRouters = _router.asyncRouterMap;
        } else {
          accessedRouters = filterAsyncRouter(_router.asyncRouterMap, perms);
        }
        commit('SET_ROUTERS', accessedRouters);
        resolve();
      });
    }
  }
};
var _default = exports.default = permission;