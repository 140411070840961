"use strict";

var _interopRequireDefault = require("/var/jenkins_home/workspace/sihong_test_front_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.deleteApi = deleteApi;
exports.languageTranslation = languageTranslation;
exports.listApi = listApi;
exports.pageApi = pageApi;
exports.trendsCreateApi = trendsCreateApi;
exports.updateApi = updateApi;
var _request = _interopRequireDefault(require("@/utils/request"));
var _request2 = _interopRequireDefault(require("@/utils/request2"));
function trendsCreateApi(data) {
  return (0, _request.default)({
    url: '/cms/create',
    method: 'post',
    data: data
  });
}
function listApi(params) {
  return (0, _request.default)({
    url: '/cms/list',
    method: 'get',
    params: params
  });
}
// 分页
function pageApi(params) {
  return (0, _request.default)({
    url: '/cms/page',
    method: 'get',
    params: params
  });
}
// 编辑
function updateApi(data) {
  return (0, _request.default)({
    url: '/cms/update',
    method: 'post',
    data: data
  });
}
// 删除
function deleteApi(params) {
  return (0, _request.default)({
    url: '/cms/delete',
    method: 'get',
    params: params
  });
}
// 一键自动翻译
function languageTranslation(params) {
  return (0, _request2.default)({
    method: 'get',
    params: params,
    url: '/translate/fy'
  });
}