"use strict";

var _interopRequireDefault = require("/var/jenkins_home/workspace/sihong_test_front_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.deleteGoods = deleteGoods;
exports.detailGoods = detailGoods;
exports.editGoods = editGoods;
exports.editGoodsPrice = editGoodsPrice;
exports.editGoodsStatus = editGoodsStatus;
exports.editProducts = editProducts;
exports.goodsLog = goodsLog;
exports.listBrandUrl = listBrandUrl;
exports.listCatAndBrand = listCatAndBrand;
exports.listCompositionUrl = listCompositionUrl;
exports.listGoods = listGoods;
exports.listGoodsUrl = listGoodsUrl;
exports.listStockLog = listStockLog;
exports.listSupplierNameUrl = listSupplierNameUrl;
exports.printUrl = printUrl;
exports.publishGoods = publishGoods;
exports.setOnSaleApi = setOnSaleApi;
var _request = _interopRequireDefault(require("@/utils/request"));
var _requestTwo = _interopRequireDefault(require("@/utils/requestTwo"));
function listGoods(query) {
  return (0, _request.default)({
    url: '/goods/list',
    method: 'get',
    params: query
  });
}
function deleteGoods(data) {
  return (0, _request.default)({
    url: '/goods/delete',
    method: 'post',
    data: data
  });
}
function publishGoods(data) {
  return (0, _request.default)({
    url: '/goods/create',
    method: 'post',
    data: data
  });
}
function detailGoods(id) {
  return (0, _request.default)({
    url: '/goods/detail',
    method: 'get',
    params: {
      id: id
    }
  });
}
function editGoods(data) {
  return (0, _request.default)({
    url: '/goods/update',
    method: 'post',
    data: data
  });
}
function listCatAndBrand() {
  return (0, _request.default)({
    url: '/goods/catAndBrand',
    method: 'get'
  });
}
function editProducts(data) {
  return (0, _request.default)({
    url: '/goods/info/update',
    method: 'Post',
    data: data
  });
}
function editGoodsStatus(data) {
  return (0, _request.default)({
    url: '/goods/state/update',
    method: 'Post',
    data: data
  });
}
function editGoodsPrice(data) {
  return (0, _request.default)({
    url: '/goods/price/update',
    method: 'Post',
    data: data
  });
}
function listStockLog(data) {
  return (0, _request.default)({
    url: '/goods/product/stock/history/list',
    method: 'Post',
    data: data
  });
}
// 批量打印
function printUrl(params) {
  return (0, _requestTwo.default)({
    url: '/wx/goods/getQrCodePdf',
    method: 'get',
    params: params
  });
}
// 成分列表
function listCompositionUrl() {
  return (0, _request.default)({
    url: '/goods/listComposition',
    method: 'get'
  });
}
// 供应商列表
function listSupplierNameUrl() {
  return (0, _request.default)({
    url: '/goods/listSupplierName',
    method: 'get'
  });
}
// 品牌列表
function listBrandUrl() {
  return (0, _request.default)({
    url: '/goods/listBrand',
    method: 'get'
  });
}
// 查询商品修改记录
function listGoodsUrl(data) {
  return (0, _request.default)({
    url: '/goods/listGoodsUpdateHistory',
    method: 'post',
    data: data
  });
}

// 查询商品修改记录
function goodsLog(params) {
  return (0, _request.default)({
    url: '/goods/scm/listGoodsUpdateHistoryByGoodsSn',
    method: 'get',
    params: params
  });
}
// 商品上架、拒绝上架、下架
function setOnSaleApi(data) {
  return (0, _request.default)({
    url: '/goods/state/setOnSale',
    method: 'post',
    data: data
  });
}