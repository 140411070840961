"use strict";

var _interopRequireDefault = require("/var/jenkins_home/workspace/sihong_test_front_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _aftersale = require("@/api/aftersale");
var _multiLanguageDisplay = require("@/utils/multiLanguageDisplay");
var _afterSalesDialog = _interopRequireDefault(require("@/components/AfterSales/afterSalesDialog.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    AfterSalesDialog: _afterSalesDialog.default
  },
  data: function data() {
    return {
      multiLanguageDisplay: _multiLanguageDisplay.multiLanguageDisplay,
      showExpress: false,
      id: '',
      reason: '',
      data: {},
      afterSalesVisible: false,
      searchForm: {
        address: '',
        consignee: '',
        mobile: ''
      },
      visibleApprove: false,
      status: ''
    };
  },
  created: function created() {
    this.id = this.$route.params.id;
    this.fetchOrderList();
  },
  methods: {
    // 获取订单详情
    fetchOrderList: function fetchOrderList() {
      var _this = this;
      (0, _aftersale.refundDetail)({
        id: this.id
      }).then(function (res) {
        if (res.data.errno == 0) {
          _this.data = res.data.data;
        }
      });
    },
    statusCom: function statusCom(status) {
      switch (status) {
        case 0:
          return this.$t('custom.label11');
        case 1:
          return this.$t('custom.select3');
        case 2:
          return this.$t('custom.select2');
        default:
          return '-';
      }
    },
    goRefund: function goRefund() {
      this.orderId = this.data.id;
      this.afterSalesVisible = true;
    },
    closeDialog: function closeDialog(val) {
      this.afterSalesVisible = false;
      if (val) {
        this.fetchOrderList();
      }
    },
    statusOrder: function statusOrder(status) {
      switch (status) {
        case 101:
          return '已提交';
        case 102:
          return '已取消';
        case 201:
          return '已退货';
        case 202:
          return '已拒绝';
        case 203:
          return '退货中';
        case 301:
          return '已退货';
        case 402:
          return '已退货';
        case 401:
          return '已退货';
        default:
          return '-';
      }
    },
    statusRefund: function statusRefund(status) {
      switch (status) {
        case 402:
          return '退款失败';
        case 401:
          return '退款中';
        case 201:
          return '退款成功';
        case 301:
          return '待退款';
        default:
          return '';
      }
    }
  }
};