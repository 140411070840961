"use strict";

var _interopRequireDefault = require("/var/jenkins_home/workspace/sihong_test_front_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("/var/jenkins_home/workspace/sihong_test_front_mall/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));
var _createForOfIteratorHelper2 = _interopRequireDefault(require("/var/jenkins_home/workspace/sihong_test_front_mall/node_modules/@babel/runtime-corejs2/helpers/createForOfIteratorHelper.js"));
var _brand = require("@/api/brand");
var _storage = require("@/api/storage");
var _auth = require("@/utils/auth");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// Secondary package based on el-pagination
var _default = exports.default = {
  name: 'MallBrand',
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      uploadPath: _storage.uploadPath,
      list: [],
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 20,
        id: undefined,
        name: undefined,
        sort: 'add_time',
        order: 'desc'
      },
      dataForm: {
        id: undefined,
        name: '',
        desc: '',
        floorPrice: undefined,
        picUrl: undefined
      },
      dialogFormVisible: false,
      dialogStatus: '',
      textMap: {
        update: '编辑',
        create: '创建'
      },
      rules: {
        name: [{
          required: true,
          message: '品牌商名称不能为空',
          trigger: 'blur'
        }],
        desc: [{
          required: true,
          message: '介绍不能为空',
          trigger: 'blur'
        }],
        picUrl: [{
          required: true,
          message: '品牌商图片不能为空',
          trigger: 'blur'
        }],
        floorPrice: [{
          required: true,
          message: '底价不能为空',
          trigger: 'blur'
        }]
      },
      downloadLoading: false
    };
  },
  computed: {
    headers: function headers() {
      return {
        Authorization: (0, _auth.getToken)()
      };
    }
  },
  created: function created() {
    this.getList();
  },
  methods: {
    beforeUpload: function beforeUpload(file) {
      console.log(file, 'file');
      var size = file.size / 1024;
      if (size > 800) {
        this.$message.error('图片不可超过800kb');
        return false;
      }
      return true;
    },
    getList: function getList() {
      var _this = this;
      this.listLoading = true;
      (0, _brand.listBrand)(this.listQuery).then(function (response) {
        _this.list = response.data.data.list;
        _this.total = response.data.data.total;
        _this.listLoading = false;
      }).catch(function () {
        _this.list = [];
        _this.total = 0;
        _this.listLoading = false;
      });
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    resetForm: function resetForm() {
      this.dataForm = {
        id: undefined,
        name: '',
        desc: '',
        floorPrice: undefined,
        picUrl: undefined
      };
    },
    handleCreate: function handleCreate() {
      var _this2 = this;
      this.resetForm();
      this.dialogStatus = 'create';
      this.dialogFormVisible = true;
      this.$nextTick(function () {
        _this2.$refs['dataForm'].clearValidate();
      });
    },
    uploadPicUrl: function uploadPicUrl(response) {
      this.dataForm.picUrl = response.data.url;
      this.$refs.image.clearValidate();
    },
    createData: function createData() {
      var _this3 = this;
      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          (0, _brand.createBrand)(_this3.dataForm).then(function (response) {
            _this3.list.unshift(response.data.data);
            _this3.dialogFormVisible = false;
            _this3.$notify.success({
              title: '成功',
              message: '创建成功'
            });
          }).catch(function (response) {
            _this3.$notify.error({
              title: '失败',
              message: response.data.errmsg
            });
          });
        }
      });
    },
    handleUpdate: function handleUpdate(row) {
      var _this4 = this;
      this.dataForm = Object.assign({}, row);
      this.dialogStatus = 'update';
      this.dialogFormVisible = true;
      this.$nextTick(function () {
        _this4.$refs['dataForm'].clearValidate();
      });
    },
    updateData: function updateData() {
      var _this5 = this;
      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          (0, _brand.updateBrand)(_this5.dataForm).then(function () {
            var _iterator = (0, _createForOfIteratorHelper2.default)(_this5.list),
              _step;
            try {
              for (_iterator.s(); !(_step = _iterator.n()).done;) {
                var v = _step.value;
                if (v.id === _this5.dataForm.id) {
                  var index = _this5.list.indexOf(v);
                  _this5.list.splice(index, 1, _this5.dataForm);
                  break;
                }
              }
            } catch (err) {
              _iterator.e(err);
            } finally {
              _iterator.f();
            }
            _this5.dialogFormVisible = false;
            _this5.$notify.success({
              title: '成功',
              message: '更新成功'
            });
          }).catch(function (response) {
            _this5.$notify.error({
              title: '失败',
              message: response.data.errmsg
            });
          });
        }
      });
    },
    handleDelete: function handleDelete(row) {
      var _this6 = this;
      this.$confirm('是否要进行删除操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _brand.deleteBrand)(row).then(function (response) {
          _this6.$notify.success({
            title: '成功',
            message: '删除成功'
          });
          var index = _this6.list.indexOf(row);
          _this6.list.splice(index, 1);
        }).catch(function (response) {
          _this6.$notify.error({
            title: '失败',
            message: response.data.errmsg
          });
        });
      });
    },
    handleDownload: function handleDownload() {
      var _this7 = this;
      this.downloadLoading = true;
      Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/vendor/Export2Excel'));
      }).then(function (excel) {
        var tHeader = ['品牌商ID', '品牌商名称', '介绍', '底价', '品牌商图片'];
        var filterVal = ['id', 'name', 'desc', 'floorPrice', 'picUrl'];
        excel.export_json_to_excel2(tHeader, _this7.list, filterVal, '品牌商信息');
        _this7.downloadLoading = false;
      });
    }
  }
};