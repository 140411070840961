"use strict";

var _interopRequireDefault = require("/var/jenkins_home/workspace/sihong_test_front_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.array.find-index");
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/es6.array.find");
var _MultiLanguageInp = _interopRequireDefault(require("@/components/MultiLanguageInp"));
var _multiLanguageDisplay = require("@/utils/multiLanguageDisplay");
var _draggableTable = _interopRequireDefault(require("@/components/Cont/draggableTable.vue"));
var _trends = require("@/api/cont/trends");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'LabelManagement',
  components: {
    MultiLanguageInp: _MultiLanguageInp.default,
    DraggableTable: _draggableTable.default
  },
  data: function data() {
    return {
      multiLanguageDisplay: _multiLanguageDisplay.multiLanguageDisplay,
      value: '',
      data: [],
      pid: '',
      childrenData: []
    };
  },
  mounted: function mounted() {
    this.handleGetTagList();
  },
  methods: {
    handlePush: function handlePush(type) {
      if (!this.pid && type === 2) return;
      this.$refs["DraggableTable".concat(type)].handlePush();
    },
    routeBack: function routeBack() {
      this.$router.go(-1);
    },
    handleGetTagList: function handleGetTagList() {
      var _this = this;
      (0, _trends.getTagList)({
        type: this.$route.query.type
      }).then(function (res) {
        _this.data = res.data.list;
        _this.changePid();
      });
    },
    changePid: function changePid() {
      var _this2 = this;
      if (this.pid) {
        this.childrenData = this.data.find(function (item) {
          return item.id === _this2.pid;
        }).childList || [];
      } else {
        this.childrenData = [];
      }
    },
    pushChildren: function pushChildren(val, isChildren) {
      var _this3 = this;
      if (isChildren) {
        var pData = this.data.find(function (item) {
          return item.id === _this3.pid;
        });
        if (Object.prototype.toString.call(val) === '[object Object]') {
          if (!pData.childList) {
            pData.childList = [val];
          } else {
            var pChildData = pData.childList.findIndex(function (item) {
              return item.id === val.id;
            });
            if (pChildData !== -1) {
              pData.childList.splice(pChildData, 1, val);
            } else {
              pData.childList.push(val);
            }
          }
        } else {
          pData.childList = val;
        }
      } else {
        if (Object.prototype.toString.call(val) === '[object Object]') {
          var index = this.data.findIndex(function (item) {
            return item.id === val.id;
          });
          if (index !== -1) {
            this.data.splice(index, 1, val);
          } else {
            this.data.push(val);
          }
        } else {
          this.data = val;
        }
      }
    },
    updateData: function updateData(pid) {
      if (pid === this.pid) {
        this.pid = '';
      }
      this.handleGetTagList();
    },
    toStr: function toStr(str) {
      var data;
      if (str) {
        data = encodeURI(str);
      } else {
        data = '暂无名称';
      }
      data = data.replace(/&nbsp;/g, '%20');
      return decodeURI(data);
    }
  }
};