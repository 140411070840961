"use strict";

var _interopRequireDefault = require("/var/jenkins_home/workspace/sihong_test_front_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
var _trends = require("@/api/cont/trends");
var _storage = require("@/api/storage");
var _auth = require("@/utils/auth");
var _MultiLanguageInp = _interopRequireDefault(require("@/components/MultiLanguageInp"));
var _multiLanguageDisplay = require("@/utils/multiLanguageDisplay");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    MultiLanguageInp: _MultiLanguageInp.default
  },
  data: function data() {
    return {
      dialogVisible: false,
      multiLanguageDisplay: _multiLanguageDisplay.multiLanguageDisplay,
      activeName: '',
      uploadPath: _storage.uploadPath,
      total: 3,
      type: 36,
      pageSize: 10,
      currentPage: 1,
      tit: '新增',
      id: '',
      staus: 1,
      // 1新增 2编辑
      form: {
        date: '',
        zipCode: '',
        title: '',
        text: '',
        enable: false,
        url: '',
        brief: ''
      },
      dataSource: []
    };
  },
  computed: {
    headers: function headers() {
      return {
        'Mu-Token': (0, _auth.getToken)()
      };
    }
  },
  created: function created() {},
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    onEnable: function onEnable(val, value) {
      console.log('val', val, value);
      var data = {
        date: value.date,
        text: value.text,
        title: value.title,
        type: this.type,
        id: value.id,
        zipcode: value.zipCode,
        enable: val,
        url: value.url,
        brief: value.brief
      };
      (0, _trends.updateApi)(data).then(function (res) {
        // this.getList()
      });
    },
    handleAvatarSuccess: function handleAvatarSuccess(res) {
      console.log(res);
      this.form.url = res.data.url;
      console.log(this.form.url);
    },
    // 删除
    deleteItem: function deleteItem(item) {
      var _this = this;
      (0, _trends.deleteApi)({
        id: item.id
      }).then(function (res) {
        console.log(res);
        _this.getList();
      });
    },
    getList: function getList() {
      var _this2 = this;
      var params = {
        type: this.type,
        page: this.currentPage,
        size: this.pageSize
      };
      (0, _trends.pageApi)(params).then(function (res) {
        console.log(res);
        _this2.dataSource = res.data.data.list;
        _this2.total = res.data.data.total;
      });
    },
    handleClose: function handleClose() {
      this.dialogVisible = false;
    },
    handleSizeChange: function handleSizeChange(val) {
      this.pageSize = val;
      this.getList();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      console.log(val);
      this.currentPage = val;
      this.getList();
    },
    add: function add(type) {
      this.dialogVisible = true;
      // this.form = {}
      this.form = {
        date: '',
        zipCode: '',
        title: '',
        text: '',
        enable: false,
        url: '',
        brief: ''
      };
      this.staus = 1;
      this.type = type;
    },
    edit: function edit(item) {
      this.dialogVisible = true;
      this.tit = '编辑';
      this.staus = 2;
      this.id = item.id;
      this.form.date = item.date;
      this.form.enable = item.enable;
      this.form.zipCode = item.zipcode;
      this.form.title = item.title;
      this.form.text = item.text;
      this.form.url = item.url;
      this.form.brief = item.brief;
      this.type = item.type;
    },
    sure: function sure() {
      var _this3 = this;
      // 使用 Object.values() 方法将 form 对象的所有值转换为数组
      var values = Object.keys(this.form);
      // 使用 Array.every() 方法判断数组中的每个值是否为空
      var isFormEmpty = values.filter(function (key) {
        return key !== 'status';
      }).every(function (key) {
        return _this3.form[key] === '';
      });
      if (isFormEmpty) {
        this.$message.warning('请输入信息');
        return;
      }
      var data = {
        date: this.form.date,
        text: this.form.text,
        title: this.form.title,
        type: this.type,
        id: this.id,
        zipcode: this.form.zipCode,
        enable: this.form.enable,
        url: this.form.url,
        brief: this.form.brief
      };
      if (this.staus == 1) {
        (0, _trends.trendsCreateApi)(data).then(function (res) {
          if (res.data.errno == 0) {
            _this3.$message.success('添加成功');
            _this3.dialogVisible = false;
            _this3.getList();
          }
        });
      }
      if (this.staus == 2) {
        (0, _trends.updateApi)(data).then(function (res) {
          console.log(res);
          _this3.$message.success('编辑成功');
          _this3.dialogVisible = false;
          _this3.getList();
        });
      }
    }
  }
};