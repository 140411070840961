"use strict";

var _interopRequireDefault = require("/var/jenkins_home/workspace/sihong_test_front_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/var/jenkins_home/workspace/sihong_test_front_mall/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _multiLanguageDisplay = require("@/utils/multiLanguageDisplay");
var _currency = require("@/api/currency");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      multiLanguageDisplay: _multiLanguageDisplay.multiLanguageDisplay,
      input: '',
      select: '',
      calc: '',
      dialogVisible: false,
      list: [],
      listLoading: true,
      total: 0,
      pageNo: 1,
      pageSize: 10,
      currencyList: [],
      conversionRates: {},
      btnLoading: false,
      baseCurrency: ''
    };
  },
  created: function created() {},
  mounted: function mounted() {
    this.getRate();
    if (JSON.parse(sessionStorage.getItem('rate'))) {
      this.input = JSON.parse(sessionStorage.getItem('rate')).input;
      this.calc = JSON.parse(sessionStorage.getItem('rate')).calc;
    }
  },
  methods: {
    getCurrencyList: function getCurrencyList() {
      var _this = this;
      (0, _currency.currencyListUrl)().then(function (data) {
        var res = data.data;
        if (res.errno == 0) {
          _this.currencyList = res.data;
          if (JSON.parse(sessionStorage.getItem('rate'))) {
            _this.select = JSON.parse(sessionStorage.getItem('rate')).select;
          } else {
            _this.select = _this.currencyList.filter(function (item) {
              return item.currency !== _this.baseCurrency;
            })[0].currency;
          }
          _this.getList();
        }
      });
    },
    getRate: function getRate() {
      var _this2 = this;
      (0, _currency.currencyRatesTodayUrl)().then(function (data) {
        var res = data.data;
        if (res.errno == 0) {
          _this2.baseCurrency = res.data.baseCurrency;
          _this2.getCurrencyList();
        }
      });
    },
    calculation: function calculation() {
      var _this3 = this;
      if (this.input) {
        (0, _currency.currencyRatesTodayUrl)().then(function (data) {
          var res = data.data;
          if (res.errno == 0) {
            _this3.calc = JSON.parse(res.data.conversionRates)[_this3.select] * +_this3.input;
            sessionStorage.setItem('rate', JSON.stringify({
              calc: _this3.calc,
              input: _this3.input,
              select: _this3.select
            }));
          }
        });
      } else {
        this.calc = '';
        sessionStorage.setItem('rate', JSON.stringify({
          calc: this.calc,
          input: this.input,
          select: this.select
        }));
      }
    },
    // 获取列表数据
    getList: function getList() {
      var _this4 = this;
      this.listLoading = true;
      var params = {
        pageNo: this.pageNo,
        pageSize: this.pageSize
      };
      (0, _currency.currencyRatesListUrl)(params).then(function (data) {
        var res = data.data;
        if (res.errno == 0) {
          _this4.listLoading = false, _this4.list = res.data.list;
          _this4.total = res.data.total;
        }
      });
    },
    closeDialog: function closeDialog(val) {
      this.dialogVisible = false;
      if (val) {
        this.getList();
      }
    },
    editRate: function editRate(row) {
      this.dialogVisible = true;
      this.conversionRates = JSON.parse(JSON.stringify(row));
      this.conversionRates.conversionRates = JSON.parse(this.conversionRates.conversionRates);
    },
    updateRate: function updateRate() {
      var _this5 = this;
      var conversionRates = JSON.stringify(this.conversionRates.conversionRates);
      this.btnLoading = true;
      (0, _currency.updateCurrencyRatesTodayUrl)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.conversionRates), {}, {
        conversionRates: conversionRates
      })).then(function (data) {
        _this5.btnLoading = false;
        var res = data.data;
        if (res.errno == 0) {
          _this5.closeDialog(true);
        }
      }).catch(function () {
        _this5.btnLoading = false;
      });
    }
  }
};