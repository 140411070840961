"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _order = require("@/api/order");
var _multiLanguageDisplay = require("@/utils/multiLanguageDisplay");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var statusMap = {
  101: '未付款',
  102: '用户取消',
  103: '系统取消',
  104: '未付定金',
  105: '未付尾款',
  201: '已付款',
  202: '申请退款',
  203: '已退款',
  301: '已发货',
  302: '部分发货',
  401: '用户收货',
  402: '系统收货',
  403: '已关闭'
};
var typesMap = {
  0: '退款',
  1: '退货退款',
  2: '换货'
};
var goodStatusMap = {
  101: '售后中',
  102: '已取消',
  201: '已完成',
  202: '已拒绝',
  203: '退货中',
  301: '已退款'
};
var _default = exports.default = {
  name: 'MallOrderDetail',
  filters: {
    orderStatusFilter: function orderStatusFilter(status) {
      return statusMap[status];
    },
    orderTypesFilter: function orderTypesFilter(type) {
      return typesMap[type];
    },
    goodStatusFilter: function goodStatusFilter(status) {
      return goodStatusMap[status];
    }
  },
  data: function data() {
    return {
      // 订单详情
      multiLanguageDisplay: _multiLanguageDisplay.multiLanguageDisplay,
      orderId: 0,
      orderDetail: {
        order: {},
        user: {},
        orderGoods: []
      },
      orderPrice: [],
      // 修改价格
      priceDialogVisible: false,
      priceForm: {
        id: undefined,
        amount: undefined
      },
      priceRules: {
        amount: [{
          required: true,
          message: '价格不能为空',
          trigger: 'blur'
        }]
      },
      // 修改地址
      addressDialogVisible: false,
      addressForm: {
        id: undefined,
        consignee: undefined,
        mobile: undefined,
        address: undefined
      },
      addressRules: {
        consignee: [{
          required: true,
          message: '收货人不能为空',
          trigger: 'blur'
        }],
        mobile: [{
          required: true,
          message: '手机号不能为空',
          trigger: 'blur'
        }],
        address: [{
          required: true,
          message: '收获地址不能为空',
          trigger: 'blur'
        }]
      }
    };
  },
  created: function created() {
    if (this.$route.query.id) {
      this.orderId = this.$route.query.id;
    }
    this.handleDetail();
  },
  methods: {
    // 详情
    handleDetail: function handleDetail() {
      var _this = this;
      (0, _order.detailOrder)(this.orderId).then(function (response) {
        var order = response.data.data.order;
        _this.orderDetail = response.data.data;
        var orderPrice = [{
          actualPrice: order.actualPrice,
          goodsPrice: order.goodsPrice,
          freightPrice: order.freightPrice,
          couponPrice: order.couponPrice,
          integralPrice: order.integralPrice,
          payDesc: order.payTime,
          payTime: order.payTime
        }];
        _this.orderPrice = orderPrice;
      });
    },
    // 修改价格
    handleUpdatePrice: function handleUpdatePrice() {
      var _this2 = this;
      this.priceForm = {
        id: this.orderId,
        amount: undefined
      };
      this.priceDialogVisible = true;
      this.$nextTick(function () {
        _this2.$refs['priceForm'].clearValidate();
      });
    },
    updateData: function updateData() {
      var _this3 = this;
      this.$refs['priceForm'].validate(function (valid) {
        if (valid) {
          (0, _order.updateOrderPrice)({
            orderId: _this3.priceForm.id,
            price: _this3.priceForm.amount
          }).then(function () {
            _this3.handleDetail();
            _this3.priceDialogVisible = false;
            _this3.$notify.success({
              title: '成功',
              message: '更新成功'
            });
          }).catch(function (response) {
            _this3.$notify.error({
              title: '失败',
              message: response.data.errmsg
            });
          });
        }
      });
    },
    // 修改地址
    handleUpdateAddress: function handleUpdateAddress() {
      var _this4 = this;
      this.addressForm = {
        id: this.orderId,
        consignee: this.orderDetail.order.consignee,
        mobile: this.orderDetail.order.mobile,
        address: this.orderDetail.order.address
      };
      this.addressDialogVisible = true;
      this.$nextTick(function () {
        _this4.$refs['addressForm'].clearValidate();
      });
    },
    updateAddressData: function updateAddressData() {
      var _this5 = this;
      this.$refs['addressForm'].validate(function (valid) {
        if (valid) {
          (0, _order.updateOrderAddress)({
            orderId: _this5.addressForm.id,
            consignee: _this5.addressForm.consignee,
            mobile: _this5.addressForm.mobile,
            address: _this5.addressForm.address
          }).then(function () {
            _this5.handleDetail();
            _this5.addressDialogVisible = false;
            _this5.$notify.success({
              title: '成功',
              message: '更新成功'
            });
          }).catch(function (response) {
            _this5.$notify.error({
              title: '失败',
              message: response.data.errmsg
            });
          });
        }
      });
    },
    // 返回
    returnOrder: function returnOrder() {
      this.$router.push({
        path: '/mall/order'
      });
    }
  }
};