var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "context", staticClass: "context" },
    [
      _c(
        "div",
        { staticClass: "header" },
        [
          _c("p", { staticClass: "header-title" }, [
            _vm._v(_vm._s(_vm.data.type === 1 ? "退款商品" : "退货商品")),
          ]),
          _vm._v(" "),
          _vm._l(_vm.data.goodsInfoList, function (item, index) {
            return _c("div", { key: index, staticStyle: { display: "flex" } }, [
              _c("img", {
                staticStyle: {
                  width: "10%",
                  height: "auto",
                  "margin-right": "20px",
                },
                attrs: { src: item.picUrl },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "goods" }, [
                _c("span", [
                  _vm._v(
                    "\n            款号: " +
                      _vm._s(item.styleNo) +
                      "\n          "
                  ),
                ]),
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    "\n            款式名称: " +
                      _vm._s(_vm.multiLanguageDisplay(item.goodsName)) +
                      "\n          "
                  ),
                ]),
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    "\n            商品单价: ￥" +
                      _vm._s(item.price) +
                      "\n          "
                  ),
                ]),
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.data.type === 1 ? "退款数量" : "退货数量") +
                      ": " +
                      _vm._s(item.applyNumber) +
                      "\n          "
                  ),
                ]),
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.data.type === 1 ? "退款金额" : "退货金额") +
                      ": ￥" +
                      _vm._s(item.amount) +
                      "\n          "
                  ),
                ]),
              ]),
            ])
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c("div", { staticClass: "header" }, [
        _c("p", { staticClass: "header-title" }, [
          _vm._v(_vm._s(_vm.data.type === 1 ? "退款详情" : "退货详情")),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "goods" }, [
          _c("span", [
            _vm._v(
              "\n          " +
                _vm._s(_vm.data.type === 1 ? "退款单号" : "退货单号") +
                ": " +
                _vm._s(_vm.data.refundNo) +
                "\n        "
            ),
          ]),
          _vm._v(" "),
          _c("span", [
            _vm._v(
              "\n          商城订单号: " +
                _vm._s(_vm.data.orderNo) +
                "\n        "
            ),
          ]),
          _vm._v(" "),
          _c("span", [
            _vm._v(
              "\n          申请日期: " +
                _vm._s(_vm.data.applyDate) +
                "\n        "
            ),
          ]),
          _vm._v(" "),
          _c("span", { staticStyle: { width: "100%" } }, [
            _vm._v(
              "\n          " +
                _vm._s(_vm.data.type === 1 ? "退款原因" : "申请理由") +
                ": " +
                _vm._s(_vm.data.type === 1 ? "取消订单" : _vm.data.reason) +
                "\n        "
            ),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          _vm._l(_vm.data.pictures, function (item, index) {
            return _c("img", {
              key: index,
              staticStyle: { width: "20%", height: "auto" },
              attrs: { src: item, alt: "" },
            })
          }),
          0
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "header" }, [
        _vm.data.type !== 1
          ? _c("p", { staticClass: "header-title" }, [
              _vm._v("退货进度: "),
              _c("span", [_vm._v(_vm._s(_vm.data.returnGoodsText))]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.statusRefund(_vm.data.refundStatus)
          ? _c("p", { staticClass: "header-title" }, [
              _vm._v("退款状态: "),
              _c("span", [_vm._v(_vm._s(_vm.data.refundStatusText))]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.data.refundStatus === 402
          ? _c("p", { staticClass: "header-title" }, [
              _vm._v("失败原因: "),
              _c("span", [_vm._v(_vm._s(_vm.data.refundFailReason))]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.data.refundStatus === 402
          ? _c("p", { staticClass: "header-title" }, [
              _vm._v("退款操作: "),
              _c(
                "span",
                {
                  staticStyle: {
                    color: "rgb(24, 144, 255)",
                    cursor: "pointer",
                  },
                  on: { click: _vm.goRefund },
                },
                [_vm._v("退款")]
              ),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _vm.afterSalesVisible
        ? _c("AfterSalesDialog", {
            attrs: { dialogVisible: _vm.afterSalesVisible, id: _vm.orderId },
            on: { closeDialog: _vm.closeDialog },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }