var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "section",
        { ref: "print" },
        [
          _c(
            "el-form",
            { attrs: { data: _vm.orderDetail, "label-position": "left" } },
            [
              _c(
                "el-form-item",
                {
                  staticStyle: { "margin-bottom": "0" },
                  attrs: { label: "订单编号" },
                },
                [_c("span", [_vm._v(_vm._s(_vm.orderDetail.order.orderSn))])]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticStyle: { "margin-bottom": "0" },
                  attrs: { label: "订单状态" },
                },
                [
                  _c("el-tag", [
                    _vm._v(
                      _vm._s(
                        _vm._f("orderStatusFilter")(
                          _vm.orderDetail.order.orderStatus
                        )
                      )
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticStyle: { "margin-bottom": "0" },
                  attrs: { label: "订单金额" },
                },
                [
                  _c("span", [
                    _vm._v(_vm._s(_vm.orderDetail.order.actualPrice)),
                  ]),
                  _vm._v(" "),
                  _vm.orderDetail.order.orderStatus === 101
                    ? _c("i", {
                        staticClass: "el-icon-edit-outline",
                        on: { click: _vm.handleUpdatePrice },
                      })
                    : _vm._e(),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticStyle: { "margin-bottom": "0" },
                  attrs: { label: "下单用户" },
                },
                [_c("span", [_vm._v(_vm._s(_vm.orderDetail.user.nickname))])]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticStyle: { "margin-bottom": "0" },
                  attrs: { label: "下单时间" },
                },
                [_c("span", [_vm._v(_vm._s(_vm.orderDetail.order.addTime))])]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticStyle: { "margin-bottom": "0" },
                  attrs: { label: "用户留言" },
                },
                [_c("span", [_vm._v(_vm._s(_vm.orderDetail.order.message))])]
              ),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "收货信息" } }, [
                _c("span", [_vm._v(_vm._s(_vm.orderDetail.order.consignee))]),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.orderDetail.order.mobile))]),
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.multiLanguageDisplay(_vm.orderDetail.order.address)
                    )
                  ),
                ]),
                _vm._v(" "),
                _vm.orderDetail.order.orderStatus === 101 ||
                _vm.orderDetail.order.orderStatus === 201
                  ? _c("i", {
                      staticClass: "el-icon-edit-outline",
                      on: { click: _vm.handleUpdateAddress },
                    })
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "商品信息" } },
                [
                  _c(
                    "el-table",
                    {
                      attrs: {
                        data: _vm.orderDetail.orderGoods,
                        border: "",
                        fit: "",
                        "highlight-current-row": "",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "商品图片",
                          prop: "picUrl",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("img", {
                                  attrs: { src: scope.row.picUrl, width: "40" },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "商品名称",
                          prop: "goodsName",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  _vm._s(
                                    _vm.multiLanguageDisplay(
                                      scope.row.goodsName
                                    )
                                  )
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "商品款号",
                          prop: "goodsSn",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "商品规格",
                          prop: "specifications",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return _vm._l(
                                scope.row.specifications,
                                function (item, index) {
                                  return _c("span", { key: index }, [
                                    _vm._v(
                                      _vm._s(_vm.multiLanguageDisplay(item))
                                    ),
                                  ])
                                }
                              )
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "商品单价",
                          prop: "price",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "商品数量",
                          prop: "number",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "商品小计",
                          prop: "money",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  _vm._s(scope.row.price * scope.row.number)
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "op-container",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.returnOrder } }, [
            _vm._v("返 回"),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.priceDialogVisible,
            title: "修改价格",
            width: "800px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.priceDialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "priceForm",
              staticStyle: { width: "400px", "margin-left": "50px" },
              attrs: {
                rules: _vm.priceRules,
                model: _vm.priceForm,
                "status-icon": "",
                "label-position": "left",
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "商品金额" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "200px" },
                    attrs: { type: "number", step: "0.01", disabled: "" },
                    model: {
                      value: _vm.orderDetail.order.goodsPrice,
                      callback: function ($$v) {
                        _vm.$set(_vm.orderDetail.order, "goodsPrice", $$v)
                      },
                      expression: "orderDetail.order.goodsPrice",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "快递运费" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "200px" },
                    attrs: { type: "number", step: "0.01", disabled: "" },
                    model: {
                      value: _vm.orderDetail.order.freightPrice,
                      callback: function ($$v) {
                        _vm.$set(_vm.orderDetail.order, "freightPrice", $$v)
                      },
                      expression: "orderDetail.order.freightPrice",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "优惠减免" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "200px" },
                    attrs: { type: "number", step: "0.01", disabled: "" },
                    model: {
                      value: _vm.orderDetail.order.couponPrice,
                      callback: function ($$v) {
                        _vm.$set(_vm.orderDetail.order, "couponPrice", $$v)
                      },
                      expression: "orderDetail.order.couponPrice",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "改价", prop: "amount" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "200px" },
                    attrs: { type: "number", step: "0.01" },
                    model: {
                      value: _vm.priceForm.amount,
                      callback: function ($$v) {
                        _vm.$set(_vm.priceForm, "amount", $$v)
                      },
                      expression: "priceForm.amount",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.priceDialogVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.updateData } },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.addressDialogVisible,
            title: "修改地址",
            width: "800px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.addressDialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "addressForm",
              staticStyle: { width: "400px", "margin-left": "50px" },
              attrs: {
                rules: _vm.addressRules,
                model: _vm.addressForm,
                "status-icon": "",
                "label-position": "left",
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "收货人", prop: "consignee" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "200px" },
                    attrs: { type: "text" },
                    model: {
                      value: _vm.addressForm.consignee,
                      callback: function ($$v) {
                        _vm.$set(_vm.addressForm, "consignee", $$v)
                      },
                      expression: "addressForm.consignee",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "手机号", prop: "mobile" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "200px" },
                    attrs: { type: "text" },
                    model: {
                      value: _vm.addressForm.mobile,
                      callback: function ($$v) {
                        _vm.$set(_vm.addressForm, "mobile", $$v)
                      },
                      expression: "addressForm.mobile",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "收货地址", prop: "address" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea" },
                    model: {
                      value: _vm.addressForm.address,
                      callback: function ($$v) {
                        _vm.$set(_vm.addressForm, "address", $$v)
                      },
                      expression: "addressForm.address",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.addressDialogVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.updateAddressData },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }