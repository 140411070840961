import { render, staticRenderFns } from "./faq.vue?vue&type=template&id=a8e42f5a&scoped=true"
import script from "./faq.vue?vue&type=script&lang=js"
export * from "./faq.vue?vue&type=script&lang=js"
import style0 from "./faq.vue?vue&type=style&index=0&id=a8e42f5a&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a8e42f5a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/jenkins_home/workspace/sihong_test_front_mall/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('a8e42f5a')) {
      api.createRecord('a8e42f5a', component.options)
    } else {
      api.reload('a8e42f5a', component.options)
    }
    module.hot.accept("./faq.vue?vue&type=template&id=a8e42f5a&scoped=true", function () {
      api.rerender('a8e42f5a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/cont/faq.vue"
export default component.exports