var render = function () {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c("h3", [_vm._v("商品介绍")]),
          _vm._v(" "),
          _c(
            "el-form",
            {
              ref: "goods",
              attrs: {
                rules: _vm.rules,
                model: _vm.goods,
                "label-width": "90px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "商品ID", prop: "id" } },
                [
                  _c("el-input", {
                    staticClass: "input-width",
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.goods.id,
                      callback: function ($$v) {
                        _vm.$set(_vm.goods, "id", $$v)
                      },
                      expression: "goods.id",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "商品名称", prop: "name" } },
                [
                  _c("el-input", {
                    staticClass: "input-width",
                    model: {
                      value: _vm.goods.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.goods, "name", $$v)
                      },
                      expression: "goods.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "供应商名称" } },
                [
                  _c("el-input", {
                    staticClass: "input-width",
                    model: {
                      value: _vm.goods.enterpriseName,
                      callback: function ($$v) {
                        _vm.$set(_vm.goods, "enterpriseName", $$v)
                      },
                      expression: "goods.enterpriseName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "供应商款号" } },
                [
                  _c("el-input", {
                    staticClass: "input-width",
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.goods.insideStyleNo,
                      callback: function ($$v) {
                        _vm.$set(_vm.goods, "insideStyleNo", $$v)
                      },
                      expression: "goods.insideStyleNo",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "商品简介" } },
                [
                  _c("el-input", {
                    staticClass: "input-width",
                    model: {
                      value: _vm.goods.brief,
                      callback: function ($$v) {
                        _vm.$set(_vm.goods, "brief", $$v)
                      },
                      expression: "goods.brief",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "商品款号", prop: "goodsSn" } },
                [
                  _c("el-input", {
                    staticClass: "input-width",
                    model: {
                      value: _vm.goods.goodsSn,
                      callback: function ($$v) {
                        _vm.$set(_vm.goods, "goodsSn", $$v)
                      },
                      expression: "goods.goodsSn",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "市场售价", prop: "counterPrice" } },
                [
                  _c(
                    "el-input",
                    {
                      staticClass: "input-width",
                      attrs: {
                        type: "number",
                        step: "0.01",
                        placeholder: "0.00",
                      },
                      on: { change: _vm.counterPriceChange },
                      model: {
                        value: _vm.goods.counterPrice,
                        callback: function ($$v) {
                          _vm.$set(_vm.goods, "counterPrice", $$v)
                        },
                        expression: "goods.counterPrice",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "80px" },
                          attrs: { slot: "prepend" },
                          slot: "prepend",
                          model: {
                            value: _vm.goods.currency,
                            callback: function ($$v) {
                              _vm.$set(_vm.goods, "currency", $$v)
                            },
                            expression: "goods.currency",
                          },
                        },
                        _vm._l(_vm.unitOptions, function (c) {
                          return _c(
                            "el-option",
                            { key: c, attrs: { value: c } },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(c) +
                                  "\n            "
                              ),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "直播价", prop: "livePrice" } },
                [
                  _c(
                    "el-input",
                    {
                      staticClass: "input-width",
                      attrs: {
                        type: "number",
                        step: "0.01",
                        placeholder: "0.00",
                      },
                      model: {
                        value: _vm.goods.livePrice,
                        callback: function ($$v) {
                          _vm.$set(_vm.goods, "livePrice", $$v)
                        },
                        expression: "goods.livePrice",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "80px" },
                          attrs: { slot: "prepend" },
                          slot: "prepend",
                          model: {
                            value: _vm.goods.currency,
                            callback: function ($$v) {
                              _vm.$set(_vm.goods, "currency", $$v)
                            },
                            expression: "goods.currency",
                          },
                        },
                        _vm._l(_vm.unitOptions, function (c) {
                          return _c(
                            "el-option",
                            { key: c, attrs: { value: c } },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(c) +
                                  "\n            "
                              ),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "商品图片", prop: "picUrl" } },
                [
                  _c(
                    "el-upload",
                    {
                      staticClass: "avatar-uploader",
                      attrs: {
                        headers: _vm.headers,
                        action: _vm.uploadPath,
                        "show-file-list": false,
                        "before-upload": function (file) {
                          this$1.limg(file, 800)
                        },
                        "on-success": _vm.uploadPicUrl,
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "tip",
                          fn: function () {
                            return [
                              _vm._v(
                                "\n            图片不可超过800kb\n          "
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    },
                    [
                      _vm.goods.picUrl
                        ? _c("img", {
                            staticClass: "avatar",
                            attrs: { src: _vm.goods.picUrl },
                          })
                        : _c("i", {
                            staticClass: "el-icon-plus avatar-uploader-icon",
                          }),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "商品3D图" } },
                [
                  _c("el-upload", {
                    staticClass: "avatar-uploader",
                    attrs: {
                      headers: _vm.headers,
                      action: _vm.uploadPath2,
                      multiple: true,
                      list: _vm.gif_list,
                      "before-upload": function (file) {
                        this$1.limg(file, 20 * 1024)
                      },
                      "on-success": _vm.uploadPicUrl3D,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "tip",
                        fn: function () {
                          return [
                            _vm._v("\n            图片不可超过20M\n          "),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "宣传画廊", prop: "gallery" } },
                [
                  _c("image-drag", {
                    attrs: {
                      limit: 10,
                      list: _vm.file_list,
                      multiple: true,
                      action: _vm.uploadPath,
                      "on-error": _vm.uploadError,
                      "on-success": _vm.bannerPicSuccess,
                      "before-upload": _vm.beforeAvatarUpload,
                    },
                    on: { update: _vm.updateFile },
                    scopedSlots: _vm._u([
                      {
                        key: "tip",
                        fn: function () {
                          return [
                            _vm._v(
                              "\n            图片大小不超过10M\n          "
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "商品单位" } },
                [
                  _c("el-input", {
                    staticClass: "input-width",
                    attrs: { placeholder: "件 / 个 / 盒" },
                    model: {
                      value: _vm.goods.unit,
                      callback: function ($$v) {
                        _vm.$set(_vm.goods, "unit", $$v)
                      },
                      expression: "goods.unit",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "关键字" } },
                [
                  _vm._l(_vm.keywords, function (tag) {
                    return _c(
                      "el-tag",
                      {
                        key: tag,
                        attrs: { closable: "", type: "primary" },
                        on: {
                          close: function ($event) {
                            return _vm.handleClose(tag)
                          },
                        },
                      },
                      [_vm._v("\n          " + _vm._s(tag) + "\n        ")]
                    )
                  }),
                  _vm._v(" "),
                  _vm.newKeywordVisible
                    ? _c("el-input", {
                        ref: "newKeywordInput",
                        staticClass: "input-new-keyword",
                        attrs: { size: "small" },
                        on: { blur: _vm.handleInputConfirm },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleInputConfirm($event)
                          },
                        },
                        model: {
                          value: _vm.newKeyword,
                          callback: function ($$v) {
                            _vm.newKeyword = $$v
                          },
                          expression: "newKeyword",
                        },
                      })
                    : _c(
                        "el-button",
                        {
                          staticClass: "button-new-keyword",
                          attrs: { size: "small", type: "primary" },
                          on: { click: _vm.showInput },
                        },
                        [_vm._v("\n          + 增加\n        ")]
                      ),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "所属分类", prop: "categoryId" } },
                [
                  _c("el-cascader", {
                    attrs: {
                      options: _vm.categoryList,
                      clearable: "",
                      "expand-trigger": "hover",
                    },
                    on: { change: _vm.handleCategoryChange },
                    model: {
                      value: _vm.categoryIds,
                      callback: function ($$v) {
                        _vm.categoryIds = $$v
                      },
                      expression: "categoryIds",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "所属品牌商" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "" },
                      model: {
                        value: _vm.goods.brandId,
                        callback: function ($$v) {
                          _vm.$set(_vm.goods, "brandId", $$v)
                        },
                        expression: "goods.brandId",
                      },
                    },
                    _vm._l(_vm.brandList, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.goodsWashings.length
        ? _c(
            "el-card",
            { staticClass: "box-card" },
            [
              _c("h3", [_vm._v("水洗")]),
              _vm._v(" "),
              _vm._l(_vm.goodsWashings, function (item, index) {
                return _c("div", { key: item.id, staticClass: "water" }, [
                  _c("img", {
                    directives: [{ name: "viewer", rawName: "v-viewer" }],
                    attrs: { src: item.washingUrl, alt: "" },
                  }),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(_vm._s(_vm.multiLanguageDisplay(item.washingName))),
                  ]),
                ])
              }),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.serviceManagements.length
        ? _c(
            "el-card",
            { staticClass: "box-card" },
            [
              _c("h3", [_vm._v("服务")]),
              _vm._v(" "),
              _vm._l(_vm.serviceManagements, function (item, index) {
                return _c("div", { key: item.id, staticClass: "water" }, [
                  _c("img", {
                    directives: [{ name: "viewer", rawName: "v-viewer" }],
                    attrs: { src: item.managementUrl, alt: "" },
                  }),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.multiLanguageDisplay(item.managementName))
                    ),
                  ]),
                ])
              }),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.certificationManagements.length
        ? _c(
            "el-card",
            { staticClass: "box-card" },
            [
              _c("h3", [_vm._v("认证")]),
              _vm._v(" "),
              _vm._l(_vm.certificationManagements, function (item, index) {
                return _c("div", { key: item.id, staticClass: "water" }, [
                  _c("img", {
                    directives: [{ name: "viewer", rawName: "v-viewer" }],
                    attrs: { src: item.managementUrl, alt: "" },
                  }),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.multiLanguageDisplay(item.managementName))
                    ),
                  ]),
                ])
              }),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.performanceManagements.length
        ? _c(
            "el-card",
            { staticClass: "box-card" },
            [
              _c("h3", [_vm._v("表现")]),
              _vm._v(" "),
              _vm._l(_vm.performanceManagements, function (item, index) {
                return _c("div", { key: item.id, staticClass: "water" }, [
                  _c("img", {
                    directives: [{ name: "viewer", rawName: "v-viewer" }],
                    attrs: { src: item.managementUrl, alt: "" },
                  }),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.multiLanguageDisplay(item.managementName))
                    ),
                  ]),
                ])
              }),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _c("el-card", { staticClass: "box-card" }, [
        _c("h3", [_vm._v("商品规格")]),
        _vm._v(" "),
        _c(
          "span",
          [
            _c(
              "el-row",
              { attrs: { type: "flex" } },
              [
                _c(
                  "el-col",
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "请选择", disabled: "" },
                        model: {
                          value: _vm.value,
                          callback: function ($$v) {
                            _vm.value = $$v
                          },
                          expression: "value",
                        },
                      },
                      _vm._l(_vm.specList, function (item) {
                        return _c("el-option", {
                          key: item.id,
                          attrs: { label: item.name, value: item.name },
                        })
                      }),
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { plain: true, type: "primary", size: "medium" },
                        on: { click: _vm.handleSelectSpec },
                      },
                      [_vm._v("\n            同步\n          ")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-row",
              { attrs: { type: "flex" } },
              [
                _c(
                  "el-col",
                  _vm._l(_vm.selectedSpecList, function (item) {
                    return _c(
                      "div",
                      {
                        key: item.value,
                        staticStyle: { padding: "8px 0px 0px 0px" },
                      },
                      [
                        _c("span", [_vm._v(_vm._s(item.name))]),
                        _vm._v(" "),
                        _c(
                          "el-checkbox-group",
                          {
                            model: {
                              value: item.checkItem,
                              callback: function ($$v) {
                                _vm.$set(item, "checkItem", $$v)
                              },
                              expression: "item.checkItem",
                            },
                          },
                          _vm._l(item.items, function (city) {
                            return _c(
                              "el-checkbox",
                              { key: city, attrs: { label: city } },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.multiLanguageDisplay(city)) +
                                    "\n              "
                                ),
                              ]
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    )
                  }),
                  0
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c("h3", [_vm._v("商品库存")]),
          _vm._v(" "),
          _c(
            "el-input",
            {
              staticStyle: { width: "250px", "padding-bottom": "5px" },
              attrs: { type: "number", placeholder: "货品库存" },
              model: {
                value: _vm.commonStock,
                callback: function ($$v) {
                  _vm.commonStock = $$v
                },
                expression: "commonStock",
              },
            },
            [
              _c(
                "el-button",
                {
                  staticStyle: {
                    color: "white",
                    "border-radius": "inherit",
                    "background-color": "rgb(24, 144, 255)",
                    border: "1px solid #1890ff",
                  },
                  attrs: { slot: "append" },
                  on: {
                    click: function ($event) {
                      return _vm.handleSynStock()
                    },
                  },
                  slot: "append",
                },
                [_vm._v("\n        批量添加\n      ")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            { attrs: { data: _vm.newProducts } },
            [
              _c("el-table-column", {
                attrs: { property: "value", label: "货品规格", width: "400" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return _vm._l(scope.row.specifications, function (tag) {
                        return _c("el-tag", { key: tag }, [
                          _vm._v(_vm._s(_vm.multiLanguageDisplay(tag))),
                        ])
                      })
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { property: "price", label: "货品售价" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { property: "number", label: "货品库存" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-input", {
                          staticStyle: { width: "150px" },
                          attrs: { type: "number" },
                          model: {
                            value: scope.row.number,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "number", $$v)
                            },
                            expression: "scope.row.number",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { property: "minOrderNum", label: "起订量" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-input-number", {
                          staticStyle: { width: "150px" },
                          attrs: { min: 0 },
                          on: {
                            blur: function ($event) {
                              return _vm.blurInp(scope)
                            },
                          },
                          model: {
                            value: scope.row.minOrderNum,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "minOrderNum", $$v)
                            },
                            expression: "scope.row.minOrderNum",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "操作",
                  width: "200",
                  "class-name": "small-padding fixed-width",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "danger", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.handleDeleteStock(scope.row)
                              },
                            },
                          },
                          [_vm._v("\n            删除\n          ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              attrs: { visible: _vm.productVisiable, title: "编辑货品" },
              on: {
                "update:visible": function ($event) {
                  _vm.productVisiable = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "productForm",
                  staticStyle: { width: "400px", "margin-left": "50px" },
                  attrs: {
                    model: _vm.productForm,
                    "status-icon": "",
                    "label-position": "left",
                    "label-width": "100px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "货品规格列", prop: "specifications" } },
                    _vm._l(_vm.productForm.specifications, function (tag) {
                      return _c("el-tag", { key: tag }, [_vm._v(_vm._s(tag))])
                    }),
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "货品库存", prop: "number" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.productForm.number,
                          callback: function ($$v) {
                            _vm.$set(_vm.productForm, "number", $$v)
                          },
                          expression: "productForm.number",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "货品图片", prop: "url" } },
                    [
                      _c(
                        "el-upload",
                        {
                          staticClass: "avatar-uploader",
                          attrs: {
                            headers: _vm.headers,
                            action: _vm.uploadPath,
                            "show-file-list": false,
                            "on-success": _vm.uploadProductUrl,
                            "before-upload": function (file) {
                              this$1.limg(file, 800)
                            },
                            accept: ".jpg, .jpeg, .png, .gif",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "tip",
                              fn: function () {
                                return [
                                  _vm._v(
                                    "\n              图片不可超过800kb\n            "
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ]),
                        },
                        [
                          _vm.productForm.url
                            ? _c("img", {
                                staticClass: "avatar",
                                attrs: { src: _vm.productForm.url },
                              })
                            : _c("i", {
                                staticClass:
                                  "el-icon-plus avatar-uploader-icon",
                              }),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.productVisiable = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handleProductEdit },
                    },
                    [_vm._v("确定")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c("h3", [_vm._v("商品参数")]),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.handleAttributeAdd },
            },
            [_vm._v("\n      添加\n    ")]
          ),
          _vm._v(" "),
          _c(
            "el-table",
            { attrs: { data: _vm.attributesData } },
            [
              _c("el-table-column", {
                attrs: { property: "attribute", label: "商品参数名称" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-input", {
                          staticStyle: { width: "200px" },
                          model: {
                            value: scope.row.attribute,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "attribute", $$v)
                            },
                            expression: "scope.row.attribute",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { property: "value", label: "商品参数值" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-input", {
                          staticStyle: { width: "200px" },
                          model: {
                            value: scope.row.value,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "value", $$v)
                            },
                            expression: "scope.row.value",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "操作",
                  width: "200",
                  "class-name": "small-padding fixed-width",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "danger", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.handleAttributeDelete(scope.row)
                              },
                            },
                          },
                          [_vm._v("\n            删除\n          ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-card",
        { staticClass: "box-card edit" },
        [
          _c("h3", [_vm._v("商品详情")]),
          _vm._v(" "),
          _vm.goods.detailType == 0
            ? _c(
                "div",
                [
                  _c(
                    "el-form",
                    {
                      ref: "goods",
                      attrs: {
                        rules: _vm.rules,
                        model: _vm.goods,
                        "label-width": "90px",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "商品详情", prop: "detail" } },
                        [
                          _c("tinymce", {
                            attrs: { width: 680, height: 800 },
                            model: {
                              value: _vm.goods.detail,
                              callback: function ($$v) {
                                _vm.$set(_vm.goods, "detail", $$v)
                              },
                              expression: "goods.detail",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _c(
                "div",
                {
                  staticClass: "detailEdit",
                  staticStyle: { width: "750px" },
                  on: { click: _vm.gotodetail },
                },
                _vm._l(_vm.goods.detailTemplate, function (goodsDItem, index) {
                  return _c("preview-temp", {
                    key: index,
                    attrs: { "goods-d-item": goodsDItem },
                  })
                }),
                1
              ),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "商品详情",
                visible: _vm.detailVisible,
                width: "95%",
                top: "0",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.detailVisible = $event
                },
              },
            },
            [
              _c("template-edit", {
                attrs: {
                  "goods-detail-list": _vm.goods.detailTemplate,
                  headers: _vm.headers,
                },
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.detailVisible = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.confirmEdit },
                    },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c("h3", [_vm._v("商品配置")]),
          _vm._v(" "),
          _c(
            "el-form",
            {
              ref: "goods",
              attrs: {
                rules: _vm.rules,
                model: _vm.goods,
                "label-width": "90px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "是否预售", prop: "presale" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.goods.presale,
                        callback: function ($$v) {
                          _vm.$set(_vm.goods, "presale", $$v)
                        },
                        expression: "goods.presale",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("现货"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("预售"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "是否新品", prop: "isNew" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.goods.isNew,
                        callback: function ($$v) {
                          _vm.$set(_vm.goods, "isNew", $$v)
                        },
                        expression: "goods.isNew",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("新品"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("非新品"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "是否热卖", prop: "isHot" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.goods.isHot,
                        callback: function ($$v) {
                          _vm.$set(_vm.goods, "isHot", $$v)
                        },
                        expression: "goods.isHot",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("普通"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("热卖"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "是否在售", prop: "isOnSale" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.goods.isOnSale,
                        callback: function ($$v) {
                          _vm.$set(_vm.goods, "isOnSale", $$v)
                        },
                        expression: "goods.isOnSale",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("在售"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("未售"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "op-container" },
        [
          _c("el-button", { on: { click: _vm.handleCancel } }, [
            _vm._v("取消"),
          ]),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { loading: _vm.loading, type: "primary" },
              on: { click: _vm.handleEdit },
            },
            [_vm._v("\n      更新商品\n    ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }