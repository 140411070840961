var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "160px" },
            attrs: { clearable: "", placeholder: "请输入商品ID" },
            model: {
              value: _vm.listQuery.goodsId,
              callback: function ($$v) {
                _vm.$set(_vm.listQuery, "goodsId", $$v)
              },
              expression: "listQuery.goodsId",
            },
          }),
          _vm._v(" "),
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "160px" },
            attrs: { clearable: "", placeholder: "请输入商品款号" },
            model: {
              value: _vm.listQuery.goodsSn,
              callback: function ($$v) {
                _vm.$set(_vm.listQuery, "goodsSn", $$v)
              },
              expression: "listQuery.goodsSn",
            },
          }),
          _vm._v(" "),
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "160px" },
            attrs: { clearable: "", placeholder: "请输入商品名称" },
            model: {
              value: _vm.listQuery.name,
              callback: function ($$v) {
                _vm.$set(_vm.listQuery, "name", $$v)
              },
              expression: "listQuery.name",
            },
          }),
          _vm._v(" "),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "160px" },
              attrs: {
                filterable: "",
                clearable: "",
                placeholder: "请选择商品渠道",
              },
              model: {
                value: _vm.listQuery.channel,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery, "channel", $$v)
                },
                expression: "listQuery.channel",
              },
            },
            _vm._l(_vm.channelList, function (item) {
              return _c("el-option", {
                key: item.id,
                attrs: { label: item.label, value: item.id },
              })
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "160px" },
              attrs: {
                filterable: "",
                clearable: "",
                placeholder: "请选择供应商",
              },
              model: {
                value: _vm.listQuery.enterpriseId,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery, "enterpriseId", $$v)
                },
                expression: "listQuery.enterpriseId",
              },
            },
            _vm._l(_vm.listSupplierName, function (item) {
              return _c("el-option", {
                key: item.enterpriseId,
                attrs: { label: item.enterpriseName, value: item.enterpriseId },
              })
            }),
            1
          ),
          _vm._v(" "),
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "160px" },
            attrs: { clearable: "", placeholder: "请输入供应商款号" },
            model: {
              value: _vm.listQuery.supplierStyleNo,
              callback: function ($$v) {
                _vm.$set(_vm.listQuery, "supplierStyleNo", $$v)
              },
              expression: "listQuery.supplierStyleNo",
            },
          }),
          _vm._v(" "),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "160px" },
              attrs: {
                filterable: "",
                clearable: "",
                placeholder: "请选择品牌",
              },
              model: {
                value: _vm.listQuery.brand,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery, "brand", $$v)
                },
                expression: "listQuery.brand",
              },
            },
            _vm._l(_vm.listBrand, function (item) {
              return _c("el-option", {
                key: item,
                attrs: { label: item, value: item },
              })
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "160px" },
              attrs: {
                filterable: "",
                clearable: "",
                multiple: "",
                "collapse-tags": "",
                placeholder: "请选择成分",
              },
              model: {
                value: _vm.compositionL2,
                callback: function ($$v) {
                  _vm.compositionL2 = $$v
                },
                expression: "compositionL2",
              },
            },
            _vm._l(_vm.listComposition, function (item) {
              return _c("el-option", {
                key: item.compositionId,
                attrs: {
                  label: item.compositionName,
                  value: item.compositionId,
                },
              })
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "160px" },
              attrs: {
                filterable: "",
                clearable: "",
                placeholder: "请选择商品状态",
              },
              model: {
                value: _vm.listQuery.authStatus,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery, "authStatus", $$v)
                },
                expression: "listQuery.authStatus",
              },
            },
            _vm._l(_vm.authStatusList, function (item, index) {
              return _c("el-option", {
                key: index,
                attrs: { label: item.name, value: item.id },
              })
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "160px" },
              attrs: {
                filterable: "",
                clearable: "",
                placeholder: "请选择一级类目",
              },
              model: {
                value: _vm.listQuery.categoryIdL1,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery, "categoryIdL1", $$v)
                },
                expression: "listQuery.categoryIdL1",
              },
            },
            _vm._l(_vm.catL1, function (item) {
              return _c("el-option", {
                key: item.value,
                attrs: {
                  label: _vm.multiLanguageDisplay(item.label),
                  value: item.value,
                },
              })
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "160px" },
              attrs: {
                filterable: "",
                clearable: "",
                placeholder: "请选择是否有图片",
              },
              model: {
                value: _vm.listQuery.hasPic,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery, "hasPic", $$v)
                },
                expression: "listQuery.hasPic",
              },
            },
            [
              _c("el-option", { attrs: { label: "是", value: true } }),
              _vm._v(" "),
              _c("el-option", { attrs: { label: "否", value: false } }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: { click: _vm.handleFilter },
            },
            [_vm._v("\n      查找\n    ")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-edit" },
              on: { click: _vm.handleCreate },
            },
            [_vm._v("\n      添加\n    ")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: {
                loading: _vm.downloadLoading,
                type: "primary",
                icon: "el-icon-download",
              },
              on: { click: _vm.handleDownload },
            },
            [_vm._v("\n      导出\n    ")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-edit-outline" },
              on: { click: _vm.print },
            },
            [_vm._v("\n      批量打印\n    ")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          attrs: {
            data: _vm.list,
            "element-loading-text": "正在查询中。。。",
            border: "",
            fit: "",
            "highlight-current-row": "",
            "row-key": _vm.getRowKeys,
          },
          on: { "selection-change": _vm.selectAll },
        },
        [
          _c("el-table-column", {
            attrs: {
              type: "selection",
              "reserve-selection": true,
              align: "center",
              width: "55",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { type: "expand" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (props) {
                  return [
                    _c(
                      "el-form",
                      {
                        staticClass: "table-expand",
                        attrs: { "label-position": "left" },
                      },
                      [
                        _c("el-form-item", { attrs: { label: "商品款号" } }, [
                          _c("span", [_vm._v(_vm._s(props.row.goodsSn))]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "宣传画廊" } },
                          _vm._l(props.row.gallery, function (pic) {
                            return _c("img", {
                              key: pic,
                              staticClass: "gallery",
                              attrs: { src: pic },
                            })
                          }),
                          0
                        ),
                        _vm._v(" "),
                        _c("el-form-item", { attrs: { label: "商品介绍" } }, [
                          _c("span", [_vm._v(_vm._s(props.row.brief))]),
                        ]),
                        _vm._v(" "),
                        _c("el-form-item", { attrs: { label: "商品单位" } }, [
                          _c("span", [_vm._v(_vm._s(props.row.unit))]),
                        ]),
                        _vm._v(" "),
                        _c("el-form-item", { attrs: { label: "关键字" } }, [
                          _c("span", [_vm._v(_vm._s(props.row.keywords))]),
                        ]),
                        _vm._v(" "),
                        _c("el-form-item", { attrs: { label: "类目ID" } }, [
                          _c("span", [_vm._v(_vm._s(props.row.categoryId))]),
                        ]),
                        _vm._v(" "),
                        _c("el-form-item", { attrs: { label: "品牌商ID" } }, [
                          _c("span", [_vm._v(_vm._s(props.row.brandId))]),
                        ]),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "商品ID", prop: "id" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "商品状态",
              "min-width": "100",
              prop: "authStatus",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.transStatus(scope.row.authStatus))),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "商品渠道", prop: "channel" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.channel == 0
                      ? _c("span", [_vm._v("线上")])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.channel == 1
                      ? _c("span", [_vm._v("线下")])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              "min-width": "100",
              label: "名称",
              prop: "name",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.multiLanguageDisplay(scope.row.name)) +
                        "\n        "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              "min-width": "100",
              label: "供应商名称",
              prop: "enterpriseName",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.multiLanguageDisplay(scope.row.enterpriseName)
                        ) +
                        "\n        "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              "min-width": "100",
              label: "供应商款号",
              prop: "supplierStyleNo",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(scope.row.supplierStyleNo) +
                        "\n        "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", property: "iconUrl", label: "图片" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("el-image", {
                      staticStyle: { width: "60px", height: "60px" },
                      attrs: {
                        src: scope.row.picUrl,
                        "preview-src-list": [scope.row.picUrl],
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              property: "counterPrice",
              label: "市场售价",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(scope.row.counterPrice) +
                        "\n        "
                    ),
                    _c("i", {
                      staticClass: "el-icon-edit",
                      on: {
                        click: function ($event) {
                          return _vm.handleUpdateCounterPrice(scope.row)
                        },
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "库存",
              prop: "id",
              "min-width": "80",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "span",
                      { staticStyle: { float: "left", "padding-top": "4px" } },
                      [_vm._v(_vm._s(scope.row.stock))]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        staticStyle: { float: "right" },
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.showStock(scope.row.id)
                          },
                        },
                      },
                      [_vm._v("\n          查看\n        ")]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "单位", prop: "unit" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "是否新品",
              prop: "isNew",
              width: "80",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("el-switch", {
                      attrs: { "active-value": true, "inactive-value": false },
                      on: {
                        change: function ($event) {
                          return _vm.handleProductStateChange(scope.row)
                        },
                      },
                      model: {
                        value: scope.row.new,
                        callback: function ($$v) {
                          _vm.$set(scope.row, "new", $$v)
                        },
                        expression: "scope.row.new",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "是否热品",
              prop: "isHot",
              width: "80",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("el-switch", {
                      attrs: { "active-value": true, "inactive-value": false },
                      on: {
                        change: function ($event) {
                          return _vm.handleProductStateChange(scope.row)
                        },
                      },
                      model: {
                        value: scope.row.hot,
                        callback: function ($$v) {
                          _vm.$set(scope.row, "hot", $$v)
                        },
                        expression: "scope.row.hot",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "日志", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini", type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.openLog(scope.row)
                          },
                        },
                      },
                      [_vm._v("\n          查看\n        ")]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "审核人", prop: "auditUserName" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "操作",
              width: "250",
              "class-name": "small-padding fixed-width",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini", type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.handleUpdate(scope.row)
                          },
                        },
                      },
                      [_vm._v("\n          编辑\n        ")]
                    ),
                    _vm._v(" "),
                    scope.row.authStatus != 2
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.handelReview(scope.row)
                              },
                            },
                          },
                          [_vm._v("\n          审核\n        ")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.authStatus == 2
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.handelDown(scope.row)
                              },
                            },
                          },
                          [_vm._v("\n          下架\n        ")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini", type: "danger" },
                        on: {
                          click: function ($event) {
                            return _vm.handleDelete(scope.row)
                          },
                        },
                      },
                      [_vm._v("\n          删除\n        ")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total > 0",
          },
        ],
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getList,
        },
      }),
      _vm._v(" "),
      _c(
        "el-tooltip",
        { attrs: { placement: "top", content: "返回顶部" } },
        [_c("back-to-top", { attrs: { "visibility-height": 100 } })],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.stockDialogVisible, title: "商品库存" },
          on: {
            "update:visible": function ($event) {
              _vm.stockDialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-input",
            {
              staticStyle: { width: "250px", "padding-bottom": "5px" },
              attrs: { type: "number", placeholder: "货品库存" },
              model: {
                value: _vm.commonStock,
                callback: function ($$v) {
                  _vm.commonStock = $$v
                },
                expression: "commonStock",
              },
            },
            [
              _c(
                "el-button",
                {
                  staticStyle: {
                    color: "white",
                    "border-radius": "inherit",
                    "background-color": "rgb(24, 144, 255)",
                    border: "1px solid #1890ff",
                  },
                  attrs: { slot: "append" },
                  on: {
                    click: function ($event) {
                      return _vm.handleSynStock()
                    },
                  },
                  slot: "append",
                },
                [_vm._v("\n        批量添加\n      ")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              attrs: {
                data: _vm.products,
                border: "",
                fit: "",
                "highlight-current-row": "",
                "max-height": "250",
              },
            },
            [
              _c("el-table-column", {
                attrs: { property: "value", label: "货品规格" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return _vm._l(scope.row.specifications, function (tag) {
                        return _c(
                          "el-tag",
                          { key: tag, staticStyle: { "margin-right": "5px" } },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.multiLanguageDisplay(tag)) +
                                "\n          "
                            ),
                          ]
                        )
                      })
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { property: "price", label: "货品售价" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { property: "number", label: "货品库存" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-input", {
                          staticClass: "input-width",
                          staticStyle: { padding: "0px 0px 0px 10px" },
                          attrs: { type: "number" },
                          model: {
                            value: scope.row.number,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "number", $$v)
                            },
                            expression: "scope.row.number",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "操作",
                  width: "200",
                  "class-name": "small-padding fixed-width",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "danger", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.handleDeleteStock(scope.row)
                              },
                            },
                          },
                          [_vm._v("\n            删除\n          ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.stockDialogVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleUpdateStock },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticStyle: { margin: "30px 0px 5px 0px" } }),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.logListLoading,
                  expression: "logListLoading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.logList,
                "element-loading-text": "正在查询中。。。",
                "max-height": "250",
                border: "",
                fit: "",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: { property: "comment", label: "货品规格" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { property: "content", label: "货品库存" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { property: "addTime", label: "操作时间" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { property: "admin", label: "操作用户" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            [
              _c("pagination", {
                attrs: {
                  total: _vm.logTotal,
                  page: _vm.logListQuery.page,
                  limit: _vm.logListQuery.limit,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.logListQuery, "page", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.logListQuery, "limit", $event)
                  },
                  pagination: _vm.getLogList,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.priceDialogVisible,
            title: "市场售价",
            width: "800px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.priceDialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "priceForm",
              staticStyle: { width: "400px", "margin-left": "50px" },
              attrs: {
                rules: _vm.priceRules,
                model: _vm.priceForm,
                "status-icon": "",
                "label-position": "left",
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "市场售价", prop: "counterPrice" } },
                [
                  _c(
                    "el-input",
                    {
                      staticClass: "input-width",
                      attrs: { placeholder: "0.00" },
                      model: {
                        value: _vm.priceForm.counterPrice,
                        callback: function ($$v) {
                          _vm.$set(_vm.priceForm, "counterPrice", $$v)
                        },
                        expression: "priceForm.counterPrice",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "80px" },
                          attrs: { slot: "prepend" },
                          slot: "prepend",
                          model: {
                            value: _vm.priceForm.currency,
                            callback: function ($$v) {
                              _vm.$set(_vm.priceForm, "currency", $$v)
                            },
                            expression: "priceForm.currency",
                          },
                        },
                        _vm._l(_vm.unitOptions, function (c) {
                          return _c(
                            "el-option",
                            { key: c, attrs: { value: c } },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(c) +
                                  "\n            "
                              ),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.priceDialogVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.updatePrice } },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.logDialogVisible, title: "日志" },
          on: {
            "update:visible": function ($event) {
              _vm.logDialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.logListLoading,
                  expression: "logListLoading",
                },
              ],
              attrs: {
                data: _vm.listLog,
                "max-height": "500px",
                "element-loading-text": "正在查询中。。。",
                border: "",
                fit: "",
                size: "small",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: { label: "时间", prop: "operatorTime" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "单号", prop: "businessNum" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "内容", prop: "content" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "详情", prop: "detail" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-link",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.getDetail(row)
                              },
                            },
                          },
                          [_vm._v("详情")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作员", prop: "operator" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作账号", prop: "operatorAccount" },
              }),
              _vm._v(" "),
              _c("el-table-column", { attrs: { label: "IP", prop: "ip" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              attrs: {
                width: "50%",
                title: "详情",
                visible: _vm.infoShow,
                "append-to-body": "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.infoShow = $event
                },
              },
            },
            [_c("span", { domProps: { innerHTML: _vm._s(_vm.info) } })]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "reviewLog",
          attrs: {
            visible: _vm.reviewDialogVisible,
            width: "500px",
            "show-close": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.reviewDialogVisible = $event
            },
          },
        },
        [
          _vm.reviewStatus
            ? [
                _c("div", { staticClass: "cont" }, [
                  _c("div", { staticClass: "title" }, [
                    _vm._v("是否同意上架商品"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "tip" }, [
                    _vm._v("请仔细查看商品详情，是否同意上架此款闪购，可"),
                    _c(
                      "a",
                      {
                        attrs: { href: "javascript:void(0);" },
                        on: { click: _vm.toDetail },
                      },
                      [_vm._v("点击查看")]
                    ),
                    _vm._v("详情"),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "footer",
                    attrs: { slot: "footer" },
                    slot: "footer",
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "btn",
                        staticStyle: { color: "red" },
                        on: { click: _vm.onRefuse },
                      },
                      [_vm._v("拒绝上架")]
                    ),
                    _vm._v(" "),
                    _c("span", { staticClass: "line" }),
                    _vm._v(" "),
                    _c(
                      "span",
                      { staticClass: "btn", on: { click: _vm.onSureUp } },
                      [_vm._v("确认上架")]
                    ),
                  ]
                ),
              ]
            : [
                _c(
                  "div",
                  { staticClass: "cont" },
                  [
                    _c("div", { staticClass: "title" }, [
                      _vm._v("拒绝上架该款商品"),
                    ]),
                    _vm._v(" "),
                    _c("el-input", {
                      attrs: {
                        type: "textarea",
                        rows: 3,
                        placeholder: "请输入拒绝理由",
                      },
                      model: {
                        value: _vm.reason,
                        callback: function ($$v) {
                          _vm.reason = $$v
                        },
                        expression: "reason",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "footer",
                    attrs: { slot: "footer" },
                    slot: "footer",
                  },
                  [
                    _c(
                      "span",
                      { staticClass: "btn", on: { click: _vm.oncancel } },
                      [_vm._v("取消")]
                    ),
                    _vm._v(" "),
                    _c("span", { staticClass: "line" }),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass: "btn",
                        staticStyle: { color: "red" },
                        on: { click: _vm.onRefuseReason },
                      },
                      [_vm._v("拒绝")]
                    ),
                  ]
                ),
              ],
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "reviewLog",
          attrs: {
            visible: _vm.removeDialogVisible,
            width: "500px",
            "show-close": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.removeDialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "cont" },
            [
              _c("div", { staticClass: "title" }, [_vm._v("是否确认下架商品")]),
              _vm._v(" "),
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  rows: 3,
                  placeholder: "请输入下架原因",
                },
                model: {
                  value: _vm.downReason,
                  callback: function ($$v) {
                    _vm.downReason = $$v
                  },
                  expression: "downReason",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "span",
                {
                  staticClass: "btn",
                  on: {
                    click: function ($event) {
                      _vm.removeDialogVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c("span", { staticClass: "line" }),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "btn",
                  staticStyle: { color: "red" },
                  on: { click: _vm.onDownProduct },
                },
                [_vm._v("下架")]
              ),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }