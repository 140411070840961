"use strict";

var _interopRequireDefault = require("/var/jenkins_home/workspace/sihong_test_front_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.array.sort");
var _interopRequireWildcard2 = _interopRequireDefault(require("/var/jenkins_home/workspace/sihong_test_front_mall/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));
require("core-js/modules/es6.regexp.split");
var _user = require("@/api/user");
var _config = require("@/api/config");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'UserList',
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    var validateAdvance = function validateAdvance(rule, value, callback) {
      if (value < 0 || value > 100) {
        callback(new Error('预售金额在0-100之间!'));
      } else {
        callback();
      }
    };
    var validateAmount = function validateAmount(rule, value, callback) {
      if (value <= 0) {
        callback(new Error('结算金额必需大于0!'));
      }
      var dataval = value.split('.');
      if (dataval.length > 1 && dataval[1].length > 2) {
        callback(new Error('结算金额最多保留2位小数!'));
      } else {
        callback();
      }
    };
    return {
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 20,
        username: undefined,
        mobile: undefined,
        sort: 'add_time',
        order: 'desc'
      },
      downloadLoading: false,
      genderDic: ['未知', '男', '女'],
      statusDic: ['可用', '禁用', '注销'],
      // 我的邀请
      dialogVisiable: false,
      upperList: [],
      subListLoading: false,
      subList: [],
      subTotal: 0,
      subListQuery: {
        userId: undefined,
        page: 1,
        limit: 20,
        sort: 'id',
        order: 'desc'
      },
      // 选择用户
      selectDialogVisiable: false,
      selectLoading: false,
      selectList: [],
      selectTotal: 0,
      selectQuery: {
        page: 1,
        limit: 20,
        sort: 'id',
        order: 'desc',
        username: '',
        mobile: ''
      },
      idKey: 'id',
      multipleSelection: [],
      multipleSelectionAll: [],
      // 用户等级
      dialogFormVisible: false,
      dataForm: {
        id: undefined,
        levelId: undefined
      },
      levelList: [],
      rules: {
        levelId: [{
          required: true,
          message: '用户等级不能为空',
          trigger: 'blur'
        }]
      },
      // 预付金额
      advanceDialogVisible: false,
      advanceForm: {
        id: undefined,
        advanceRatio: undefined
      },
      advanceRules: {
        advanceRatio: [{
          required: true,
          message: '预付金额不能为空',
          trigger: 'blur'
        }, {
          validator: validateAdvance,
          trigger: 'blur'
        }]
      },
      // 分销订单
      orderDialogVisiable: false,
      tab: '1',
      orderLoading: false,
      orderList: [],
      orderTotal: 3,
      orderQuery: {
        page: 1,
        limit: 20,
        sort: 'id',
        order: 'desc',
        type: 1,
        userId: 0
      },
      distributorData: {
        commission: 0,
        distributorOrderCount: 0,
        finishOrderCount: 0,
        orderAmount: 0,
        unsettledAmount: 0,
        withdrawAmount: 0,
        withdrawableAmount: 0
      },
      // 结算
      incomeDialogVisiable: false,
      incomeForm: {
        withdrawableAmount: undefined,
        amount: undefined
      },
      incomeRules: {
        amount: [{
          required: true,
          message: '结算金额不能为空',
          trigger: 'blur'
        }, {
          validator: validateAmount,
          trigger: 'blur'
        }]
      }
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;
      this.listLoading = true;
      (0, _user.fetchList)(this.listQuery).then(function (response) {
        _this.list = response.data.data.list;
        _this.total = response.data.data.total;
        _this.listLoading = false;
      }).catch(function () {
        _this.list = [];
        _this.total = 0;
        _this.listLoading = false;
      });
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleDownload: function handleDownload() {
      var _this2 = this;
      this.downloadLoading = true;
      Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/vendor/Export2Excel'));
      }).then(function (excel) {
        var tHeader = ['用户名', '手机号码', '性别', '生日', '状态'];
        var filterVal = ['username', 'mobile', 'gender', 'birthday', 'status'];
        excel.export_json_to_excel2(tHeader, _this2.list, filterVal, '用户信息');
        _this2.downloadLoading = false;
      });
    },
    // 查看我的邀请
    handleLook: function handleLook(row) {
      this.subListQuery.userId = row.id;
      this.dialogVisiable = true;
      this.getSubList();
      this.getUpperList();
    },
    // 获取用户下级列表
    getUpperList: function getUpperList() {
      var _this3 = this;
      (0, _user.listUpper)({
        userId: this.subListQuery.userId
      }).then(function (response) {
        _this3.upperList = response.data.data;
      }).catch(function () {
        _this3.upperList = [];
      });
    },
    // 获取用户下级列表
    getSubList: function getSubList() {
      var _this4 = this;
      this.subListLoading = true;
      (0, _user.listSub)(this.subListQuery).then(function (response) {
        _this4.subList = response.data.data.list;
        _this4.subTotal = response.data.data.total;
        _this4.subListLoading = false;
      }).catch(function () {
        _this4.subList = [];
        _this4.subTotal = 0;
        _this4.subListLoading = false;
      });
    },
    // 移除
    handleRemove: function handleRemove(row) {
      var _this5 = this;
      this.$confirm('是否要移除该用户?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _user.deleteUserSub)({
          subId: row.userId
        }).then(function (res) {
          _this5.getSubList();
          _this5.$notify.success({
            title: '成功',
            message: '操作成功'
          });
        }).catch(function (response) {
          _this5.$notify.error({
            title: '失败',
            message: response.data.errmsg
          });
        });
      });
    },
    // 添加我邀请的用户
    handleAddUser: function handleAddUser() {
      var that = this;
      that.selectQuery.page = 1;
      that.selectList = [];
      that.selectTotal = 0;
      that.selectDialogVisiable = true;
      this.multipleSelectionAll = [];
      that.getSelectList();
    },
    // 选择用户：查询
    handleFilterSelect: function handleFilterSelect() {
      var that = this;
      // 改变页的时候调用一次
      that.changePageCoreRecordData().then(function (val) {
        that.selectQuery.page = 1;
        that.getSelectList();
      });
    },
    // 选择用户:用户列表页码、页数改变
    handlePaginChange: function handlePaginChange() {
      var that = this;
      // 改变页的时候调用一次
      that.changePageCoreRecordData().then(function (val) {
        that.getSelectList();
      });
    },
    // 选择用户：获取用户列表
    getSelectList: function getSelectList() {
      var _this6 = this;
      this.selectLoading = true;
      (0, _user.fetchList)({
        page: this.selectQuery.page,
        limit: this.selectQuery.limit,
        sort: this.selectQuery.sort,
        order: this.selectQuery.order,
        username: this.selectQuery.username,
        mobile: this.selectQuery.mobile
      }).then(function (response) {
        _this6.selectList = response.data.data.list;
        _this6.selectTotal = response.data.data.total;
        _this6.selectLoading = false;
        setTimeout(function () {
          _this6.setSelectRow();
        }, 200);
      }).catch(function (response) {
        _this6.selectList = [];
        _this6.selectTotal = 0;
        _this6.selectLoading = false;
      });
    },
    // 选择用户：多选改变事件
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 选择用户：取消选择
    handleCancelSelect: function handleCancelSelect() {
      this.selectDialogVisiable = false;
    },
    // 选择用户：确定选择
    handleSubmitSelect: function handleSubmitSelect() {
      var _this7 = this;
      // 改变页的时候调用一次
      var that = this;
      this.changePageCoreRecordData().then(function (val) {
        // 接口：添加用户
        if (that.multipleSelectionAll.length === 0) {
          _this7.$message.info('请先选择用户');
        } else {
          var subIds = [];
          that.multipleSelectionAll.forEach(function (item, index, array) {
            subIds.push(item.id);
          });
          (0, _user.addUserSub)({
            userId: _this7.subListQuery.userId,
            subIds: subIds
          }).then(function (res) {
            _this7.getSubList();
            _this7.selectDialogVisiable = false;
            if (res.data.data.invalidUserIds.length > 0) {
              var userStr = res.data.data.invalidUserIds.join(',');
              _this7.$notify.error({
                title: '失败',
                message: '用户' + userStr + '已有邀请人，不可再被添加'
              });
            } else {
              _this7.$notify.success({
                title: '成功',
                message: '操作成功'
              });
            }
          }).catch(function (response) {
            _this7.$notify.error({
              title: '失败',
              message: response.data.errmsg
            });
          });
        }
      });
    },
    setSelectRow: function setSelectRow() {
      if (!this.multipleSelectionAll || this.multipleSelectionAll.length <= 0) {
        return;
      }
      var idKey = this.idKey;
      var selectAllIds = [];
      this.multipleSelectionAll.forEach(function (row) {
        selectAllIds.push(row[idKey]);
      });
      this.$refs.multipleTable.clearSelection();
      for (var i = 0; i < this.selectList.length; i++) {
        if (selectAllIds.indexOf(this.selectList[i][idKey]) >= 0) {
          this.$refs.multipleTable.toggleRowSelection(this.selectList[i], true);
        }
      }
    },
    changePageCoreRecordData: function changePageCoreRecordData() {
      var _this8 = this;
      return new Promise(function (resolve, reject) {
        var idKey = _this8.idKey;
        if (_this8.multipleSelectionAll.length <= 0) {
          var selectGoodIds = [];
          _this8.multipleSelection.forEach(function (row) {
            selectGoodIds.push({
              id: row[idKey]
            });
          });
          _this8.multipleSelectionAll = selectGoodIds;
          resolve();
          return;
        }
        var selectAllIds = [];
        _this8.multipleSelectionAll.forEach(function (row) {
          selectAllIds.push(row[idKey]);
        });
        var selectIds = [];
        _this8.multipleSelection.forEach(function (row) {
          selectIds.push(row[idKey]);
          if (selectAllIds.indexOf(row[idKey]) < 0) {
            _this8.multipleSelectionAll.push({
              id: row[idKey]
            });
          }
        });
        var noSelectIds = [];
        _this8.selectList.forEach(function (row) {
          if (selectIds.indexOf(row[idKey]) < 0) {
            noSelectIds.push(row[idKey]);
          }
        });
        noSelectIds.forEach(function (id) {
          if (selectAllIds.indexOf(id) >= 0) {
            for (var i = 0; i < _this8.multipleSelectionAll.length; i++) {
              if (_this8.multipleSelectionAll[i][idKey] === id) {
                _this8.multipleSelectionAll.splice(i, 1);
                break;
              }
            }
          }
        });
        resolve();
      });
    },
    // 用户等级
    handleUpdateLevel: function handleUpdateLevel(row) {
      var _this9 = this;
      (0, _config.listLevel)().then(function (response) {
        _this9.levelList = response.data.data.list;
      }).catch(function () {
        _this9.levelList = [];
      });
      this.dataForm = Object.assign({}, row);
      this.dialogFormVisible = true;
      this.$nextTick(function () {
        _this9.$refs['dataForm'].clearValidate();
      });
    },
    updateData: function updateData() {
      var _this10 = this;
      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          (0, _user.updateUserLevel)({
            userId: _this10.dataForm.id,
            levelId: _this10.dataForm.levelId
          }).then(function () {
            _this10.getList();
            _this10.dialogFormVisible = false;
            _this10.$notify.success({
              title: '成功',
              message: '更新成功'
            });
          }).catch(function (response) {
            _this10.$notify.error({
              title: '失败',
              message: response.data.errmsg
            });
          });
        }
      });
    },
    // 预付金额
    handleUpdateAdvance: function handleUpdateAdvance(row) {
      var _this11 = this;
      this.advanceForm = Object.assign({}, row);
      this.advanceDialogVisible = true;
      this.$nextTick(function () {
        _this11.$refs['advanceForm'].clearValidate();
      });
    },
    updateAdvance: function updateAdvance() {
      var _this12 = this;
      this.$refs['advanceForm'].validate(function (valid) {
        if (valid) {
          (0, _user.updateUserAdvance)({
            userId: _this12.advanceForm.id,
            advanceRatio: _this12.advanceForm.advanceRatio
          }).then(function () {
            _this12.getList();
            _this12.advanceDialogVisible = false;
            _this12.$notify.success({
              title: '成功',
              message: '更新成功'
            });
          }).catch(function (response) {
            _this12.$notify.error({
              title: '失败',
              message: response.data.errmsg
            });
          });
        }
      });
    },
    // 设置分销商
    handleSetDistributor: function handleSetDistributor(row) {
      var _this13 = this;
      this.$confirm('是否要设置该用户为分销商?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _user.updateUserDistributor)({
          userId: row.id,
          status: 1
        }).then(function (response) {
          _this13.$notify.success({
            title: '成功',
            message: '操作成功'
          });
          _this13.getList();
        }).catch(function (response) {
          _this13.$notify.error({
            title: '失败',
            message: response.data.errmsg
          });
        });
      });
    },
    // 取消分销商
    handleCancelDistributor: function handleCancelDistributor(row) {
      var _this14 = this;
      this.$confirm('是否要取消该用户的分销商资格?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _user.updateUserDistributor)({
          userId: row.id,
          status: 0
        }).then(function (response) {
          _this14.$notify.success({
            title: '成功',
            message: '操作成功'
          });
          _this14.getList();
        }).catch(function (response) {
          _this14.$notify.error({
            title: '失败',
            message: response.data.errmsg
          });
        });
      });
    },
    // 分销订单
    handleOrder: function handleOrder(row) {
      this.tab = '1';
      this.orderQuery.userId = row.id;
      this.orderQuery.type = 1;
      this.orderQuery.page = 1;
      this.orderDialogVisiable = true;
      this.getOrderList();
      this.distributorInfo();
    },
    // 表格Tab点击事件
    handleClick: function handleClick() {
      this.orderQuery.type = this.tab;
      this.orderQuery.page = 1;
      this.getOrderList();
    },
    // 获取订单列表
    getOrderList: function getOrderList() {
      var _this15 = this;
      this.orderLoading = true;
      (0, _user.distributorOrderList)({
        userId: this.orderQuery.userId,
        type: this.orderQuery.type,
        page: this.orderQuery.page,
        limit: this.orderQuery.limit,
        sort: this.orderQuery.sort,
        order: this.orderQuery.order
      }).then(function (response) {
        _this15.orderList = response.data.data.list;
        _this15.orderTotal = response.data.data.total;
        _this15.orderLoading = false;
      }).catch(function (response) {
        _this15.orderList = [];
        _this15.orderTotal = 0;
        _this15.orderLoading = false;
      });
    },
    // 获取订单列表
    distributorInfo: function distributorInfo() {
      var _this16 = this;
      this.orderLoading = true;
      (0, _user.distributorInfo)({
        userId: this.orderQuery.userId
      }).then(function (response) {
        _this16.distributorData = response.data.data;
      });
    },
    // 结算
    handleIncome: function handleIncome() {
      this.incomeForm = {
        withdrawableAmount: this.distributorData.withdrawableAmount,
        amount: undefined
      };
      this.incomeDialogVisiable = true;
    },
    settleAmount: function settleAmount() {
      var _this17 = this;
      if (this.incomeForm.amount > this.incomeForm.withdrawableAmount) {
        this.$message.error('结算金额不能超过可结算金额');
        return false;
      }
      this.$refs['incomeForm'].validate(function (valid) {
        if (valid) {
          (0, _user.distributorSettle)({
            userId: _this17.orderQuery.userId,
            amount: _this17.incomeForm.amount
          }).then(function (response) {
            _this17.distributorInfo();
            _this17.$notify.success({
              title: '成功',
              message: '操作成功'
            });
          }).catch(function (response) {
            _this17.$notify.error({
              title: '失败',
              message: response.data.errmsg
            });
          });
        }
      });
    }
  }
};