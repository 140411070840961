var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container" }, [
    _c(
      "div",
      { staticClass: "content" },
      [
        _c(
          "div",
          { staticClass: "left" },
          [
            _c("el-tree", {
              ref: "myTree",
              attrs: {
                "check-strictly": true,
                "node-key": "id",
                "expand-on-click-node": false,
                "highlight-current": true,
                "icon-class": "none",
                "default-expand-all": "",
                data: _vm.data,
                props: _vm.defaultProps,
              },
              on: { "node-click": _vm.handleNodeClick },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("el-divider", { attrs: { direction: "vertical" } }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "right" },
          [_c("orderType", { ref: "orderType" })],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }