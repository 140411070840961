"use strict";

var _interopRequireDefault = require("/var/jenkins_home/workspace/sihong_test_front_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createIssue = createIssue;
exports.deleteIssue = deleteIssue;
exports.listIssue = listIssue;
exports.readIssue = readIssue;
exports.updateIssue = updateIssue;
var _request = _interopRequireDefault(require("@/utils/request"));
function listIssue(query) {
  return (0, _request.default)({
    url: '/issue/list',
    method: 'get',
    params: query
  });
}
function createIssue(data) {
  return (0, _request.default)({
    url: '/issue/create',
    method: 'post',
    data: data
  });
}
function readIssue(data) {
  return (0, _request.default)({
    url: '/issue/read',
    method: 'get',
    data: data
  });
}
function updateIssue(data) {
  return (0, _request.default)({
    url: '/issue/update',
    method: 'post',
    data: data
  });
}
function deleteIssue(data) {
  return (0, _request.default)({
    url: '/issue/delete',
    method: 'post',
    data: data
  });
}