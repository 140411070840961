"use strict";

var _interopRequireDefault = require("/var/jenkins_home/workspace/sihong_test_front_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _jsCookie = _interopRequireDefault(require("js-cookie"));
/**
* APP设置
*/
var app = {
  state: {
    sidebar: {
      /**
      * 左侧：三|||是打开还是隐藏状态sidebarStatus=1隐藏 0打开
      */
      opened: !+_jsCookie.default.get('sidebarStatus'),
      withoutAnimation: false
    },
    /**
    * 设置设备是：手机还是电脑桌面
    */
    device: 'desktop',
    /**
    * 设置语言
    */
    language: _jsCookie.default.get('language') || 'en',
    /**
    * 设置element-ui默认大小
    */
    size: _jsCookie.default.get('size') || 'medium'
  },
  mutations: {
    TOGGLE_SIDEBAR: function TOGGLE_SIDEBAR(state) {
      if (state.sidebar.opened) {
        _jsCookie.default.set('sidebarStatus', 1);
      } else {
        _jsCookie.default.set('sidebarStatus', 0);
      }
      state.sidebar.opened = !state.sidebar.opened;
      state.sidebar.withoutAnimation = false;
    },
    CLOSE_SIDEBAR: function CLOSE_SIDEBAR(state, withoutAnimation) {
      _jsCookie.default.set('sidebarStatus', 1);
      state.sidebar.opened = false;
      state.sidebar.withoutAnimation = withoutAnimation;
    },
    TOGGLE_DEVICE: function TOGGLE_DEVICE(state, device) {
      state.device = device;
    },
    SET_LANGUAGE: function SET_LANGUAGE(state, language) {
      state.language = language;
      _jsCookie.default.set('language', language);
    },
    SET_SIZE: function SET_SIZE(state, size) {
      state.size = size;
      _jsCookie.default.set('size', size);
    }
  },
  actions: {
    toggleSideBar: function toggleSideBar(_ref) {
      var commit = _ref.commit;
      commit('TOGGLE_SIDEBAR');
    },
    closeSideBar: function closeSideBar(_ref2, _ref3) {
      var commit = _ref2.commit;
      var withoutAnimation = _ref3.withoutAnimation;
      commit('CLOSE_SIDEBAR', withoutAnimation);
    },
    toggleDevice: function toggleDevice(_ref4, device) {
      var commit = _ref4.commit;
      commit('TOGGLE_DEVICE', device);
    },
    setLanguage: function setLanguage(_ref5, language) {
      var commit = _ref5.commit;
      commit('SET_LANGUAGE', language);
    },
    setSize: function setSize(_ref6, size) {
      var commit = _ref6.commit;
      commit('SET_SIZE', size);
    }
  }
};
var _default = exports.default = app;