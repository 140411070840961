"use strict";

var _interopRequireDefault = require("/var/jenkins_home/workspace/sihong_test_front_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
require("core-js/modules/es6.function.name");
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
require("core-js/modules/es6.regexp.split");
var _objectSpread2 = _interopRequireDefault(require("/var/jenkins_home/workspace/sihong_test_front_mall/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _trends = require("@/api/cont/trends");
var _multiLanguageDisplay = require("@/utils/multiLanguageDisplay");
var _storage = require("@/api/storage");
var _MultiLanguageInp = _interopRequireDefault(require("@/components/MultiLanguageInp"));
var _auth = require("@/utils/auth");
var _sortablejs = _interopRequireDefault(require("sortablejs"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    MultiLanguageInp: _MultiLanguageInp.default
  },
  data: function data() {
    return {
      dialogVisible: false,
      checkFileType: _multiLanguageDisplay.checkFileType,
      multiLanguageDisplay: _multiLanguageDisplay.multiLanguageDisplay,
      btn: [{
        value: '48',
        tit: '时装秀title'
      }],
      activeName: '',
      uploadPath: _storage.uploadPath,
      total: 3,
      type: 0,
      pageSize: 10,
      currentPage: 1,
      tit: '新增',
      id: '',
      staus: 1,
      // 1新增 2编辑
      form: {
        title: '',
        text: '',
        enable: false
      },
      oldIndex: null,
      newIndex: null,
      dataSource: []
    };
  },
  computed: {
    headers: function headers() {
      return {
        'Mu-Token': (0, _auth.getToken)()
      };
    },
    tip: function tip() {
      if (this.type == 1 || this.type == 8) return '视频大小不超过10M,图片不超过800kb';
      return '视频大小不超过5M,图片不超过500kb';
    }
  },
  created: function created() {
    this.type = this.btn[0].value;
    this.activeName = this.btn[0].value;
  },
  mounted: function mounted() {
    this.type = this.btn[0].value;
    this.activeName = this.btn[0].value;
    this.getList();
    this.init();
  },
  methods: {
    init: function init() {
      // 1. 获取拖拽元素的父元素
      // 因为使用的element的table 所以可直接获取tbody
      var el = document.querySelector(".el-table__body-wrapper tbody");
      var _this = this;
      // 2. 创建并配置相关参数
      var sortable = new _sortablejs.default(el, {
        // 此处进行配置 及 回调函数的使用
        handle: '.handle_move',
        // css选择器的字符串 若设置该值，则表示只有按住拖动手柄才能使列表单元进行拖动
        draggable: '.el-table__row',
        // 允许拖拽的项目类名
        // 因为拖拽过程中不尽兴位置调整，所以就要记录拖拽行初始位置以及目标位置
        // 拖拽中 回调函数
        onMove: function onMove(customEvent) {
          // 禁止在拖拽过程中交换位置
          // 可将初始位置及目标位置进行记录
          _this.oldIndex = customEvent.dragged.rowIndex; //  初始位置
          _this.newIndex = customEvent.related.rowIndex; // 目标位置
          console.log(_this.oldIndex, _this.newIndex, 's');
          return false;
        },
        // 拖拽结束，调整位置
        onEnd: function onEnd() {
          // 在拖拽结束后，获取初始及目标位置
          var newIndex = _this.newIndex,
            oldIndex = _this.oldIndex;
          var currRow = _this.dataSource.splice(oldIndex, 1)[0];
          _this.dataSource.splice(newIndex, 0, currRow);
          // 拖拽完成，初始位置及目标位置重置
          _this.newIndex = null;
          _this.oldIndex = null;
          var dataSource = _this.dataSource.map(function (item) {
            return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, item), {}, {
              type: _this.type
            });
          });
          (0, _trends.cmsUpdateOrSaveBatch)(dataSource).then(function (res) {});
        }
      });
    },
    beforeUpload: function beforeUpload(file) {
      var size = file.size / 1024;
      var vides = ['mp4', 'avi', 'mov', 'rmvb', 'wmv'];
      var isVideo = vides.some(function (item) {
        return item.indexOf(file.type.split('/')[1]) >= 0;
      });
      var type = this.type;
      if (['1', '8'].includes(type)) return isVideo && size <= 10240 || !isVideo && size <= 800;
      return isVideo && size <= 5120 || !isVideo && size <= 500;
    },
    onEnable: function onEnable(val, value) {
      var data = {
        text: value.text,
        title: value.title,
        id: value.id,
        enable: val,
        type: this.type
      };
      (0, _trends.updateApi)(data).then(function (res) {
        // this.getList()
      });
    },
    handleClick: function handleClick(tab) {
      console.log(tab.name, 888);
      this.type = tab.name * 1;
      this.getList();
    },
    // 删除
    deleteItem: function deleteItem(item) {
      var _this2 = this;
      (0, _trends.deleteApi)({
        id: item.id
      }).then(function (res) {
        _this2.getList();
      });
    },
    getList: function getList() {
      var _this3 = this;
      var params = {
        type: this.type,
        page: this.currentPage,
        size: this.pageSize
      };
      (0, _trends.pageApi)(params).then(function (res) {
        console.log(res);
        _this3.dataSource = res.data.data.list;
        _this3.total = res.data.data.total;
      });
    },
    handleClose: function handleClose() {
      this.dialogVisible = false;
    },
    handleSizeChange: function handleSizeChange(val) {
      this.pageSize = val;
      this.getList();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      console.log(val);
      this.currentPage = val;
      this.getList();
    },
    add: function add(type) {
      this.dialogVisible = true;
      // this.form = {}
      this.form = {
        title: '',
        text: '',
        enable: false
      };
      this.staus = 1;
      this.type = type;
    },
    edit: function edit(item) {
      this.dialogVisible = true;
      this.tit = '编辑';
      this.staus = 2;
      this.id = item.id;
      this.form.enable = item.enable;
      this.form.title = item.title;
      this.form.text = item.text;
      this.type = item.type;
    },
    sure: function sure() {
      var _this4 = this;
      // 使用 Object.values() 方法将 form 对象的所有值转换为数组
      var values = Object.keys(this.form);
      // 使用 Array.every() 方法判断数组中的每个值是否为空
      var isFormEmpty = values.filter(function (key) {
        return key !== 'enable';
      }).every(function (key) {
        return _this4.form[key] === '';
      });
      if (isFormEmpty) {
        this.$message.warning('请输入信息');
        return;
      }
      var data = {
        text: this.form.text,
        title: this.form.title,
        type: this.type,
        id: this.id,
        zipcode: this.form.zipCode,
        enable: this.form.enable
      };
      if (this.staus == 1) {
        (0, _trends.trendsCreateApi)(data).then(function (res) {
          if (res.data.errno == 0) {
            _this4.$message.success('添加成功');
            _this4.dialogVisible = false;
            _this4.getList();
          }
        });
      }
      if (this.staus == 2) {
        (0, _trends.updateApi)(data).then(function (res) {
          console.log(res);
          _this4.$message.success('编辑成功');
          _this4.dialogVisible = false;
          _this4.getList();
        });
      }
    }
  }
};