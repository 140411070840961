"use strict";

var _interopRequireDefault = require("/var/jenkins_home/workspace/sihong_test_front_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "AppMain", {
  enumerable: true,
  get: function get() {
    return _AppMain.default;
  }
});
Object.defineProperty(exports, "Navbar", {
  enumerable: true,
  get: function get() {
    return _Navbar.default;
  }
});
Object.defineProperty(exports, "Sidebar", {
  enumerable: true,
  get: function get() {
    return _index.default;
  }
});
Object.defineProperty(exports, "TagsView", {
  enumerable: true,
  get: function get() {
    return _TagsView.default;
  }
});
var _Navbar = _interopRequireDefault(require("./Navbar"));
var _index = _interopRequireDefault(require("./Sidebar/index.vue"));
var _TagsView = _interopRequireDefault(require("./TagsView"));
var _AppMain = _interopRequireDefault(require("./AppMain"));