"use strict";

var _interopRequireDefault = require("/var/jenkins_home/workspace/sihong_test_front_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _axios = _interopRequireDefault(require("axios"));
var _elementUi = require("element-ui");
var _store = _interopRequireDefault(require("@/store"));
var _auth = require("@/utils/auth");
var _jsCookie = _interopRequireDefault(require("js-cookie"));
// create an axios instance //'process.env.VUE_APP_BASE_API, // api 的 base_url
var service = _axios.default.create({
  baseURL: process.env.VUE_APP_BASE_API,
  // api 的 base_url
  timeout: 15000 // request timeout
});

// request interceptor
service.interceptors.request.use(function (config) {
  // Do something before request is sent
  if (_store.default.getters.token) {
    // 让每个请求携带token-- ['Authorization']为自定义key 请根据实际情况自行修改
    config.headers['Mu-Token'] = (0, _auth.getToken)();
  }
  return config;
}, function (error) {
  // Do something with request error
  console.log(error); // for debug
  Promise.reject(error);
});

// response interceptor
service.interceptors.response.use(function (response) {
  var res = response.data;
  if (res.errno === '100') {
    _store.default.dispatch('FedLogOut').then(function () {
      // 用户登录界面提示
      _jsCookie.default.set('point', 501);
      location.reload();
    });
    return Promise.reject('error');
  }
  return res;
}, function (error) {
  console.log('err' + error);
  (0, _elementUi.Message)({
    message: '登录连接超时（后台不能连接，请联系系统管理员）',
    type: 'error',
    duration: 15 * 1000
  });
  return Promise.reject(error);
});
var _default = exports.default = service;