"use strict";

var _interopRequireDefault = require("/var/jenkins_home/workspace/sihong_test_front_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _screenfull = _interopRequireDefault(require("screenfull"));
//
//
//
//
//
//
var _default = exports.default = {
  name: 'Screenfull',
  props: {
    width: {
      type: Number,
      default: 22
    },
    height: {
      type: Number,
      default: 22
    },
    fill: {
      type: String,
      default: '#48576a'
    }
  },
  data: function data() {
    return {
      isFullscreen: false
    };
  },
  methods: {
    click: function click() {
      if (!_screenfull.default.enabled) {
        this.$message({
          message: '浏览器不支持全屏',
          type: 'warning'
        });
        return false;
      }
      _screenfull.default.toggle();
    }
  }
};