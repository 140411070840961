"use strict";

var _interopRequireDefault = require("/var/jenkins_home/workspace/sihong_test_front_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.array.find-index");
var _EmailEnv = require("@/api/EmailEnv");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _EmailTemplate = require("@/api/EmailTemplate");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'ConfigLevel',
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      list: [],
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 20,
        name: undefined,
        sceneCode: undefined,
        sort: 'id',
        order: 'desc'
      },
      sceneList: [],
      multipleSelection: [],
      activeName: 'zh'
    };
  },
  created: function created() {
    this.getSceneList();
    this.getList();
  },
  methods: {
    codeText: function codeText(code) {
      if (!code) return '';
      var index = this.sceneList.findIndex(function (item) {
        return item.code == code;
      });
      if (index >= 0) return this.sceneList[index]['name'];
      return '';
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    getSceneList: function getSceneList() {
      var _this = this;
      (0, _EmailEnv.getListNo)().then(function (res) {
        var dataRes = res.data;
        console.log(res, 'res');
        if (dataRes.errno === 0) {
          _this.sceneList = dataRes.data;
        }
      });
    },
    // 获取列表
    getList: function getList() {
      var _this2 = this;
      this.listLoading = true;
      var _this$listQuery = this.listQuery,
        page = _this$listQuery.page,
        limit = _this$listQuery.limit,
        name = _this$listQuery.name,
        sceneCode = _this$listQuery.sceneCode;
      (0, _EmailTemplate.getEmailTemplateList)({
        page: page,
        size: limit,
        name: name,
        sceneCode: sceneCode
      }).then(function (response) {
        response.data.data.list.forEach(function (ele) {
          var target = _this2.sceneList.filter(function (s) {
            return s.code === ele.sceneCode;
          });
          ele.sceneName = target.length > 0 ? target[0].name : '';
        });
        _this2.list = response.data.data.list;
        _this2.total = response.data.data.total;
        _this2.listLoading = false;
      }).catch(function () {
        _this2.list = [];
        _this2.listLoading = false;
      });
    },
    // 新增
    handleCreate: function handleCreate() {
      this.$router.push({
        path: '/config/EmailTemplateDetail'
      });
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 修改
    handleUpdate: function handleUpdate(row) {
      this.$router.push({
        path: '/config/EmailTemplateDetail',
        query: {
          id: row.id
        }
      });
    },
    onEnable: function onEnable(val, value) {
      var _this3 = this;
      (0, _EmailTemplate.saveEmailTemplate)({
        id: value.id,
        isEnable: val
      }).then(function () {
        _this3.getList();
        _this3.$notify.success({
          title: '成功',
          message: '修改成功'
        });
      }).catch(function (response) {
        _this3.getList();
        _this3.$message.error(response.data.errmsg);
      });
    },
    handleSetConfig: function handleSetConfig() {
      this.$router.push({
        path: '/config/EmailEnv'
      });
    },
    // 删除
    handleDeleteMore: function handleDeleteMore() {
      var _this4 = this;
      if (this.multipleSelection.length === 0) return this.$message.error('请选择你要删除的模板');
      var ids = this.multipleSelection.map(function (ele) {
        return ele.id;
      }).join(',');
      this.$confirm('是否要进行删除操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _EmailTemplate.deleteEmailTemplate)({
          ids: ids
        }).then(function () {
          _this4.getList();
          _this4.$notify.success({
            title: '成功',
            message: '删除成功'
          });
        }).catch(function (response) {
          _this4.$message.error(response.data.errmsg);
        });
      }).catch(function () {});
    },
    handleDelete: function handleDelete(row) {
      var _this5 = this;
      this.$confirm('是否要进行删除操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _EmailTemplate.deleteEmailTemplate)({
          ids: row.id + ''
        }).then(function () {
          _this5.getList();
          _this5.$notify.success({
            title: '成功',
            message: '删除成功'
          });
        }).catch(function (response) {
          _this5.$message.error(response.data.errmsg);
        });
      }).catch(function () {});
    }
  }
};