"use strict";

var _interopRequireDefault = require("/var/jenkins_home/workspace/sihong_test_front_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addUserSub = addUserSub;
exports.deleteUserSub = deleteUserSub;
exports.distributorInfo = distributorInfo;
exports.distributorOrderList = distributorOrderList;
exports.distributorSettle = distributorSettle;
exports.fetchList = fetchList;
exports.listAddress = listAddress;
exports.listCard = listCard;
exports.listCollect = listCollect;
exports.listFeedback = listFeedback;
exports.listFootprint = listFootprint;
exports.listHistory = listHistory;
exports.listSub = listSub;
exports.listUpper = listUpper;
exports.updateUserAdvance = updateUserAdvance;
exports.updateUserDistributor = updateUserDistributor;
exports.updateUserLevel = updateUserLevel;
var _request = _interopRequireDefault(require("@/utils/request"));
function fetchList(query) {
  return (0, _request.default)({
    url: '/user/list',
    method: 'get',
    params: query
  });
}
function listAddress(query) {
  return (0, _request.default)({
    url: '/address/list',
    method: 'get',
    params: query
  });
}
function listCollect(query) {
  return (0, _request.default)({
    url: '/collect/list',
    method: 'get',
    params: query
  });
}
function listFeedback(query) {
  return (0, _request.default)({
    url: '/feedback/list',
    method: 'get',
    params: query
  });
}
function listFootprint(query) {
  return (0, _request.default)({
    url: '/footprint/list',
    method: 'get',
    params: query
  });
}
function listHistory(query) {
  return (0, _request.default)({
    url: '/history/list',
    method: 'get',
    params: query
  });
}
function listCard(data) {
  return (0, _request.default)({
    url: '/card/list',
    method: 'POST',
    data: data
  });
}
function listUpper(data) {
  return (0, _request.default)({
    url: '/card/usershard/upper/list',
    method: 'POST',
    data: data
  });
}
function listSub(data) {
  return (0, _request.default)({
    url: '/card/usershard/sub/list',
    method: 'POST',
    data: data
  });
}
function updateUserLevel(data) {
  return (0, _request.default)({
    url: '/user/level/update',
    method: 'POST',
    data: data
  });
}
function updateUserAdvance(data) {
  return (0, _request.default)({
    url: '/user/advanceratio/update',
    method: 'POST',
    data: data
  });
}
function updateUserDistributor(data) {
  return (0, _request.default)({
    url: '/user/distributor/status/update',
    method: 'POST',
    data: data
  });
}
function addUserSub(data) {
  return (0, _request.default)({
    url: '/card/usershard/sub/add',
    method: 'POST',
    data: data
  });
}
function deleteUserSub(data) {
  return (0, _request.default)({
    url: '/card/usershard/sub/delete',
    method: 'POST',
    data: data
  });
}
function distributorInfo(data) {
  return (0, _request.default)({
    url: '/distributor/info',
    method: 'POST',
    data: data
  });
}
function distributorOrderList(query) {
  return (0, _request.default)({
    url: '/distributor/order/list',
    method: 'get',
    params: query
  });
}
function distributorSettle(data) {
  return (0, _request.default)({
    url: '/distributor/settle',
    method: 'POST',
    data: data
  });
}