var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "iconShot" }, [
    _c("div", { staticClass: "icon-dialog" }, [
      _c("div", { staticClass: "clip" }, [
        _c(
          "div",
          { ref: "img", staticClass: "img" },
          [
            _c("vue-cropper", {
              ref: "cropper",
              attrs: {
                img: _vm.imgSrc,
                canScale: _vm.options.canScale,
                autoCrop: _vm.options.autoCrop,
                canMove: _vm.options.canMove,
                centerBox: _vm.options.centerBox,
                fixedNumber: _vm.options.fixedNumber,
                fixedBox: true,
                fixed: true,
                enlarge: 2,
                outputType: "png",
              },
              on: { realTime: _vm.realTime },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "previews" }, [
          _c("img", {
            staticStyle: {
              width: "470px",
              height: "170px",
              "object-fit": "cover",
            },
            attrs: { src: _vm.cutImgSrc },
          }),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }