"use strict";

var _interopRequireDefault = require("/var/jenkins_home/workspace/sihong_test_front_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _Tinymce = _interopRequireDefault(require("@/components/Tinymce"));
var _auth = require("@/utils/auth");
var _multiLanguageDisplay = require("@/utils/multiLanguageDisplay");
var _EmailTemplate = require("@/api/EmailTemplate");
var _EmailEnv = require("@/api/EmailEnv");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'GoodsEdit',
  components: {
    Tinymce: _Tinymce.default
  },
  data: function data() {
    return {
      multiLanguageDisplay: _multiLanguageDisplay.multiLanguageDisplay,
      // 商品基本信息
      EmailTemplate: {
        isEnable: true
      },
      activeName: 'zh',
      loading: false,
      rules: {
        name: [{
          required: true,
          message: '模板名称不能为空',
          trigger: 'blur'
        }],
        sceneCode: [{
          required: true,
          message: '应用场景不能为空',
          trigger: 'blur'
        }],
        content: [{
          required: true,
          message: '模板内容不能为空',
          trigger: 'blur'
        }],
        isEnable: [{
          required: true
        }]
      },
      sceneList: [],
      codeList: [],
      changeIdx: 0
    };
  },
  computed: {
    // 头部信息
    headers: function headers() {
      return {
        Authorization: (0, _auth.getToken)()
      };
    }
  },
  created: function created() {
    this.getSceneList();
    this.init();
  },
  methods: {
    addLabelAttribute: function addLabelAttribute(arr) {
      var _this = this;
      arr.forEach(function (item) {
        item.label = _this.multiLanguageDisplay(item.label);
        if (item.children && item.children.length) {
          _this.addLabelAttribute(item.children);
        }
      });
    },
    getSceneList: function getSceneList() {
      var _this2 = this;
      (0, _EmailEnv.getListNo)().then(function (res) {
        var dataRes = res.data;
        console.log(res, 'res');
        if (dataRes.errno === 0) {
          _this2.sceneList = dataRes.data;
        }
      });
    },
    selectChange: function selectChange(value) {
      var _this3 = this;
      if ((this.EmailTemplate.contentZh || this.EmailTemplate.contentEn) && this.EmailTemplate.sceneCode) {
        return this.$message.error('模板内容已填写,请不要随意切换应用场景');
      }
      this.EmailTemplate.sceneCode = value;
      this.getParamBySceneData(value);
      // 切换完应用场景，激活编辑器
      this.$nextTick(function () {
        var editor = window.tinymce.get(_this3.activeName + '-tinymce');
        editor.focus();
      });
    },
    getParamBySceneData: function getParamBySceneData(sceneCode) {
      var _this4 = this;
      (0, _EmailTemplate.getParamByScene)({
        sceneCode: sceneCode
      }).then(function (res) {
        var dataRes = res.data;
        if (dataRes.errno === 0) {
          _this4.codeList = dataRes.data;
        }
      });
    },
    // 数据初始化
    init: function init() {
      var _this5 = this;
      if (this.$route.query.id) {
        console.log('编辑');
        var Id = this.$route.query.id;
        (0, _EmailTemplate.getById)({
          id: Id
        }).then(function (res) {
          var dataRes = res.data;
          if (dataRes.errno === 0) {
            var contentList = dataRes.data.contentList;
            dataRes.data.contentZh = contentList.filter(function (ele) {
              return ele.lang === 'zh';
            }).length > 0 ? contentList.filter(function (ele) {
              return ele.lang === 'zh';
            })[0].content : '';
            dataRes.data.contentEn = contentList.filter(function (ele) {
              return ele.lang === 'en';
            }).length > 0 ? contentList.filter(function (ele) {
              return ele.lang === 'en';
            })[0].content : '';
            _this5.EmailTemplate = dataRes.data;
            _this5.getParamBySceneData(_this5.EmailTemplate.sceneCode);
            _this5.$nextTick(function () {
              if (_this5.changeIdx === 0) {
                var editor = window.tinymce.get(_this5.activeName + '-tinymce');
                editor.focus();
                editor.selection.select(editor.getBody(), true);
                editor.selection.collapse(false);
                _this5.changeIdx = 1;
              }
            });
          }
        });
      } else {
        console.log('新增');
        this.$nextTick(function () {
          if (_this5.changeIdx === 0) {
            var editor = window.tinymce.get(_this5.activeName + '-tinymce');
            editor.focus();
            _this5.changeIdx = 1;
          }
        });
      }
    },
    handleClick: function handleClick(data) {
      // this.changeIdx = 0
      var editor = window.tinymce.get(data.name + '-tinymce');
      editor.focus();
      editor.selection.select(editor.getBody(), true);
      editor.selection.collapse(false);
    },
    tinymceChange: function tinymceChange(data) {
      console.log(data);
      // const editor = window.tinymce.get(this.activeName + '-tinymce')
      // editor.focus()
      // editor.selection.select(editor.getBody(), true)
      // editor.selection.collapse(false)
    },
    tinymceNodeChange: function tinymceNodeChange(data) {
      console.log(data);
      console.log(this.changeIdx);
      if (this.changeIdx === 0) {
        var editor = window.tinymce.get(this.activeName + '-tinymce');
        editor.focus();
        editor.selection.select(editor.getBody(), true);
        editor.selection.collapse(false);
        this.changeIdx = 1;
      }
    },
    // 取消上架
    handleCancel: function handleCancel() {
      this.$router.push({
        path: '/config/EmailTemplateConfig'
      });
    },
    // 确认更新
    handleEdit: function handleEdit() {
      var _this6 = this;
      this.loading = true;
      // 数据验证
      var isVaild = this.dataIsVaild();
      if (!isVaild) {
        this.loading = false;
        return;
      }
      var _this$EmailTemplate = this.EmailTemplate,
        name = _this$EmailTemplate.name,
        sceneCode = _this$EmailTemplate.sceneCode,
        contentZh = _this$EmailTemplate.contentZh,
        contentEn = _this$EmailTemplate.contentEn,
        remark = _this$EmailTemplate.remark,
        isEnable = _this$EmailTemplate.isEnable;
      var contentList = this.EmailTemplate.contentList;
      if (contentList && contentList.length > 0) {
        contentList.forEach(function (ele) {
          if (ele.lang === 'zh') {
            ele.content = contentZh;
          } else if (ele.lang === 'en') {
            ele.content = contentEn;
          }
        });
      } else {
        contentList = [{
          lang: 'zh',
          content: contentZh
        }, {
          lang: 'en',
          content: contentEn
        }];
      }
      var dataObj = {
        id: this.$route.query.id,
        name: name,
        contentList: contentList,
        sceneCode: sceneCode,
        remark: remark,
        isEnable: isEnable
      };
      (0, _EmailTemplate.saveEmailTemplate)(dataObj).then(function () {
        _this6.loading = false;
        _this6.$notify.success({
          title: '成功',
          message: _this6.$route.query.id ? '编辑成功' : '新建成功'
        });
        _this6.$router.push({
          path: '/config/EmailTemplateConfig'
        });
      });
    },
    // 数据验证
    dataIsVaild: function dataIsVaild() {
      if (this.EmailTemplate.name === undefined || this.EmailTemplate.name === '') {
        this.$message.error('模板名称不能为空');
        return false;
      }
      if (this.EmailTemplate.sceneCode === undefined || this.EmailTemplate.sceneCode === '') {
        this.$message.error('应用场景不能为空');
        return false;
      }
      if ((this.EmailTemplate.contentZh === undefined || this.EmailTemplate.contentZh === '') && (this.EmailTemplate.contentEn === undefined || this.EmailTemplate.contentEn === '')) {
        this.$message.error('模板内容不能为空');
        return false;
      }
      return true;
    }
  }
};