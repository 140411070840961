"use strict";

var _interopRequireDefault = require("/var/jenkins_home/workspace/sihong_test_front_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/var/jenkins_home/workspace/sihong_test_front_mall/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _objectWithoutProperties2 = _interopRequireDefault(require("/var/jenkins_home/workspace/sihong_test_front_mall/node_modules/@babel/runtime-corejs2/helpers/objectWithoutProperties.js"));
require("core-js/modules/web.dom.iterable");
var _moment = _interopRequireDefault(require("moment"));
var _warehouse = require("@/api/warehouse");
var _multiLanguageDisplay = require("@/utils/multiLanguageDisplay");
var _excluded = ["billNo"]; //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'MallInStorage',
  data: function data() {
    return {
      col: 6,
      dialogVisible: false,
      multiLanguageDisplay: _multiLanguageDisplay.multiLanguageDisplay,
      rules: {},
      list: [],
      form: {
        bizDate: (0, _moment.default)().format('YYYY-MM-DD'),
        remark: '',
        inoutId: '',
        billNo: ''
      }
    };
  },
  mounted: function mounted() {},
  computed: {
    total: function total() {
      if (this.list.length == 0) return 0;
      return this.list.reduce(function (total, item) {
        return total + item.num;
      }, 0);
    }
  },
  methods: {
    init: function init(info, inoutId) {
      this.form.remark = info.remark;
      this.form.inoutId = inoutId;
      this.form.billNo = info.billNo;
      this.list = info.detailList;
      this.list.forEach(function (item) {
        item.returnItem = item.num;
      });
    },
    open: function open(info, inoutId) {
      this.dialogVisible = true;
      this.init(info, inoutId);
    },
    handleClose: function handleClose() {
      this.dialogVisible = false;
    },
    sure: function sure() {
      var _this = this;
      console.log(this.list, 11);
      var newProductList = this.list.map(function (product) {
        return {
          inoutDetailId: product.inoutDetailId,
          num: product.num,
          productId: product.productId,
          warehouseId: product.warehouseId,
          location: product.warehouseName
        };
      });
      var _this$form = this.form,
        billNo = _this$form.billNo,
        data = (0, _objectWithoutProperties2.default)(_this$form, _excluded);
      var params = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, data), {}, {
        productLocationList: newProductList
      });
      (0, _warehouse.retApi)(params).then(function (data) {
        var res = data.data;
        if (res.errno == 0) {
          _this.dialogVisible = false;
          _this.$message.success('操作成功');
          _this.$emit("updateList", 11);
        }
      });
    }
  }
};