"use strict";

var _interopRequireDefault = require("/var/jenkins_home/workspace/sihong_test_front_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("/var/jenkins_home/workspace/sihong_test_front_mall/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));
var _createForOfIteratorHelper2 = _interopRequireDefault(require("/var/jenkins_home/workspace/sihong_test_front_mall/node_modules/@babel/runtime-corejs2/helpers/createForOfIteratorHelper.js"));
var _storage = require("@/api/storage");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// Secondary package based on el-pagination
var _default = exports.default = {
  name: 'SysOs',
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 20,
        key: undefined,
        name: undefined,
        sort: 'add_time',
        order: 'desc'
      },
      createDialogVisible: false,
      dataForm: {
        id: undefined,
        name: ''
      },
      updateDialogVisible: false,
      rules: {
        name: [{
          required: true,
          message: '对象名称不能为空',
          trigger: 'blur'
        }]
      },
      downloadLoading: false
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;
      this.listLoading = true;
      (0, _storage.listStorage)(this.listQuery).then(function (response) {
        _this.list = response.data.data.list;
        _this.total = response.data.data.total;
        _this.listLoading = false;
      }).catch(function () {
        _this.list = [];
        _this.total = 0;
        _this.listLoading = false;
      });
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    resetForm: function resetForm() {
      this.dataForm = {
        id: undefined,
        name: ''
      };
    },
    handleCreate: function handleCreate() {
      this.createDialogVisible = true;
    },
    handleUpload: function handleUpload(item) {
      var _this2 = this;
      this.$refs.upload.clearFiles();
      var formData = new FormData();
      formData.append('file', item.file);
      (0, _storage.createStorage)(formData).then(function (response) {
        _this2.list.unshift(response.data.data);
        _this2.createDialogVisible = false;
        _this2.$notify.success({
          title: '成功',
          message: '上传成功'
        });
      }).catch(function () {
        _this2.$message.error('上传失败，请重新上传');
      });
    },
    handleUpdate: function handleUpdate(row) {
      var _this3 = this;
      this.dataForm = Object.assign({}, row);
      this.updateDialogVisible = true;
      this.$nextTick(function () {
        _this3.$refs['dataForm'].clearValidate();
      });
    },
    updateData: function updateData() {
      var _this4 = this;
      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          (0, _storage.updateStorage)(_this4.dataForm).then(function () {
            var _iterator = (0, _createForOfIteratorHelper2.default)(_this4.list),
              _step;
            try {
              for (_iterator.s(); !(_step = _iterator.n()).done;) {
                var v = _step.value;
                if (v.id === _this4.dataForm.id) {
                  var index = _this4.list.indexOf(v);
                  _this4.list.splice(index, 1, _this4.dataForm);
                  break;
                }
              }
            } catch (err) {
              _iterator.e(err);
            } finally {
              _iterator.f();
            }
            _this4.updateDialogVisible = false;
            _this4.$notify.success({
              title: '成功',
              message: '更新成功'
            });
          }).catch(function (response) {
            _this4.$notify.error({
              title: '失败',
              message: response.data.errmsg
            });
          });
        }
      });
    },
    handleDelete: function handleDelete(row) {
      var _this5 = this;
      this.$confirm('是否要进行删除操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _storage.deleteStorage)(row).then(function (response) {
          _this5.$notify.success({
            title: '成功',
            message: '删除成功'
          });
          var index = _this5.list.indexOf(row);
          _this5.list.splice(index, 1);
        }).catch(function (response) {
          _this5.$notify.error({
            title: '失败',
            message: response.data.errmsg
          });
        });
      });
    },
    handleDownload: function handleDownload() {
      var _this6 = this;
      this.downloadLoading = true;
      Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/vendor/Export2Excel'));
      }).then(function (excel) {
        var tHeader = ['ID', '对象KEY', '对象名称', '对象类型', '对象大小', '访问链接'];
        var filterVal = ['id', 'key', 'name', 'type', 'size', 'url'];
        excel.export_json_to_excel2(tHeader, _this6.list, filterVal, '对象存储信息');
        _this6.downloadLoading = false;
      });
    }
  }
};