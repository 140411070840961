"use strict";

var _interopRequireDefault = require("/var/jenkins_home/workspace/sihong_test_front_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/var/jenkins_home/workspace/sihong_test_front_mall/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _objectWithoutProperties2 = _interopRequireDefault(require("/var/jenkins_home/workspace/sihong_test_front_mall/node_modules/@babel/runtime-corejs2/helpers/objectWithoutProperties.js"));
var _moment = _interopRequireDefault(require("moment"));
var _warehouse = require("@/api/warehouse");
var _multiLanguageDisplay = require("@/utils/multiLanguageDisplay");
var _excluded = ["billNo"]; //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'MallInStorage',
  data: function data() {
    return {
      multiLanguageDisplay: _multiLanguageDisplay.multiLanguageDisplay,
      editDate: (0, _moment.default)().format('YYYY-MM-DD'),
      col: 6,
      dialogVisible: false,
      typeList: [],
      //入库类型
      wareHouseList: [],
      //入库仓库
      sourceList: [],
      //商品来源
      list: [{
        styleNo: '',
        goodsName: '',
        brandName: '',
        unit: '',
        categoryName: '',
        productList: []
      }],
      form: {
        date: (0, _moment.default)().format('YYYY-MM-DD'),
        typeId: '',
        warehouseId: '',
        inoutId: '',
        sourceId: '',
        remark: ''
      },
      rules: {
        date: [{
          required: true,
          message: ' ',
          trigger: 'blur'
        }],
        typeId: [{
          required: true,
          message: ' ',
          trigger: 'change'
        }],
        warehouseId: [{
          required: true,
          message: ' ',
          trigger: 'change'
        }]
        // sourceId:[{ required: true, message: ' ', trigger: 'change' }],
      }
    };
  },
  mounted: function mounted() {},
  methods: {
    init: function init(info, inoutId) {
      // this.form.date=info.bizDate
      this.form.typeId = info.bizTypeId;
      this.form.warehouseId = info.warehouseId;
      this.form.sourceId = info.sourceId;
      this.form.remark = info.remark;
      this.form.billNo = info.billNo;
      this.form.inoutId = inoutId;
      this.list = info.detailList;
    },
    getInType: function getInType() {
      var _this = this;
      var data = {
        type: 1
      };
      (0, _warehouse.operationApi)(data).then(function (data) {
        var res = data.data;
        if (res.errno == 0) {
          _this.typeList = res.data;
        }
      });
    },
    // 仓库列表
    getHouse: function getHouse() {
      var _this2 = this;
      var data = {};
      (0, _warehouse.warehouseListApi)(data).then(function (data) {
        var res = data.data;
        if (res.errno == 0) {
          _this2.wareHouseList = res.data;
        }
      });
    },
    // 商品来源
    getSource: function getSource() {
      var _this3 = this;
      var data = {};
      (0, _warehouse.sourceApi)(data).then(function (data) {
        var res = data.data;
        if (res.errno == 0) {
          _this3.sourceList = res.data;
        }
      });
    },
    open: function open(info, inoutId) {
      console.log('info', info);
      this.dialogVisible = true;
      this.init(info, inoutId);
      this.getInType(); //入库类型
      this.getHouse(); //入库仓库
      this.getSource(); //商品来源
    },
    handleClose: function handleClose() {
      this.dialogVisible = false;
    },
    sure: function sure() {
      var _this4 = this;
      this.$refs.ruleForm.validate(function (valid) {
        if (valid) {
          console.log('this.list', _this4.list);
          var newProductList = _this4.list.map(function (product) {
            return {
              location: product.locationName,
              num: product.num,
              productId: product.productId,
              inoutDetailId: product.inoutDetailId
            };
          });
          var _this4$form = _this4.form,
            billNo = _this4$form.billNo,
            data = (0, _objectWithoutProperties2.default)(_this4$form, _excluded);
          var params = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, data), {}, {
            productList: newProductList
          });
          (0, _warehouse.inboundEditexportApi)(params).then(function (data) {
            var res = data.data;
            if (res.errno == 0) {
              _this4.dialogVisible = false;
              _this4.$message.success('操作成功');
            }
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    onDelete: function onDelete(index) {
      this.list.splice(index, 1);
    }
  }
};