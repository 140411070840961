"use strict";

var _interopRequireDefault = require("/var/jenkins_home/workspace/sihong_test_front_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createCategory = createCategory;
exports.deleteCategory = deleteCategory;
exports.editTemplate = editTemplate;
exports.enableCategory = enableCategory;
exports.getSerialsByCategoryId = getSerialsByCategoryId;
exports.listApiCatL1 = listApiCatL1;
exports.listCatL1 = listCatL1;
exports.listCatL2 = listCatL2;
exports.listCategory = listCategory;
exports.listPropByCid = listPropByCid;
exports.readCategory = readCategory;
exports.updateCategory = updateCategory;
var _request = _interopRequireDefault(require("@/utils/request"));
var _requestTwo = _interopRequireDefault(require("@/utils/requestTwo"));
function listCategory(query) {
  return (0, _request.default)({
    url: '/category/list',
    method: 'get',
    params: query
  });
}
function listCatL1() {
  return (0, _request.default)({
    url: '/category/l1',
    method: 'get'
  });
}
function listCatL2() {
  return (0, _request.default)({
    url: '/category/l2',
    method: 'get'
  });
}
function createCategory(data) {
  return (0, _request.default)({
    url: '/category/create',
    method: 'post',
    data: data
  });
}
function readCategory(data) {
  return (0, _request.default)({
    url: '/category/read',
    method: 'get',
    data: data
  });
}
function updateCategory(data) {
  return (0, _request.default)({
    url: '/category/update',
    method: 'post',
    data: data
  });
}
function deleteCategory(data) {
  return (0, _request.default)({
    url: '/category/delete',
    method: 'post',
    data: data
  });
}
function editTemplate(data) {
  return (0, _request.default)({
    url: '/goods/prop/template/editTemplate',
    method: 'post',
    data: data
  });
}
function listPropByCid(data) {
  return (0, _request.default)({
    url: '/goods/prop/template/listPropByCid',
    method: 'get',
    params: data
  });
}
function getSerialsByCategoryId(query) {
  return (0, _requestTwo.default)({
    url: '/api/getSerialsByCategoryId',
    method: 'get',
    params: query
  });
}
function listApiCatL1() {
  return (0, _requestTwo.default)({
    url: '/api/category/l1',
    method: 'get'
  });
}
function enableCategory(data) {
  return (0, _request.default)({
    url: '/category/enable',
    method: 'post',
    data: data
  });
}