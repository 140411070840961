"use strict";

var _interopRequireDefault = require("/var/jenkins_home/workspace/sihong_test_front_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.search");
var _objectSpread2 = _interopRequireDefault(require("/var/jenkins_home/workspace/sihong_test_front_mall/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _multiLanguageDisplay = require("@/utils/multiLanguageDisplay");
var _aftersale = require("@/api/aftersale");
var _afterSalesDialog = _interopRequireDefault(require("@/components/AfterSales/afterSalesDialog.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    Pagination: _Pagination.default,
    AfterSalesDialog: _afterSalesDialog.default
  },
  data: function data() {
    return {
      multiLanguageDisplay: _multiLanguageDisplay.multiLanguageDisplay,
      search: {
        type: '',
        refundNo: '',
        orderNo: '',
        styleNo: '',
        refundStatus: ''
      },
      afterSalesVisible: false,
      orderId: '',
      list: [],
      listLoading: true,
      total: 0,
      pageNo: 1,
      pageSize: 10
    };
  },
  created: function created() {},
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    lookDetail: function lookDetail(row) {
      this.$router.push({
        path: "/returnRefund/detail/".concat(row.id)
      });
    },
    // 查找
    onSearch: function onSearch() {
      this.pageNo = 1;
      this.getList();
    },
    onClear: function onClear() {
      this.search = {
        goodsName: '',
        styleNo: '',
        color: '',
        size: '',
        category: ''
      };
      this.pageNo = 1;
      this.getList();
    },
    // 进入详情
    toDetail: function toDetail() {
      this.$router.push('/wareHouse/houseListDetail');
    },
    // 获取列表数据
    getList: function getList() {
      var _this = this;
      this.listLoading = true;
      var params = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.search), {}, {
        pageNo: this.pageNo,
        pageSize: this.pageSize
      });
      (0, _aftersale.refundList)(params).then(function (data) {
        var res = data.data;
        if (res.errno == 0) {
          _this.listLoading = false, _this.list = res.data.list;
          _this.total = res.data.total;
        }
      });
    },
    goRefund: function goRefund(row) {
      this.orderId = row.id;
      this.afterSalesVisible = true;
    },
    closeDialog: function closeDialog(val) {
      this.afterSalesVisible = false;
      if (val) {
        this.getList();
      }
    }
  }
};