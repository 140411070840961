var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "context" }, [
    _c("iframe", {
      staticClass: "contractContent",
      staticStyle: { height: "80vh" },
      attrs: { src: _vm.url, width: "100%", title: "模板设计" },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }