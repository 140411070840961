var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "detail",
      attrs: {
        top: "6vh",
        visible: _vm.dialogVisible,
        width: "80%",
        "before-close": _vm.handleClose,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _vm.type
        ? _c(
            "div",
            { staticClass: "cont" },
            [
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: _vm.col } }, [
                    _c("span", [_vm._v("出库日期")]),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.info.bizDate))]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: _vm.col } }, [
                    _c("span", [_vm._v("出库类型")]),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.info.bizTypeName))]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: _vm.col } }, [
                    _c("span", [_vm._v("出库方向")]),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.info.destName))]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: _vm.col } }, [
                    _c("span", [_vm._v("收货方")]),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.info.receiver))]),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: _vm.col } }, [
                    _c("span", [_vm._v("提交人")]),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.info.userName))]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: _vm.col } }, [
                    _c("span", [_vm._v("出库单号")]),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.info.billNo))]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: _vm.col } }, [
                    _c("span", [_vm._v("创建时间")]),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.info.createTime))]),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c("el-col", { staticClass: "remark", attrs: { span: 12 } }, [
                    _c("span", [_vm._v("备注")]),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.info.remark))]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: _vm.col } }, [
                    _c("span", [_vm._v("修改人")]),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.info.updateUserName))]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: _vm.col } }, [
                    _c("span", [_vm._v("修改日期")]),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.info.updateTime))]),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-divider"),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "listCont" },
                _vm._l(_vm.info.detailList, function (item, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "list" },
                    [
                      _c(
                        "el-row",
                        [
                          _c("el-col", { attrs: { span: _vm.col } }, [
                            _c("span", [_vm._v("商品款号")]),
                            _vm._v(" "),
                            _c("span", [_vm._v(_vm._s(item.styleNo))]),
                          ]),
                          _vm._v(" "),
                          _c("el-col", { attrs: { span: _vm.col } }, [
                            _c("span", [_vm._v("商品名称")]),
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.multiLanguageDisplay(item.goodsName))
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("el-col", { attrs: { span: _vm.col } }, [
                            _c("span", [_vm._v("商品品牌")]),
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.multiLanguageDisplay(item.brandName))
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("el-col", { attrs: { span: _vm.col } }, [
                            _c("span", [_vm._v("商品单位")]),
                            _vm._v(" "),
                            _c("span", [_vm._v(_vm._s(item.unit))]),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        [
                          _c("el-col", { attrs: { span: 12 } }, [
                            _c("span", [_vm._v("商品类别")]),
                            _vm._v(" "),
                            _c("span", [_vm._v(_vm._s(item.categoryName))]),
                          ]),
                          _vm._v(" "),
                          _c("el-col", { attrs: { span: _vm.col } }, [
                            _c("span", [_vm._v("原仓库")]),
                            _vm._v(" "),
                            _c("span", [_vm._v(_vm._s(item.warehouseName))]),
                          ]),
                          _vm._v(" "),
                          _c("el-col", { attrs: { span: _vm.col } }, [
                            _c("span", [_vm._v("库位号")]),
                            _vm._v(" "),
                            _c("span", [_vm._v(_vm._s(item.locationName))]),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        [
                          _c("el-col", { attrs: { span: _vm.col } }, [
                            _c("span", [_vm._v("商品颜色")]),
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.multiLanguageDisplay(item.color))
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("el-col", { attrs: { span: _vm.col } }, [
                            _c("span", [_vm._v("商品尺码")]),
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.multiLanguageDisplay(item.size))
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("el-col", { attrs: { span: _vm.col } }, [
                            _c("span", [_vm._v("商品数量")]),
                            _vm._v(" "),
                            _c("span", [_vm._v(_vm._s(item.num))]),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("el-divider"),
                    ],
                    1
                  )
                }),
                0
              ),
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "cont" },
            [
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: _vm.col } }, [
                    _c("span", [_vm._v("调拨日期")]),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.info.bizDate))]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: _vm.col } }, [
                    _c("span", [_vm._v("出库单号")]),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.info.billNo))]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: _vm.col } }, [
                    _c("span", [_vm._v("提交人")]),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.info.userName))]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: _vm.col } }, [
                    _c("span", [_vm._v("创建时间")]),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.info.createTime))]),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("span", [_vm._v("备注")]),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.info.remark))]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: _vm.col } }, [
                    _c("span", [_vm._v("修改人")]),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.info.updateUserName))]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: _vm.col } }, [
                    _c("span", [_vm._v("修改日期")]),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.info.updateTime))]),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-divider"),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "listCont" },
                _vm._l(_vm.info.detailList, function (item, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "list" },
                    [
                      _c(
                        "el-row",
                        [
                          _c("el-col", { attrs: { span: _vm.col } }, [
                            _c("span", [_vm._v("商品款号")]),
                            _vm._v(" "),
                            _c("span", [_vm._v(_vm._s(item.styleNo))]),
                          ]),
                          _vm._v(" "),
                          _c("el-col", { attrs: { span: _vm.col } }, [
                            _c("span", [_vm._v("商品名称")]),
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.multiLanguageDisplay(item.goodsName))
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("el-col", { attrs: { span: _vm.col } }, [
                            _c("span", [_vm._v("商品品牌")]),
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.multiLanguageDisplay(item.brandName))
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("el-col", { attrs: { span: _vm.col } }, [
                            _c("span", [_vm._v("商品单位")]),
                            _vm._v(" "),
                            _c("span", [_vm._v(_vm._s(item.unit))]),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        [
                          _c("el-col", { attrs: { span: 12 } }, [
                            _c("span", [_vm._v("商品类别")]),
                            _vm._v(" "),
                            _c("span", [_vm._v(_vm._s(item.categoryName))]),
                          ]),
                          _vm._v(" "),
                          _c("el-col", { attrs: { span: _vm.col } }, [
                            _c("span", [_vm._v("原仓库")]),
                            _vm._v(" "),
                            _c("span", [_vm._v(_vm._s(item.warehouseName))]),
                          ]),
                          _vm._v(" "),
                          _c("el-col", { attrs: { span: _vm.col } }, [
                            _c("span", [_vm._v("库位号")]),
                            _vm._v(" "),
                            _c("span", [_vm._v(_vm._s(item.locationName))]),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        [
                          _c("el-col", { attrs: { span: _vm.col } }, [
                            _c("span", [_vm._v("商品颜色")]),
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.multiLanguageDisplay(item.color))
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("el-col", { attrs: { span: _vm.col } }, [
                            _c("span", [_vm._v("商品尺码")]),
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.multiLanguageDisplay(item.size))
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("el-col", { attrs: { span: _vm.col } }, [
                            _c("span", [_vm._v("调拨数量")]),
                            _vm._v(" "),
                            _c("span", [_vm._v(_vm._s(item.num))]),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        [
                          _c("el-col", { attrs: { span: _vm.col } }, [
                            _c("span", [_vm._v("调拨仓库")]),
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(_vm._s(item.destWarehouseName)),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("el-col", { attrs: { span: _vm.col } }, [
                            _c("span", [_vm._v("库位号")]),
                            _vm._v(" "),
                            _c("span", [_vm._v(_vm._s(item.destLocationName))]),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("el-divider"),
                    ],
                    1
                  )
                }),
                0
              ),
            ],
            1
          ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v("确 认")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }