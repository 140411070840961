"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'TemplateDesign',
  data: function data() {
    return {
      url: process.env.VUE_APP_TEMPLATE_URL
    };
  },
  mounted: function mounted() {},
  methods: {}
};