"use strict";

var _interopRequireDefault = require("/var/jenkins_home/workspace/sihong_test_front_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.currencyListUrl = currencyListUrl;
exports.currencyRatesL = currencyRatesL;
exports.currencyRatesLInfo = currencyRatesLInfo;
exports.currencyRatesLUpdate = currencyRatesLUpdate;
exports.currencyRatesListUrl = currencyRatesListUrl;
exports.currencyRatesTodayUrl = currencyRatesTodayUrl;
exports.queryCurrencyRatesToday = queryCurrencyRatesToday;
exports.queryCurrencySettlementRates = queryCurrencySettlementRates;
exports.refreshCurrencyRatesUrl = refreshCurrencyRatesUrl;
exports.updateCurrencyRatesTodayUrl = updateCurrencyRatesTodayUrl;
var _request = _interopRequireDefault(require("@/utils/request"));
/*
 * @Author: 张月平 sam.zhang@neo-digitex.com
 * @Date: 2024-09-24 13:41:56
 * @LastEditors: 张月平 sam.zhang@neo-digitex.com
 * @LastEditTime: 2024-11-23 09:46:40
 * @FilePath: \frontend\src\api\currency.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

function currencyListUrl(query) {
  return (0, _request.default)({
    url: '/currency/currencyList',
    method: 'get',
    params: query
  });
}
function currencyRatesListUrl(query) {
  return (0, _request.default)({
    url: '/currency/currencyRatesList',
    method: 'get',
    params: query
  });
}
function currencyRatesTodayUrl(query) {
  return (0, _request.default)({
    url: '/currency/currencyRatesToday',
    method: 'get',
    params: query
  });
}
function refreshCurrencyRatesUrl(query) {
  return (0, _request.default)({
    url: '/currency/refreshCurrencyRates',
    method: 'get',
    params: query
  });
}
function updateCurrencyRatesTodayUrl(data) {
  return (0, _request.default)({
    url: '/currency/updateCurrencyRatesToday',
    method: 'post',
    data: data
  });
}

// 定价汇率

function currencyRatesL(params) {
  return (0, _request.default)({
    url: '/currencySettlementRates/currencyRatesList',
    method: 'get',
    params: params
  });
}
function currencyRatesLUpdate(data) {
  return (0, _request.default)({
    url: '/currencySettlementRates/updateById',
    method: 'post',
    data: data
  });
}
// 当天汇率
function queryCurrencyRatesToday(params) {
  return (0, _request.default)({
    url: '/currencySettlementRates/queryCurrencyRatesToday',
    method: 'get',
    params: params
  });
}
function currencyRatesLInfo(params) {
  return (0, _request.default)({
    url: '/currencySettlementRates/findById',
    method: 'get',
    params: params
  });
}

// 当天的汇率
function queryCurrencySettlementRates(params) {
  return (0, _request.default)({
    url: '/app/style/queryCurrencySettlementRates ',
    method: 'get',
    params: params
  });
}

//