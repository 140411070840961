import { render, staticRenderFns } from "./houseList.vue?vue&type=template&id=c64a1770&scoped=true"
import script from "./houseList.vue?vue&type=script&lang=js"
export * from "./houseList.vue?vue&type=script&lang=js"
import style0 from "./houseList.vue?vue&type=style&index=0&id=c64a1770&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c64a1770",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/jenkins_home/workspace/sihong_test_front_mall/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('c64a1770')) {
      api.createRecord('c64a1770', component.options)
    } else {
      api.reload('c64a1770', component.options)
    }
    module.hot.accept("./houseList.vue?vue&type=template&id=c64a1770&scoped=true", function () {
      api.rerender('c64a1770', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/wareHouse/houseList.vue"
export default component.exports