"use strict";

var _interopRequireDefault = require("/var/jenkins_home/workspace/sihong_test_front_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _goods = require("@/api/goods");
var _inventory = require("@/api/inventory");
var _BackToTop = _interopRequireDefault(require("@/components/BackToTop"));
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _multiLanguageDisplay = require("@/utils/multiLanguageDisplay");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// Secondary package based on el-pagination
var _default = exports.default = {
  name: 'GoodsList',
  components: {
    BackToTop: _BackToTop.default,
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      list: [],
      multiLanguageDisplay: _multiLanguageDisplay.multiLanguageDisplay,
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 20,
        goodsSn: undefined,
        name: undefined,
        sort: 'add_time',
        order: 'desc'
      },
      goodsDetail: '',
      detailDialogVisible: false,
      downloadLoading: false,
      // 商品库存
      commonStock: '',
      deteledProducts: [],
      products: [],
      stockDialogVisible: false,
      // 商品库存日志
      logListLoading: false,
      logList: [],
      logTotal: 0,
      logListQuery: {
        goodsId: 0,
        page: 1,
        limit: 20,
        sort: 'id',
        order: 'desc'
      }
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    // 获取商品列表
    getList: function getList() {
      var _this = this;
      this.listLoading = true;
      (0, _goods.listGoods)(this.listQuery).then(function (response) {
        _this.list = response.data.data.list;
        _this.total = response.data.data.total;
        _this.listLoading = false;
      }).catch(function () {
        _this.list = [];
        _this.total = 0;
        _this.listLoading = false;
        // reject(err)
      });
    },
    // 商品库存：显示商品库存弹窗
    showStock: function showStock(row) {
      var _this2 = this;
      // this.commonStock = ''
      // this.deteledProducts = []
      (0, _inventory.listStyleSkuInventory)({
        styleNo: row.goodsSn
      }).then(function (response) {
        if (response.code === '0') {
          _this2.products = response.data;
        } else {
          _this2.products = [];
        }
      });
      // this.logListQuery.goodsId = row.id
      // this.getLogList()
      this.stockDialogVisible = true;
    },
    // 商品库存：同步库存
    handleSynStock: function handleSynStock() {
      var self = this;
      this.products.forEach(function (item, index, array) {
        item.number = self.commonStock;
      });
    },
    // 商品库存：删除某库存
    handleDeleteStock: function handleDeleteStock(row) {
      var index = this.products.indexOf(row);
      this.products.splice(index, 1);
      this.deteledProducts.push(row);
    },
    // 商品库存：保存
    handleUpdateStock: function handleUpdateStock() {
      // if (this.products.length === 0) {
      //   this.$message.error('商品库存不能为空')
      //   return false
      // }
      // var productvar = []
      // this.products.forEach((item, index, array) => {
      //   productvar.push({
      //     id: item.id,
      //     storeCount: item.number,
      //     deleted: item.deleted
      //   })
      // })
      // this.deteledProducts.forEach((item, index, array) => {
      //   productvar.push({ id: item.id, storeCount: item.number, deleted: true })
      // })
      // editProducts({ productList: productvar })
      //   .then(response => {
      //     this.$notify.success({
      //       title: '成功',
      //       message: '操作成功'
      //     })
      //     this.stockDialogVisible = false
      //   })
      //   .catch(response => {
      //     this.$notify.error({
      //       title: '失败',
      //       message: response.data.errmsg
      //     })
      //   })

      this.stockDialogVisible = false;
    },
    // 获取库存操作日志
    getLogList: function getLogList() {
      var _this3 = this;
      this.logListLoading = true;
      (0, _goods.listStockLog)(this.logListQuery).then(function (response) {
        _this3.logList = response.data.data.list;
        _this3.logTotal = response.data.data.total;
        _this3.logListLoading = false;
      }).catch(function () {
        _this3.list = [];
        _this3.total = 0;
        _this3.logListLoading = false;
      });
    },
    // 查询
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    }
  }
};