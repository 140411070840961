"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.function.name");
var _config = require("@/api/config");
var _multiLanguageDisplay = require("@/utils/multiLanguageDisplay");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'ConfigAddress',
  data: function data() {
    return {
      multiLanguageDisplay: _multiLanguageDisplay.multiLanguageDisplay,
      list: [],
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 20,
        username: undefined,
        mobile: undefined,
        sort: 'id',
        order: 'desc'
      },
      // 新增、修改
      dialogFormVisible: false,
      dialogStatus: '',
      textMap: {
        update: '编辑',
        create: '创建'
      },
      dataForm: {
        id: undefined,
        name: '',
        consignee: '',
        mobile: '',
        address: ''
      }
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    // 获取列表
    getList: function getList() {
      var _this = this;
      this.listLoading = true;
      // this.list = [
      //   {
      //     category: '成衣',
      //     list: ['类目','系列','成分','服务','表现','认证','交易类型']
      //   },
      //   {
      //     category: '面料',
      //     list: ['类目','系列','成分','服务','表现','认证','交易类型']
      //   },
      //   {
      //     category: '纱线',
      //     list: ['类目','系列','成分','服务','表现','认证','交易类型']
      //   },{
      //     category: '饰品',
      //     list: ['类目','系列','成分','服务','表现','认证','交易类型']
      //   },{
      //     category: '礼品',
      //     list: ['类目','系列','成分','服务','表现','认证','交易类型']
      //   }
      // ]
      (0, _config.categoryFilterList)().then(function (response) {
        _this.list = response.data.data.list;
        _this.listLoading = false;
      }).catch(function () {
        _this.list = [];
        _this.listLoading = false;
      });
    },
    // 重置表单
    resetForm: function resetForm() {
      this.dataForm = {
        id: undefined,
        name: '',
        consignee: '',
        mobile: '',
        address: ''
      };
    },
    // 新增
    handleCreate: function handleCreate() {
      var _this2 = this;
      this.resetForm();
      this.dialogStatus = 'create';
      this.dialogFormVisible = true;
      this.$nextTick(function () {
        _this2.$refs['dataForm'].clearValidate();
      });
    },
    createData: function createData() {
      var _this3 = this;
      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          (0, _config.createLevel)({
            name: _this3.dataForm.name,
            level: _this3.dataForm.level,
            discount: _this3.dataForm.discount
          }).then(function (response) {
            _this3.getList();
            _this3.dialogFormVisible = false;
            _this3.$notify.success({
              title: '成功',
              message: '创建成功'
            });
          }).catch(function (response) {
            _this3.$notify.error({
              title: '失败',
              message: response.data.errmsg
            });
          });
        }
      });
    },
    // 修改
    handleUpdate: function handleUpdate(row) {
      var _this4 = this;
      row.discount = Number(row.discount);
      this.dataForm = Object.assign({}, row);
      this.dialogStatus = 'update';
      this.dialogFormVisible = true;
      this.$nextTick(function () {
        _this4.$refs['dataForm'].clearValidate();
      });
    },
    updateData: function updateData() {
      var _this5 = this;
      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          (0, _config.updateLevel)(_this5.dataForm).then(function () {
            _this5.getList();
            _this5.dialogFormVisible = false;
            _this5.$notify.success({
              title: '成功',
              message: '更新成功'
            });
          }).catch(function (response) {
            _this5.$notify.error({
              title: '失败',
              message: response.data.errmsg
            });
          });
        }
      });
    },
    // 删除
    handleDelete: function handleDelete(row) {
      var _this6 = this;
      this.$confirm('是否要进行删除操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _config.deleteLevel)({
          id: row.id
        }).then(function (response) {
          _this6.getList();
          _this6.$notify.success({
            title: '成功',
            message: '删除成功'
          });
        }).catch(function (response) {
          _this6.$notify.error({
            title: '失败',
            message: response.data.errmsg
          });
        });
      });
    }
  }
};