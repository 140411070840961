var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: { clearable: "", placeholder: "请输入售后编号" },
            model: {
              value: _vm.listQuery.afterSaleNo,
              callback: function ($$v) {
                _vm.$set(_vm.listQuery, "afterSaleNo", $$v)
              },
              expression: "listQuery.afterSaleNo",
            },
          }),
          _vm._v(" "),
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: { clearable: "", placeholder: "请输入订单编号" },
            model: {
              value: _vm.listQuery.orderNo,
              callback: function ($$v) {
                _vm.$set(_vm.listQuery, "orderNo", $$v)
              },
              expression: "listQuery.orderNo",
            },
          }),
          _vm._v(" "),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "permission",
                  rawName: "v-permission",
                  value: ["GET /admin/aftersale/list"],
                  expression: "['GET /admin/aftersale/list']",
                },
              ],
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: { click: _vm.handleFilter },
            },
            [_vm._v("查找")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-tabs",
        {
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.tab,
            callback: function ($$v) {
              _vm.tab = $$v
            },
            expression: "tab",
          },
        },
        [
          _c("el-tab-pane", { attrs: { label: "全部", name: "0" } }),
          _vm._v(" "),
          _c("el-tab-pane", { attrs: { label: "待处理", name: "2" } }),
          _vm._v(" "),
          _c("el-tab-pane", { attrs: { label: "已完成", name: "4" } }),
          _vm._v(" "),
          _c("el-tab-pane", { attrs: { label: "已拒绝", name: "3" } }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          attrs: {
            data: _vm.list,
            "element-loading-text": "正在查询中。。。",
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", label: "售后编号", prop: "aftersaleNo" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "订单编号", prop: "orderNo" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "售后类型", prop: "type" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("el-tag", [_vm._v("线下订单")])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "申请时间",
              prop: "uploadFileTime",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "用户昵称", prop: "customerName" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "退款金额", prop: "actAmount" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "商品金额", prop: "amount" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "退货回单", prop: "fileUrl" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("img", {
                      staticClass: "img",
                      attrs: { src: row.fileUrl, alt: "", srcset: "" },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "状态", prop: "status" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-tag",
                      { attrs: { type: _vm.typeTag[scope.row.status] } },
                      [_vm._v(_vm._s(_vm.typeDesc[scope.row.status]))]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "原因", prop: "reason" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "审核时间", prop: "reviewerTime" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "审核",
              "min-width": "120",
              "class-name": "small-padding",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", disabled: row.status != 2 },
                        on: {
                          click: function ($event) {
                            return _vm.check(row)
                          },
                        },
                      },
                      [_vm._v("审核")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total > 0",
          },
        ],
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getList,
        },
      }),
      _vm._v(" "),
      _c(
        "el-tooltip",
        { attrs: { placement: "top", content: "返回顶部" } },
        [_c("back-to-top", { attrs: { "visibility-height": 100 } })],
        1
      ),
      _vm._v(" "),
      _c(
        "YDialog",
        {
          attrs: {
            title: "线下订单退货退款审核",
            width: "700px",
            showCancel: true,
            visible: _vm.visible,
            loading: _vm.loading,
            "confirm-text": _vm.coText,
            "cancel-text": _vm.caText,
          },
          on: {
            "update:visible": function ($event) {
              _vm.visible = $event
            },
            confirm: _vm.confirm,
            cancel: _vm.cancel,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                "label-width": "200px",
                "label-position": "left",
              },
            },
            [
              !_vm.isCancel
                ? _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "订货退款金额(CNY)",
                                prop: "amount",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { size: "small", disabled: "" },
                                model: {
                                  value: _vm.form.amount,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "amount", $$v)
                                  },
                                  expression: "form.amount",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "实退金额(CNY)", prop: "amount" },
                            },
                            [
                              _c("el-input", {
                                attrs: { size: "small", disabled: "" },
                                model: {
                                  value: _vm.form.actAmount,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "actAmount", $$v)
                                  },
                                  expression: "form.actAmount",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "退款回单", prop: "amount" } },
                            [
                              _c(
                                "div",
                                { staticClass: "tui" },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      size: "small",
                                      disabled: "",
                                      value: "退款回单",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "a",
                                    {
                                      staticClass: "download",
                                      attrs: {
                                        href: _vm.form.fileUrl,
                                        download: "",
                                      },
                                    },
                                    [_vm._v("下载")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "textarea",
                              autosize: { minRows: 2, maxRows: 4 },
                              placeholder: "拒绝原因",
                            },
                            model: {
                              value: _vm.form.reason,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "reason", $$v)
                              },
                              expression: "form.reason",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
              _vm._v(" "),
              !_vm.isCancel
                ? _c("div", { staticClass: "tip" }, [
                    _vm._v(
                      "\n        请选择付款模式，并上传银行提供的支付业务付款回单，耐心等待审核\n      "
                    ),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }