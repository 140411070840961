"use strict";

var _interopRequireDefault = require("/var/jenkins_home/workspace/sihong_test_front_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _stat = require("@/api/stat");
var _line = _interopRequireDefault(require("v-charts/lib/line"));
//
//
//
//
//
//
var _default = exports.default = {
  name: 'StatOrder',
  components: {
    VeLine: _line.default
  },
  data: function data() {
    return {
      chartData: {},
      chartSettings: {},
      chartExtend: {}
    };
  },
  created: function created() {
    var _this = this;
    (0, _stat.statOrder)().then(function (response) {
      _this.chartData = response.data.data;
      _this.chartSettings = {
        labelMap: {
          'orders': '订单量',
          'customers': '下单用户',
          'amount': '订单总额',
          'pcr': '客单价'
        }
      };
      _this.chartExtend = {
        xAxis: {
          boundaryGap: true
        }
      };
    });
  }
};