var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticClass: "log",
          attrs: {
            "modal-append-to-body": false,
            visible: _vm.dialogVisible,
            width: "600px",
            "close-on-click-modal": false,
            "show-close": false,
            "close-on-press-escape": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "header-title",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c("p", { staticStyle: { "font-size": "18px" } }, [
                _vm._v("平台退款"),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("p", [_vm._v("是否确认进行退款操作")]),
          _vm._v(" "),
          _c("div", { staticClass: "amount" }, [
            _c("p", [
              _c("span", [_vm._v("买家收到退款")]),
              _c("span", [_vm._v("￥" + _vm._s(_vm.refund.refundAmount))]),
            ]),
            _vm._v(" "),
            _c("p", [
              _c("span", [_vm._v("退款手续费")]),
              _c("span", [_vm._v("￥" + _vm._s(_vm.refund.fee))]),
            ]),
            _vm._v(" "),
            _c("p", [
              _c("span", [_vm._v("退款总计金额")]),
              _c("span", { staticClass: "orange" }, [
                _vm._v("￥" + _vm._s(_vm.refund.totalAmount)),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "cont" },
            [
              _c(
                "el-button",
                { staticClass: "cancel-left", on: { click: _vm.cancel } },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "submit-right",
                  attrs: { type: "primary" },
                  on: { click: _vm.submitOrderRefund },
                },
                [_vm._v("确认")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }