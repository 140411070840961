"use strict";

var _interopRequireDefault = require("/var/jenkins_home/workspace/sihong_test_front_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.function.name");
var _mall = require("@/api/mall");
var _MultiLanguageInp = _interopRequireDefault(require("@/components/MultiLanguageInp"));
var _multiLanguageDisplay = require("@/utils/multiLanguageDisplay");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'ColorSpec',
  components: {
    MultiLanguageInp: _MultiLanguageInp.default
  },
  data: function data() {
    return {
      list: null,
      total: 0,
      listLoading: true,
      multiLanguageDisplay: _multiLanguageDisplay.multiLanguageDisplay,
      listQuery: {
        page: 1,
        limit: 20,
        sort: 'id',
        order: 'desc'
      },
      tagList: [],
      dataForm: {
        id: undefined,
        name: '颜色',
        sort: '2',
        inputList: ''
      },
      dialogFormVisible: false,
      dialogStatus: '',
      textMap: {
        update: '编辑',
        create: '创建'
      },
      rules: {
        name: [{
          required: true,
          message: '名称不能为空',
          trigger: 'blur'
        }],
        sort: [{
          required: true,
          message: '排序不能为空',
          trigger: 'blur'
        }]
      },
      downloadLoading: false
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;
      this.listLoading = true;
      (0, _mall.listSpec)(this.listQuery).then(function (response) {
        _this.list = response.data.data.list;
        _this.total = response.data.data.total;
        _this.list = _this.list.filter(function (item) {
          return item.name === '颜色';
        });
        _this.list.forEach(function (item) {
          item.inputList = JSON.parse(item.inputList);
        });
        _this.listLoading = false;
      }).catch(function () {
        _this.list = [];
        _this.total = 0;
        _this.listLoading = false;
      });
    },
    resetForm: function resetForm() {
      this.dataForm = {
        id: undefined,
        name: '颜色',
        sort: 2,
        inputList: []
      };
      this.tagList = [];
    },
    // 新增
    handleCreate: function handleCreate() {
      var _this2 = this;
      this.resetForm();
      this.dialogStatus = 'create';
      this.dialogFormVisible = true;
      this.$nextTick(function () {
        _this2.$refs['dataForm'].clearValidate();
      });
    },
    // 新增颜色规格
    addTag: function addTag() {
      this.tagList.push('');
    },
    deleteTag: function deleteTag(index) {
      this.tagList.splice(index, 1);
    },
    createData: function createData() {
      var _this3 = this;
      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          _this3.dataForm.inputList = JSON.stringify(_this3.tagList);
          if (_this3.dataForm.inputList === '') {
            _this3.$message.error('规则值不能为空');
            return false;
          }
          (0, _mall.createSpec)(_this3.dataForm).then(function (response) {
            // this.list.unshift(response.data.data)
            _this3.dialogFormVisible = false;
            _this3.$notify.success({
              title: '成功',
              message: '创建成功'
            });
            _this3.getList();
          }).catch(function (response) {
            _this3.$notify.error({
              title: '失败',
              message: response.data.errmsg
            });
          });
        }
      });
    },
    // 修改
    handleUpdate: function handleUpdate(row) {
      var _this4 = this;
      this.dataForm = Object.assign({}, row);
      this.tagList = this.dataForm.inputList;
      this.dialogStatus = 'update';
      this.dialogFormVisible = true;
      this.$nextTick(function () {
        _this4.$refs['dataForm'].clearValidate();
      });
    },
    updateData: function updateData() {
      var _this5 = this;
      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          _this5.dataForm.inputList = JSON.stringify(_this5.tagList);
          (0, _mall.updateSpec)(_this5.dataForm).then(function () {
            _this5.getList();
            _this5.dialogFormVisible = false;
            _this5.$notify.success({
              title: '成功',
              message: '更新成功'
            });
          }).catch(function (response) {
            _this5.$notify.error({
              title: '失败',
              message: response.data.errmsg
            });
          });
        }
      });
    },
    // 删除
    handleDelete: function handleDelete(row) {
      var _this6 = this;
      this.$confirm('是否要进行删除操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _mall.deleteSpec)({
          id: row.id
        }).then(function (response) {
          _this6.$notify.success({
            title: '成功',
            message: '删除成功'
          });
          var index = _this6.list.indexOf(row);
          _this6.list.splice(index, 1);
        }).catch(function (response) {
          _this6.$notify.error({
            title: '失败',
            message: response.data.errmsg
          });
        });
      });
    }
  }
};