"use strict";

var _interopRequireDefault = require("/var/jenkins_home/workspace/sihong_test_front_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectWithoutProperties2 = _interopRequireDefault(require("/var/jenkins_home/workspace/sihong_test_front_mall/node_modules/@babel/runtime-corejs2/helpers/objectWithoutProperties.js"));
require("core-js/modules/es6.regexp.search");
var _objectSpread2 = _interopRequireDefault(require("/var/jenkins_home/workspace/sihong_test_front_mall/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _moment = _interopRequireDefault(require("moment"));
var _warehouse = require("@/api/warehouse");
var _multiLanguageDisplay = require("@/utils/multiLanguageDisplay");
var _excluded = ["submit", "batchNo"]; //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'MallInStorage',
  data: function data() {
    return {
      multiLanguageDisplay: _multiLanguageDisplay.multiLanguageDisplay,
      pageNo: 1,
      pageSize: 100,
      col: 6,
      dialogVisible: false,
      deleteType: true,
      rules: {
        date: [{
          required: true,
          message: ' ',
          trigger: 'blur'
        }],
        submit: [{
          required: true,
          message: ' ',
          trigger: 'blur'
        }],
        batchNo: [{
          required: true,
          message: ' ',
          trigger: 'blur'
        }]
      },
      search: {
        goodsName: '',
        styleNo: '',
        color: '',
        size: '',
        category: '',
        warehouse: '',
        //仓库
        location: '' //库位号
      },
      listLoading: true,
      selectList: [],
      newList: [],
      list: [],
      form: {
        date: (0, _moment.default)().format('YYYY-MM-DD'),
        remark: '',
        submit: '默认账户名',
        batchNo: '系统自动生成'
      },
      wareHouseList: []
    };
  },
  mounted: function mounted() {},
  computed: {
    total: function total() {
      if (this.list.length == 0) return 0;
      return this.list.reduce(function (total, item) {
        return total + item.num;
      }, 0);
    }
  },
  methods: {
    getEmit: function getEmit() {
      this.$emit('init', []);
    },
    init: function init() {
      this.form = {
        date: (0, _moment.default)().format('YYYY-MM-DD'),
        remark: '',
        submit: '默认账户名',
        batchNo: '系统自动生成'
      };
      this.search = {
        goodsName: '',
        styleNo: '',
        color: '',
        size: '',
        category: '',
        warehouse: '',
        //仓库
        location: '' //库位号
      };
      this.newList = [];
    },
    reload: function reload() {
      this.search = {
        goodsName: '',
        styleNo: '',
        color: '',
        size: '',
        category: '',
        warehouse: '',
        //仓库
        location: '' //库位号
      };
      this.newList = [];
    },
    press: function press() {
      this.getList();
    },
    // 出库方向列表
    getDestination: function getDestination() {
      var _this = this;
      var data = {};
      (0, _warehouse.warehouseListApi)(data).then(function (data) {
        var res = data.data;
        if (res.errno == 0) {
          _this.wareHouseList = res.data;
        }
      });
    },
    getList: function getList() {
      var _this2 = this;
      this.listLoading = true;
      var data = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.search), {}, {
        pageNo: this.pageNo,
        pageSize: this.pageSize
      });
      (0, _warehouse.listLocationApi)(data).then(function (data) {
        var res = data.data;
        if (res.errno == 0) {
          _this2.listLoading = false;
          _this2.newList = res.data.list;
        }
      });
    },
    open: function open(selectList) {
      this.dialogVisible = true;
      this.init();
      this.getDestination();
      if (selectList.length <= 1) {
        this.deleteType = false;
      } else {
        this.deleteType = true;
      }
      var newselectList = JSON.parse(JSON.stringify(selectList));
      this.list = newselectList;
    },
    handleClose: function handleClose() {
      this.dialogVisible = false;
    },
    sure: function sure() {
      var _this3 = this;
      this.$refs.ruleForm.validate(function (valid) {
        if (valid) {
          var _this3$form = _this3.form,
            submit = _this3$form.submit,
            batchNo = _this3$form.batchNo,
            data = (0, _objectWithoutProperties2.default)(_this3$form, _excluded);
          console.log('this.list', _this3.list);
          var newProductList = _this3.list.map(function (product) {
            return {
              locationId: product.locationId,
              //原仓库id
              num: product.num,
              //调拨数量
              productId: product.productId,
              //产品id
              destLocation: product.destLocation,
              //调拨库位号
              destWarehouseId: product.wareHouse,
              //调拨仓库
              inoutDetailId: product.inoutDetailId,
              remove: product.remove ? product.remove : false
            };
          });
          var isNumNewProductList = newProductList.filter(function (item) {
            return item.num != 0;
          });
          if (isNumNewProductList.length == 0) return;
          var params = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, data), {}, {
            productList: newProductList
          });
          console.log('params', params);
          (0, _warehouse.transferApi)(params).then(function (data) {
            var res = data.data;
            if (res.errno == 0) {
              _this3.dialogVisible = false;
              _this3.$message.success('操作成功');
              _this3.getEmit();
            }
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    onDelete: function onDelete(index) {
      this.list[index].num = 0;
      this.$set(this.list[index], 'remove', true);
      var newList = this.list.filter(function (item) {
        return !item.remove;
      });
      if (newList.length <= 1) {
        this.deleteType = false;
      }
    },
    add: function add(item, index) {
      this.newList.splice(index, 1);
      this.list.unshift((0, _objectSpread2.default)({}, item));
      this.list.length <= 1 ? this.deleteType = false : this.deleteType = true;
    }
  }
};