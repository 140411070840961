"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vueCropper = require("vue-cropper");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  name: 'iconCropper',
  components: {
    VueCropper: _vueCropper.VueCropper
  },
  props: {
    imgSrc: {
      type: String,
      default: ''
    },
    //回显上次保存的截图的位置
    location: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
    // iconItem:{
    //   type:Object,
    //   default(){
    //     return {};
    //   }
    // }
  },
  data: function data() {
    return {
      // 学习截图选项
      iconForm: {
        originImgBase64: "",
        imgBase64: ""
        // location: "",
        // matchingName: "",
        // matchingType: "",
        // note: "",
        // picGroupId: "0",
        // tsId: "",
      },
      // 储存截图区域的图片,自己传
      // imgSrc:'',
      // 储存截图后的生成的base64图片
      cutImgSrc: '',
      // cropper插件的配置
      options: {
        canScale: true,
        autoCrop: true,
        canMove: false,
        centerBox: true,
        fixed: true,
        fixedNumber: [940, 340],
        fixedBox: true
      },
      // 截图框相对图片的位置
      clipAxis: {},
      // 截图回显标志,只触发一次实现回显，
      flag: true
    };
  },
  mounted: function mounted() {
    // this.$nextTick(() => {
    //   this.adjustCropperBox();
    // });
  },
  watch: {},
  methods: {
    adjustCropperBox: function adjustCropperBox() {
      var imgAxis = this.$refs.cropper.getImgAxis(); // 获取图片实际宽高
      var imgWidth = imgAxis.width;
      var imgHeight = imgAxis.height;
      var targetWidth = this.options.autoCropWidth; // 目标宽度
      var targetHeight = this.options.autoCropHeight; // 目标高度

      var newWidth = targetWidth;
      var newHeight = targetHeight;

      // 如果图片尺寸小于目标裁剪框，按比例缩放裁剪框
      if (imgWidth < targetWidth || imgHeight < targetHeight) {
        var scaleRatio = Math.min(imgWidth / targetWidth, imgHeight / targetHeight);
        newWidth = targetWidth * scaleRatio;
        newHeight = targetHeight * scaleRatio;
      }

      // 调整裁剪框大小
      this.$refs.cropper.setCropWH(newWidth, newHeight);
    },
    // 学习截图框变化事件
    realTime: function realTime(data) {
      var _this = this;
      if (data.h) {
        // 获取图片相对容器的位置
        var img = this.$refs.cropper.getImgAxis();
        // 获取截图框相对容器的位置
        var clip = this.$refs.cropper.getCropAxis();
        this.clipAxis.x = clip.x1 - img.x1;
        this.clipAxis.y = clip.y1 - img.y1;
        // 获取截图图片
        this.$refs.cropper.getCropData(function (img) {
          _this.clipAxis.width = data.w;
          _this.clipAxis.height = data.h;
          _this.cutImgSrc = img;
        });
        // 获取原图base64的图片
        // this.imageUrlToBase64(data.url);

        // 图标列表编辑回显(初始化只触发一次)
        if (this.location.height != undefined && this.flag) {
          this.$nextTick(function () {
            // auto crop
            _this.$refs.cropper.goAutoCrop();
            _this.$nextTick(function () {
              // if cropped and has position message, update crop box
              _this.$refs.cropper.cropOffsertX = _this.location.x + img.x1;
              _this.$refs.cropper.cropOffsertY = _this.location.y + img.y1;
              _this.$refs.cropper.cropW = _this.location.width;
              _this.$refs.cropper.cropH = _this.location.height;

              // this.iconForm.location=this.iconItem.location;
              // this.iconForm.matchingName=this.iconItem.matchingName;
              // this.iconForm.matchingType=this.iconItem.matchingType;
              // this.iconForm.note=this.iconItem.note;
              // this.picGroupId=this.iconItem.picGroupId.split(',');
              // this.iconForm.id=this.iconItem.id;
              // this.iconForm.tsId=this.iconItem.tsId;
              _this.flag = false;
            });
          });
        }
      }
    },
    // 原图通过canvas转为base64的格式
    imageUrlToBase64: function imageUrlToBase64(src) {
      var _this2 = this;
      //一定要设置为let，不然图片不显示
      var image = new Image();
      //解决跨域问题
      image.setAttribute('crossOrigin', 'anonymous');
      var imageUrl = src;
      image.src = imageUrl;

      //image.onload为异步加载
      image.onload = function () {
        var canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height;
        var context = canvas.getContext('2d');
        context.drawImage(image, 0, 0, image.width, image.height);

        // var quality = 0.8;
        //这里的dataurl就是base64类型
        var dataURL = canvas.toDataURL("image/jpeg"); //使用toDataUrl将图片转换成jpeg的格式
        _this2.iconForm.originImgBase64 = dataURL;
      };
    },
    getCropData: function getCropData() {
      var _this3 = this;
      return new Promise(function (resolve, reject) {
        _this3.$refs.cropper.getCropData(function (base64) {
          _this3.$refs.cropper.getCropBlob(function (blob) {
            _this3.$emit('clickOk', {
              blob: blob,
              base64: base64
            });
            if (blob) {
              resolve({
                blob: blob,
                base64: base64
              }); // 成功返回 Blob 数据
            } else {
              reject(new Error("Failed to get crop blob"));
            }
          });
        });
      });
    }
  }
};