"use strict";

var _interopRequireDefault = require("/var/jenkins_home/workspace/sihong_test_front_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
var _objectSpread2 = _interopRequireDefault(require("/var/jenkins_home/workspace/sihong_test_front_mall/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _lodash = _interopRequireDefault(require("lodash"));
var _trends = require("@/api/cont/trends");
var _storage = require("@/api/storage");
var _MultiLanguageInp = _interopRequireDefault(require("@/components/MultiLanguageInp"));
var _multiLanguageDisplay = require("@/utils/multiLanguageDisplay");
var _Tinymce = _interopRequireDefault(require("@/components/Tinymce"));
var _auth = require("@/utils/auth");
var _sortablejs = _interopRequireDefault(require("sortablejs"));
var _category = require("@/api/category");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    MultiLanguageInp: _MultiLanguageInp.default,
    Tinymce: _Tinymce.default
  },
  data: function data() {
    return {
      dialogVisible: false,
      multiLanguageDisplay: _multiLanguageDisplay.multiLanguageDisplay,
      uploadPath: _storage.uploadPath,
      total: 0,
      type: 0,
      pageSize: 10,
      currentPage: 1,
      tit: '新增',
      id: '',
      staus: 1,
      // 1新增 2编辑
      fileList: [],
      form: {
        date: '',
        zipCode: '',
        title: '',
        text: '',
        enable: false,
        url: '',
        brief: '',
        contentZh: '',
        contentEn: null,
        contentEs: null,
        tagList: [],
        topCategoryId: '',
        serialId: ''
      },
      dataSource: [],
      tag: [],
      selectedKeys: [],
      props: {
        multiple: true,
        checkStrictly: true,
        value: "id",
        label: "name",
        children: 'childList',
        emitPath: false
      },
      oldIndex: null,
      newIndex: null,
      value: '',
      activeName: 'zh',
      categoryL1: [],
      categoryL2: [],
      rules: {
        topCategoryId: [{
          required: true,
          message: '请选择产品类别',
          trigger: 'change'
        }],
        serialId: [{
          required: true,
          message: '请选择系列类别',
          trigger: 'change'
        }]
      },
      seriesCategory: []
    };
  },
  computed: {
    headers: function headers() {
      return {
        'Mu-Token': (0, _auth.getToken)()
      };
    }
  },
  mounted: function mounted() {
    this.type = this.$route.meta.type;
    this.getList();
    this.handleGetTagList();
    this.init();
    this.getCatL1();
  },
  methods: {
    init: function init() {
      // 1. 获取拖拽元素的父元素
      // 因为使用的element的table 所以可直接获取tbody
      var el = document.querySelector(".el-table__body-wrapper tbody");
      var _this = this;
      // 2. 创建并配置相关参数
      var sortable = new _sortablejs.default(el, {
        forceFallback: true,
        // 此处进行配置 及 回调函数的使用
        handle: '.handle_move',
        // css选择器的字符串 若设置该值，则表示只有按住拖动手柄才能使列表单元进行拖动
        draggable: '.el-table__row',
        // 允许拖拽的项目类名
        // 因为拖拽过程中不尽兴位置调整，所以就要记录拖拽行初始位置以及目标位置
        // 拖拽中 回调函数
        onMove: function onMove(customEvent) {
          // 禁止在拖拽过程中交换位置
          // 可将初始位置及目标位置进行记录
          _this.oldIndex = customEvent.dragged.rowIndex; //  初始位置
          _this.newIndex = customEvent.related.rowIndex; // 目标位置
          return false;
        },
        // 拖拽结束，调整位置
        onEnd: function onEnd() {
          // 在拖拽结束后，获取初始及目标位置
          var newIndex = _this.newIndex,
            oldIndex = _this.oldIndex;
          var currRow = _this.dataSource.splice(oldIndex, 1)[0];
          _this.dataSource.splice(newIndex, 0, currRow);
          // 拖拽完成，初始位置及目标位置重置
          _this.newIndex = null;
          _this.oldIndex = null;
          var dataSource = _this.dataSource.map(function (item) {
            return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, item), {}, {
              type: _this.type
            });
          });
          (0, _trends.cmsUpdateOrSaveBatch)(dataSource).then(function (res) {});
        }
      });
    },
    handleClick: function handleClick(data) {},
    onEnable: function onEnable(val, value) {
      var data = {
        date: value.date,
        text: value.text,
        title: value.title,
        type: this.type,
        id: value.id,
        zipcode: value.zipCode,
        enable: val,
        url: value.url,
        brief: value.brief
      };
      (0, _trends.updateApi)(data).then(function (res) {
        // this.getList()
      });
    },
    handleAvatarSuccess: function handleAvatarSuccess(res) {
      this.form.url = res.data.url;
    },
    // 删除
    deleteItem: function deleteItem(item) {
      var _this2 = this;
      (0, _trends.deleteApi)({
        id: item.id
      }).then(function (res) {
        _this2.getList();
      });
    },
    getList: function getList() {
      var _this3 = this;
      var params = {
        type: this.type,
        page: this.currentPage,
        size: this.pageSize
      };
      (0, _trends.pageApi)(params).then(function (res) {
        _this3.dataSource = res.data.data.list;
        _this3.total = res.data.data.total;
      });
    },
    getCatL1: function getCatL1() {
      var _this4 = this;
      (0, _category.listApiCatL1)().then(function (res) {
        _this4.categoryL1 = res.data.list;
        _this4.total = res.data.total;
      });
    },
    handleClose: function handleClose() {
      this.dialogVisible = false;
    },
    handleSizeChange: function handleSizeChange(val) {
      this.pageSize = val;
      this.getList();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.currentPage = val;
      this.getList();
    },
    handleRemove: function handleRemove(file) {
      this.fileList = this.fileList.filter(function (item) {
        return item.id !== file.id;
      });
    },
    handleSuccess: function handleSuccess(res) {
      this.fileList.push(res.data);
    },
    add: function add() {
      this.dialogVisible = true;
      // this.form = {}
      this.form = {
        date: '',
        zipCode: '',
        title: '',
        text: '',
        enable: false,
        url: '',
        brief: '',
        contentZh: '',
        contentEn: '',
        tagList: [],
        topCategoryId: '',
        serialId: ''
      };
      this.fileList = [];
      this.selectedKeys = [];
      this.staus = 1;
    },
    edit: function edit(item) {
      var _this5 = this;
      this.$nextTick(function () {
        _this5.form = {
          date: '',
          zipCode: '',
          title: '',
          text: '',
          enable: false,
          url: '',
          brief: '',
          contentZh: '',
          contentEn: '',
          tagList: [],
          topCategoryId: '',
          serialId: ''
        };
        _this5.dialogVisible = true;
        _this5.tit = '编辑';
        _this5.staus = 2;
        _this5.id = item.id;
        _this5.form = _lodash.default.cloneDeep(item);
        _this5.form.fileList ? _this5.fileList = _lodash.default.cloneDeep(_this5.form.fileList) : _this5.fileList = [];
        if (item.date) {
          _this5.form.date = [item.date, item.endDate ? item.endDate : item.date];
        }
        _this5.selectedKeys = item.tagList ? item.tagList.map(function (item1) {
          return item1.id;
        }) : [];
        _this5.tagList = item.tagList;
        _this5.handleGetSerialsByCategoryId();
      });

      // this.form.date = item.date
      // this.form.enable = item.enable
      // this.form.zipCode = item.zipcode
      // this.form.title = item.title
      // this.form.text = item.text
      // this.form.url = item.url
      // this.form.brief = item.brief
      // this.form.contentZh = item.contentZh
      // this.form.contentEn = item.contentEn
    },
    sure: function sure() {
      var _this6 = this;
      // 使用 Object.values() 方法将 form 对象的所有值转换为数组
      var values = Object.keys(this.form);
      // 使用 Array.every() 方法判断数组中的每个值是否为空
      var isFormEmpty = values.filter(function (key) {
        return key !== 'enable';
      }).every(function (key) {
        return _this6.form[key] === '';
      });
      if (isFormEmpty) {
        this.$message.warning('请输入信息');
        return;
      }
      var data = {
        date: this.form.date ? this.form.date[0] : '',
        endDate: this.form.date ? this.form.date[1] : '',
        text: this.form.text,
        title: this.form.title,
        type: this.type,
        id: this.id,
        zipcode: this.form.zipCode,
        enable: this.form.enable,
        url: this.form.url,
        brief: this.form.brief,
        contentZh: this.form.contentZh,
        contentEn: this.form.contentEn,
        contentEs: this.form.contentEs,
        fileList: this.fileList,
        tagList: this.form.tagList,
        topCategoryId: this.form.topCategoryId,
        serialId: this.form.serialId
      };
      this.$refs.form.validate(function (valid) {
        if (valid) {
          if (_this6.staus == 1) {
            (0, _trends.trendsCreateApi)(data).then(function (res) {
              if (res.data.errno == 0) {
                _this6.$message.success('添加成功');
                _this6.dialogVisible = false;
                _this6.getList();
              }
            });
          }
          if (_this6.staus == 2) {
            (0, _trends.updateApi)(data).then(function (res) {
              _this6.$message.success('编辑成功');
              _this6.dialogVisible = false;
              _this6.getList();
            });
          }
        }
      });
    },
    routeLabel: function routeLabel() {
      this.$router.push({
        path: '/labelManagement',
        query: {
          type: 3
        }
      });
    },
    handleGetTagList: function handleGetTagList() {
      var _this7 = this;
      (0, _trends.getTagList)({
        type: 3
      }).then(function (res) {
        _this7.tag = res.data.list;
        _this7.tag.forEach(function (item) {
          item.disabled = true;
          item.name = _this7.multiLanguageDisplay(item.name);
          if (item.childList) {
            item.childList.forEach(function (item1) {
              item1.name = _this7.multiLanguageDisplay(item1.name);
            });
          }
        });
      });
    },
    handleSelectionChange: function handleSelectionChange(selectedIds) {
      // 根据选中的 ID 数组过滤出完整对象
      var _getSelectedObjects = function getSelectedObjects(options, selectedIds) {
        var result = [];
        options.forEach(function (option) {
          if (selectedIds.includes(option.id)) {
            result.push(option);
          }
          if (option.childList) {
            result = result.concat(_getSelectedObjects(option.childList, selectedIds));
          }
        });
        return result;
      };
      this.form.tagList = _getSelectedObjects(this.tag, selectedIds);
    },
    handleGetSerialsByCategoryId: function handleGetSerialsByCategoryId(val) {
      var _this8 = this;
      if (!this.form.topCategoryId) return;
      (0, _category.getSerialsByCategoryId)({
        categoryId: this.form.topCategoryId
      }).then(function (res) {
        if (val) {
          _this8.$set(_this8.form, 'serialId', '');
        }
        _this8.seriesCategory = res.data;
      });
    }
  }
};