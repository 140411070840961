"use strict";

var _interopRequireDefault = require("/var/jenkins_home/workspace/sihong_test_front_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.deleteEmailTemplate = deleteEmailTemplate;
exports.getById = getById;
exports.getEmailTemplateList = getEmailTemplateList;
exports.getListScene = getListScene;
exports.getParamByScene = getParamByScene;
exports.saveEmailTemplate = saveEmailTemplate;
var _request = _interopRequireDefault(require("@/utils/request"));
/**
 * 获取邮件模板列表
 * @param {*} data
 * @returns
 */
function getEmailTemplateList(data) {
  return (0, _request.default)({
    url: '/email/template/page',
    method: 'post',
    data: data
  });
}
/**
 * 保存邮件模板
 * @param {*} data
 * @returns
 */
function saveEmailTemplate(data) {
  return (0, _request.default)({
    url: '/email/template/save',
    method: 'post',
    data: data
  });
}
/**
 * 删除邮件模板
 * @param {*} params
 * @returns
 */
function deleteEmailTemplate(params) {
  return (0, _request.default)({
    url: '/email/template/delete',
    method: 'get',
    params: params
  });
}
/**
 * 获取应用场景列表
 * @param {*} data
 * @returns
 */
function getListScene(data) {
  return (0, _request.default)({
    url: '/email/template/listScene',
    method: 'post',
    data: data
  });
}
/**
 * 获取详情
 * @param {*} params
 * @returns
 */
function getById(params) {
  return (0, _request.default)({
    url: '/email/template/getById',
    method: 'get',
    params: params
  });
}
/**
 * 获取应用场景下可插入的代码
 * @param {*} params
 * @returns
 */
function getParamByScene(params) {
  return (0, _request.default)({
    url: '/email/template/getParamByScene',
    method: 'get',
    params: params
  });
}