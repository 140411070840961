var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            top: "6vh",
            width: "80%",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "content", staticStyle: { width: "100%" } },
            [
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  staticClass: "demo-ruleForm",
                  attrs: {
                    model: _vm.form,
                    rules: _vm.rules,
                    "label-width": "100px",
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: _vm.col } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "出库日期", prop: "date" } },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  "value-format": "yyyy-MM-dd",
                                  format: "",
                                  type: "date",
                                  placeholder: "选择日期",
                                },
                                model: {
                                  value: _vm.form.date,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "date", $$v)
                                  },
                                  expression: "form.date",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: _vm.col } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "出库类型", prop: "typeId" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "filter-item",
                                  staticStyle: { width: "100%" },
                                  attrs: { filterable: "", clearable: "" },
                                  on: { change: _vm.onOutType },
                                  model: {
                                    value: _vm.form.typeId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "typeId", $$v)
                                    },
                                    expression: "form.typeId",
                                  },
                                },
                                _vm._l(_vm.typeList, function (item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: { label: item.name, value: item.id },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: _vm.col } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "出库方向", prop: "destId" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "filter-item",
                                  staticStyle: { width: "100%" },
                                  attrs: { filterable: "", clearable: "" },
                                  model: {
                                    value: _vm.form.destId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "destId", $$v)
                                    },
                                    expression: "form.destId",
                                  },
                                },
                                _vm._l(_vm.wareHouseList, function (item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: { label: item.name, value: item.id },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: _vm.col } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "收货方" } },
                            [
                              _c("el-input", {
                                staticClass: "filter-item",
                                staticStyle: { width: "100%" },
                                attrs: { clearable: "", placeholder: "请输入" },
                                model: {
                                  value: _vm.form.receiver,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "receiver", $$v)
                                  },
                                  expression: "form.receiver",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: _vm.col } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "归还日期" } },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  "value-format": "yyyy-MM-dd",
                                  type: "date",
                                  placeholder: "选择日期",
                                },
                                model: {
                                  value: _vm.form.retDate,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "retDate", $$v)
                                  },
                                  expression: "form.retDate",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 18 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "备注" } },
                            [
                              _c("el-input", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  type: "textarea",
                                  rows: 1,
                                  placeholder: "请输入",
                                },
                                model: {
                                  value: _vm.form.remark,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "remark", $$v)
                                  },
                                  expression: "form.remark",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: _vm.col } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "提交人", prop: "submit" } },
                            [_c("span", [_vm._v("默认账户名")])]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: _vm.col } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "出库单号", prop: "batchNo" } },
                            [_c("span", [_vm._v(_vm._s(_vm.form.billNo))])]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: _vm.col } },
                        [
                          _c("el-form-item", { attrs: { label: "修改人" } }, [
                            _c("span", [_vm._v("默认账户名")]),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: _vm.col } },
                        [
                          _c("el-form-item", { attrs: { label: "修改日期" } }, [
                            _c("span", [_vm._v(_vm._s(_vm.editDate))]),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-divider"),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "listContent" },
                _vm._l(_vm.list, function (item, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "list" },
                    [
                      _c(
                        "el-form",
                        { attrs: { "label-width": "100px" } },
                        [
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: _vm.col } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "商品款号" } },
                                    [_c("span", [_vm._v(_vm._s(item.styleNo))])]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: _vm.col } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "商品名称" } },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.multiLanguageDisplay(
                                              item.goodsName
                                            )
                                          )
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: _vm.col } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "商品品牌" } },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.multiLanguageDisplay(
                                              item.brandName
                                            )
                                          )
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: _vm.col } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "商品单位" } },
                                    [_c("span", [_vm._v(_vm._s(item.unit))])]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "商品类别" } },
                                    [
                                      _c("span", [
                                        _vm._v(_vm._s(item.categoryName)),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: _vm.col } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "原仓库" } },
                                    [
                                      _c("span", [
                                        _vm._v(_vm._s(item.warehouseName)),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: _vm.col } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "库位号" } },
                                    [
                                      _c("span", [
                                        _vm._v(_vm._s(item.locationName)),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: _vm.col } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "商品颜色" } },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.multiLanguageDisplay(item.color)
                                          )
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: _vm.col } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "商品尺码" } },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.multiLanguageDisplay(item.size)
                                          )
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: _vm.col } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "剩余数量" } },
                                    [
                                      _c("span", [
                                        _vm._v(_vm._s(item.inventoryCount)),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: _vm.col } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "商品数量" } },
                                    [
                                      _c("el-input-number", {
                                        staticStyle: { width: "100%" },
                                        attrs: { min: 0 },
                                        model: {
                                          value: item.num,
                                          callback: function ($$v) {
                                            _vm.$set(item, "num", $$v)
                                          },
                                          expression: "item.num",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("el-divider"),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                0
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.sure } },
                [_vm._v("确 认")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }