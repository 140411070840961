var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            top: "6vh",
            width: "80%",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "content", staticStyle: { width: "100%" } },
            [
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  staticClass: "demo-ruleForm",
                  attrs: {
                    model: _vm.form,
                    rules: _vm.rules,
                    "label-width": "120px",
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: _vm.col } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "入库日期", prop: "date" } },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  "value-format": "yyyy-MM-dd",
                                  type: "date",
                                  placeholder: "选择日期",
                                },
                                model: {
                                  value: _vm.form.date,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "date", $$v)
                                  },
                                  expression: "form.date",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: _vm.col } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "入库类型", prop: "typeId" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "filter-item",
                                  staticStyle: { width: "100%" },
                                  attrs: { filterable: "", clearable: "" },
                                  model: {
                                    value: _vm.form.typeId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "typeId", $$v)
                                    },
                                    expression: "form.typeId",
                                  },
                                },
                                _vm._l(_vm.typeList, function (item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: { label: item.name, value: item.id },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: _vm.col } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "入库仓库", prop: "warehouseId" },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "filter-item",
                                  staticStyle: { width: "100%" },
                                  attrs: { filterable: "", clearable: "" },
                                  on: { change: _vm.onWareSelect },
                                  model: {
                                    value: _vm.form.warehouseId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "warehouseId", $$v)
                                    },
                                    expression: "form.warehouseId",
                                  },
                                },
                                _vm._l(_vm.wareHouseList, function (item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: { label: item.name, value: item.id },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: _vm.col } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "库位号", prop: "inoutId" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "filter-item",
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    "allow-create": "",
                                    filterable: "",
                                    clearable: "",
                                  },
                                  on: { change: _vm.onSelect },
                                  model: {
                                    value: _vm.form.inoutId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "inoutId", $$v)
                                    },
                                    expression: "form.inoutId",
                                  },
                                },
                                _vm._l(_vm.locationList, function (item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: { label: item.name, value: item.id },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: _vm.col } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "商品来源" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "filter-item",
                                  staticStyle: { width: "100%" },
                                  attrs: { filterable: "", clearable: "" },
                                  model: {
                                    value: _vm.form.sourceId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "sourceId", $$v)
                                    },
                                    expression: "form.sourceId",
                                  },
                                },
                                _vm._l(_vm.sourceList, function (item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: { label: item.name, value: item.id },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 18 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "备注" } },
                            [
                              _c("el-input", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  type: "textarea",
                                  rows: 1,
                                  placeholder: "请输入",
                                },
                                model: {
                                  value: _vm.form.remark,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "remark", $$v)
                                  },
                                  expression: "form.remark",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: _vm.col } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "提交人", prop: "submit" } },
                            [_c("span", [_vm._v(_vm._s(_vm.form.submit))])]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: _vm.col } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "入库单号", prop: "batchNo" } },
                            [_c("span", [_vm._v(_vm._s(_vm.form.batchNo))])]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: _vm.col } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "本次入库总数" } },
                            [_c("span", [_vm._v(_vm._s(_vm.total))])]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-divider"),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "listContent" },
                _vm._l(_vm.list, function (item, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "list" },
                    [
                      !item.remove
                        ? _c(
                            "el-form",
                            { attrs: { "label-width": "100px" } },
                            [
                              _vm.list.length > 1
                                ? _c(
                                    "el-row",
                                    [
                                      _c("el-col", { staticClass: "delete" }, [
                                        _c("img", {
                                          attrs: {
                                            src: require("@/assets/image/shanchu.png"),
                                            alt: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.onDelete(index, item)
                                            },
                                          },
                                        }),
                                      ]),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: _vm.col } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "商品款号",
                                            required: true,
                                          },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              staticClass: "filter-item",
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                filterable: "",
                                                clearable: "",
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.selectStyle(
                                                    $event,
                                                    index
                                                  )
                                                },
                                              },
                                              model: {
                                                value: item.styleNo,
                                                callback: function ($$v) {
                                                  _vm.$set(item, "styleNo", $$v)
                                                },
                                                expression: "item.styleNo",
                                              },
                                            },
                                            _vm._l(
                                              _vm.styleList,
                                              function (citem, index) {
                                                return _c("el-option", {
                                                  key: index,
                                                  attrs: {
                                                    label: citem,
                                                    value: citem,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-col",
                                    { attrs: { span: _vm.col } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "商品名称",
                                            required: true,
                                          },
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.multiLanguageDisplay(
                                                  item.goodsName
                                                )
                                              )
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-col",
                                    { attrs: { span: _vm.col } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "商品品牌",
                                            required: true,
                                          },
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.multiLanguageDisplay(
                                                  item.brandName
                                                )
                                              )
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-col",
                                    { attrs: { span: _vm.col } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "商品单位",
                                            required: true,
                                          },
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(_vm._s(item.unit)),
                                          ]),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: _vm.col } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "商品类别",
                                            required: true,
                                          },
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(_vm._s(item.categoryName)),
                                          ]),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm._l(
                                item.productList,
                                function (ccitem, ccindex) {
                                  return _c(
                                    "el-row",
                                    { key: ccindex },
                                    [
                                      _c(
                                        "el-col",
                                        { attrs: { span: _vm.col } },
                                        [
                                          _c(
                                            "el-form-item",
                                            { attrs: { label: "商品颜色" } },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.multiLanguageDisplay(
                                                      ccitem.color
                                                    )
                                                  )
                                                ),
                                              ]),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-col",
                                        { attrs: { span: _vm.col } },
                                        [
                                          _c(
                                            "el-form-item",
                                            { attrs: { label: "商品尺码" } },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.multiLanguageDisplay(
                                                      ccitem.size
                                                    )
                                                  )
                                                ),
                                              ]),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-col",
                                        { attrs: { span: _vm.col } },
                                        [
                                          _c(
                                            "el-form-item",
                                            { attrs: { label: "库位号" } },
                                            [
                                              _c("el-input", {
                                                staticStyle: { width: "100%" },
                                                attrs: {
                                                  placeholder: "请输入",
                                                },
                                                model: {
                                                  value: ccitem.locationName,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      ccitem,
                                                      "locationName",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "ccitem.locationName",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-col",
                                        { attrs: { span: _vm.col } },
                                        [
                                          _c(
                                            "el-form-item",
                                            { attrs: { label: "商品数量" } },
                                            [
                                              _c("el-input-number", {
                                                staticStyle: { width: "100%" },
                                                attrs: { min: 0 },
                                                model: {
                                                  value: ccitem.num,
                                                  callback: function ($$v) {
                                                    _vm.$set(ccitem, "num", $$v)
                                                  },
                                                  expression: "ccitem.num",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                }
                              ),
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !item.remove ? _c("el-divider") : _vm._e(),
                    ],
                    1
                  )
                }),
                0
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "newAdd" }, [
            _c("div", { staticClass: "btn", on: { click: _vm.addNewList } }, [
              _c("img", {
                attrs: { src: require("@/assets/image/newAdd.png"), alt: "" },
              }),
              _vm._v(" "),
              _c("span", [_vm._v("新增")]),
            ]),
          ]),
          _vm._v(" "),
          _c("el-divider"),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.sure } },
                [_vm._v("确 认")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }