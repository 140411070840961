"use strict";

var _interopRequireDefault = require("/var/jenkins_home/workspace/sihong_test_front_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _selected = require("@/api/selected");
var _Tinymce = _interopRequireDefault(require("@/components/Tinymce"));
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'SelectCompany',
  components: {
    Tinymce: _Tinymce.default
  },
  data: function data() {
    return {
      dataForm: {
        detail: null,
        initContent: null
      }
    };
  },
  computed: {},
  created: function created() {
    this.init();
  },
  methods: {
    init: function init() {
      var _this = this;
      (0, _selected.selectedCompanyDetail)().then(function (response) {
        _this.dataForm.detail = response.data.data.content;
        _this.dataForm.initContent = response.data.data.content;
      });
    },
    cancel: function cancel() {
      window.tinyMCE.activeEditor.setContent(this.dataForm.initContent);
    },
    update: function update() {
      var _this2 = this;
      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          (0, _selected.updateSelectedCompany)({
            content: _this2.dataForm.detail
          }).then(function (response) {
            _this2.$notify.success({
              title: '成功',
              message: '修改成功'
            });
          }).catch(function (response) {
            _this2.$notify.error({
              title: '失败',
              message: response.data.errmsg
            });
          });
        }
      });
    }
  }
};