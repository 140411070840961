var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tinymce-container editor-container" },
    [
      _c("textarea", {
        staticClass: "tinymce-textarea",
        attrs: { id: _vm.tinymceId },
      }),
      _vm._v(" "),
      _vm.needAddCode
        ? _c(
            "el-select",
            {
              staticClass: "tinymce-select",
              attrs: { value: _vm.code, size: "mini", placeholder: "请选择" },
              on: { change: _vm.selectChange },
            },
            _vm._l(_vm.codeList, function (item) {
              return _c("el-option", {
                key: item.key,
                attrs: { label: item.remark, value: item },
              })
            }),
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "editor-custom-btn-container" },
        [
          _c("editor-img-drag", {
            staticClass: "editor-upload-btn",
            attrs: { color: "#1890ff" },
            on: { success: _vm.imageSuccess },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }