"use strict";

var _interopRequireDefault = require("/var/jenkins_home/workspace/sihong_test_front_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createSpec = createSpec;
exports.createSpecMultiLang = createSpecMultiLang;
exports.deleteSpec = deleteSpec;
exports.listSpec = listSpec;
exports.readSpec = readSpec;
exports.updateSpec = updateSpec;
var _request = _interopRequireDefault(require("@/utils/request"));
// 商品规格
function listSpec(data) {
  return (0, _request.default)({
    url: '/spec/list',
    method: 'POST',
    data: data
  });
}
function createSpec(data) {
  return (0, _request.default)({
    url: '/spec/create',
    method: 'POST',
    data: data
  });
}
function createSpecMultiLang(data) {
  return (0, _request.default)({
    url: '/spec/createByMultiLang',
    method: 'POST',
    data: data
  });
}
function readSpec(data) {
  return (0, _request.default)({
    url: '/spec/read',
    method: 'POST',
    data: data
  });
}
function updateSpec(data) {
  return (0, _request.default)({
    url: '/spec/update',
    method: 'POST',
    data: data
  });
}
function deleteSpec(data) {
  return (0, _request.default)({
    url: '/spec/delete',
    method: 'POST',
    data: data
  });
}