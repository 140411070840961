"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: '提示'
    },
    loading: {
      type: Boolean,
      default: false
    },
    width: {
      type: String | undefined,
      default: undefined
    },
    showCancel: {
      type: Boolean,
      default: false
    },
    confirmText: {
      type: String,
      default: '确定'
    },
    cancelText: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {};
  },
  methods: {
    confirm: function confirm() {
      this.$emit('confirm');
    },
    cancel2: function cancel2() {
      this.$emit('update:visible', false);
    },
    cancel: function cancel() {
      if (this.cancelText) {
        this.$emit('cancel');
      } else {
        this.$emit('update:visible', false);
      }
    }
  },
  components: {}
};