var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              attrs: { placeholder: "请选择类型", clearable: "" },
              model: {
                value: _vm.search.type,
                callback: function ($$v) {
                  _vm.$set(_vm.search, "type", $$v)
                },
                expression: "search.type",
              },
            },
            [
              _c("el-option", { attrs: { label: "取消订单", value: 1 } }),
              _vm._v(" "),
              _c("el-option", { attrs: { label: "退货退款", value: 2 } }),
            ],
            1
          ),
          _vm._v(" "),
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: { clearable: "", placeholder: "请输入退货/退款单号" },
            model: {
              value: _vm.search.refundNo,
              callback: function ($$v) {
                _vm.$set(_vm.search, "refundNo", $$v)
              },
              expression: "search.refundNo",
            },
          }),
          _vm._v(" "),
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "160px" },
            attrs: { clearable: "", placeholder: "请输入商城订单号" },
            model: {
              value: _vm.search.orderNo,
              callback: function ($$v) {
                _vm.$set(_vm.search, "orderNo", $$v)
              },
              expression: "search.orderNo",
            },
          }),
          _vm._v(" "),
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "160px" },
            attrs: { clearable: "", placeholder: "请输入款号" },
            model: {
              value: _vm.search.styleNo,
              callback: function ($$v) {
                _vm.$set(_vm.search, "styleNo", $$v)
              },
              expression: "search.styleNo",
            },
          }),
          _vm._v(" "),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              attrs: { placeholder: "请选择退款状态", clearable: "" },
              model: {
                value: _vm.search.refundStatus,
                callback: function ($$v) {
                  _vm.$set(_vm.search, "refundStatus", $$v)
                },
                expression: "search.refundStatus",
              },
            },
            [
              _c("el-option", { attrs: { label: "退款成功", value: 1 } }),
              _vm._v(" "),
              _c("el-option", { attrs: { label: "未退款", value: 0 } }),
              _vm._v(" "),
              _c("el-option", { attrs: { label: "退款失败", value: 2 } }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: { click: _vm.onSearch },
            },
            [_vm._v("查找")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          attrs: {
            data: _vm.list,
            "element-loading-text": "正在查询中。。。",
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "类型",
              width: "100",
              prop: "type",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(scope.row.type === 1 ? "取消订单" : "退货退款")
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "退货/退款单号",
              prop: "refundNo",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "商城订单号", prop: "orderNo" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "退货进度",
              prop: "returnGoodsStatus",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(scope.row.returnGoodsStatus || "-")),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "退款状态", prop: "refundStatus" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "操作" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "span",
                      {
                        staticClass: "operate",
                        on: {
                          click: function ($event) {
                            return _vm.lookDetail(scope.row)
                          },
                        },
                      },
                      [_vm._v("查看详情")]
                    ),
                    _vm._v(" "),
                    scope.row.refundStatus === "退款失败"
                      ? _c(
                          "span",
                          {
                            staticClass: "operate",
                            on: {
                              click: function ($event) {
                                return _vm.goRefund(scope.row)
                              },
                            },
                          },
                          [_vm._v("退款")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total > 0",
          },
        ],
        attrs: { total: _vm.total, page: _vm.pageNo, limit: _vm.pageSize },
        on: {
          "update:page": function ($event) {
            _vm.pageNo = $event
          },
          "update:limit": function ($event) {
            _vm.pageSize = $event
          },
          pagination: _vm.getList,
        },
      }),
      _vm._v(" "),
      _vm.afterSalesVisible
        ? _c("AfterSalesDialog", {
            attrs: { dialogVisible: _vm.afterSalesVisible, id: _vm.orderId },
            on: { closeDialog: _vm.closeDialog },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }