"use strict";

var _interopRequireDefault = require("/var/jenkins_home/workspace/sihong_test_front_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.deleteArticle = deleteArticle;
exports.deleteMenu = deleteMenu;
exports.languageList = languageList;
exports.listMenu = listMenu;
exports.pageArticle = pageArticle;
exports.saveArticle = saveArticle;
exports.saveMenu = saveMenu;
var _request = _interopRequireDefault(require("@/utils/request"));
/**
 * 获取类目
 * @returns
 */
function languageList() {
  return (0, _request.default)({
    url: '/rule/language/list',
    method: 'get'
  });
}
/**
 * 获取类目
 * @returns
 */
function listMenu() {
  return (0, _request.default)({
    url: '/rule/listMenu',
    method: 'get'
  });
}
/**
 * 保存类目
 * @param {*} data
 * @returns
 */
function saveMenu(data) {
  return (0, _request.default)({
    url: '/rule/saveMenu',
    method: 'post',
    data: data
  });
}
/**
 * 删除类目
 * @param {*} data
 * @returns
 */
function deleteMenu(data) {
  return (0, _request.default)({
    url: '/rule/deleteMenu',
    method: 'post',
    data: data
  });
}
/**
 * 获取规则
 * @param {*} params
 * @returns
 */
function pageArticle(params) {
  return (0, _request.default)({
    url: '/rule/pageArticle',
    method: 'get',
    params: params
  });
}
/**
 * 保存规则
 * @param {*} data
 * @returns
 */
function saveArticle(data) {
  return (0, _request.default)({
    url: '/rule/saveArticle',
    method: 'post',
    data: data
  });
}
/**
 * 删除规则
 * @param {*} data
 * @returns
 */
function deleteArticle(data) {
  return (0, _request.default)({
    url: '/rule/deleteArticle',
    method: 'post',
    data: data
  });
}