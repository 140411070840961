"use strict";

var _interopRequireDefault = require("/var/jenkins_home/workspace/sihong_test_front_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectWithoutProperties2 = _interopRequireDefault(require("/var/jenkins_home/workspace/sihong_test_front_mall/node_modules/@babel/runtime-corejs2/helpers/objectWithoutProperties.js"));
require("core-js/modules/es6.regexp.search");
var _objectSpread2 = _interopRequireDefault(require("/var/jenkins_home/workspace/sihong_test_front_mall/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _moment = _interopRequireDefault(require("moment"));
var _warehouse = require("@/api/warehouse");
var _multiLanguageDisplay = require("@/utils/multiLanguageDisplay");
var _excluded = ["submit", "batchNo", "inoutId"]; //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'MallInStorage',
  data: function data() {
    return {
      multiLanguageDisplay: _multiLanguageDisplay.multiLanguageDisplay,
      col: 6,
      dialogVisible: false,
      pageNo: 1,
      pageSize: 100,
      outType: null,
      //出库类型
      deleteType: true,
      rules: {
        date: [{
          required: true,
          message: ' ',
          trigger: 'blur'
        }],
        typeId: [{
          required: true,
          message: ' ',
          trigger: 'change'
        }],
        destId: [{
          required: true,
          message: ' ',
          trigger: 'change'
        }],
        submit: [{
          required: true,
          message: ' ',
          trigger: 'blur'
        }],
        batchNo: [{
          required: true,
          message: ' ',
          trigger: 'blur'
        }],
        receiver: [{
          required: true,
          message: ' ',
          trigger: 'blur'
        }],
        retDate: [{
          required: true,
          message: ' ',
          trigger: 'change'
        }]
      },
      search: {
        goodsName: '',
        styleNo: '',
        color: '',
        size: '',
        category: '',
        warehouse: '',
        //仓库
        location: '' //库位号
      },
      newList: [],
      list: [],
      form: {
        date: (0, _moment.default)().format('YYYY-MM-DD'),
        typeId: '',
        destId: '',
        receiver: '',
        retDate: '',
        remark: '',
        submit: '默认账户名',
        batchNo: '系统自动生成'
      },
      listLoading: true,
      typeList: [],
      wareHouseList: []
    };
  },
  mounted: function mounted() {},
  computed: {
    total: function total() {
      if (this.list.length == 0) return 0;
      return this.list.reduce(function (total, item) {
        return total + item.num;
      }, 0);
    }
  },
  methods: {
    init: function init() {
      this.form = {
        date: (0, _moment.default)().format('YYYY-MM-DD'),
        typeId: '',
        destId: '',
        receiver: '',
        retDate: '',
        remark: '',
        destName: '',
        submit: '默认账户名',
        batchNo: '系统自动生成'
      };
      this.search = {
        goodsName: '',
        styleNo: '',
        color: '',
        size: '',
        category: '',
        warehouse: '',
        //仓库
        location: '' //库位号
      };
      this.newList = [];
    },
    reload: function reload() {
      this.search = {
        goodsName: '',
        styleNo: '',
        color: '',
        size: '',
        category: '',
        warehouse: '',
        //仓库
        location: '' //库位号
      };
      this.newList = [];
    },
    getInType: function getInType() {
      var _this = this;
      var data = {
        type: 2
      };
      (0, _warehouse.operationApi)(data).then(function (data) {
        var res = data.data;
        if (res.errno == 0) {
          _this.typeList = res.data;
        }
      });
    },
    getEmit: function getEmit() {
      this.$emit('init', []);
    },
    // 出库方向列表
    getDestination: function getDestination(val) {
      var _this2 = this;
      var data = {
        typeId: val
      };
      (0, _warehouse.outboundDestinationApi)(data).then(function (data) {
        var res = data.data;
        if (res.errno == 0) {
          _this2.wareHouseList = res.data;
        }
      });
    },
    onOutType: function onOutType(val) {
      this.getDestination(val);
      console.log('val', val);
      this.outType = val;
      this.form.destId = '';
    },
    open: function open(selectList) {
      this.dialogVisible = true;
      this.init();
      this.getInType();
      if (selectList.length <= 1) {
        this.deleteType = false;
      } else {
        this.deleteType = true;
      }
      var newselectList = JSON.parse(JSON.stringify(selectList));
      this.list = newselectList;
    },
    handleClose: function handleClose() {
      this.dialogVisible = false;
      // this.$emit('init',[])
    },
    press: function press() {
      this.getList();
    },
    getList: function getList() {
      var _this3 = this;
      this.listLoading = true;
      var data = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.search), {}, {
        pageNo: this.pageNo,
        pageSize: this.pageSize
      });
      (0, _warehouse.listLocationApi)(data).then(function (data) {
        var res = data.data;
        console.log('res', res);
        if (res.errno == 0) {
          _this3.listLoading = false;
          _this3.newList = res.data.list;
        }
      });
    },
    sure: function sure() {
      var _this4 = this;
      this.$refs.ruleForm.validate(function (valid) {
        if (valid) {
          var aa = _this4.wareHouseList.some(function (item) {
            return item.id == _this4.form.destId;
          });
          if (!aa) {
            _this4.form.destName = _this4.form.destId;
            _this4.form.destId = '';
          }
          var _this4$form = _this4.form,
            submit = _this4$form.submit,
            batchNo = _this4$form.batchNo,
            inoutId = _this4$form.inoutId,
            data = (0, _objectWithoutProperties2.default)(_this4$form, _excluded);
          // const newProductList = this.list.flatMap(item => item.productList.map(product => ({
          //     location: product.locationName,
          //     locationId:product.locationId,
          //     num: product.num,
          //     productId: product.productId
          //   })));
          console.log('this.list', _this4.list);
          var newProductList = _this4.list.map(function (product) {
            return {
              location: product.locationName,
              locationId: product.locationId,
              num: product.num,
              productId: product.productId,
              remove: product.remove ? product.remove : false,
              inoutDetailId: product.inoutDetailId
            };
          });
          // 过滤掉num为0的
          var isNumNewProductList = newProductList.filter(function (item) {
            return item.num != 0;
          });
          if (isNumNewProductList.length == 0) return;
          var params = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, data), {}, {
            productList: isNumNewProductList
          });
          (0, _warehouse.outboundApi)(params).then(function (data) {
            var res = data.data;
            if (res.errno == 0) {
              _this4.dialogVisible = false;
              _this4.$message.success('操作成功');
              _this4.getEmit();
            }
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    onDelete: function onDelete(index) {
      this.list[index].num = 0;
      this.$set(this.list[index], 'remove', true);
      var newList = this.list.filter(function (item) {
        return !item.remove;
      });
      if (newList.length <= 1) {
        this.deleteType = false;
      }
    },
    add: function add(item, index) {
      this.newList.splice(index, 1);
      this.list.unshift((0, _objectSpread2.default)({}, item));
      this.list.length <= 1 ? this.deleteType = false : this.deleteType = true;
    }
  }
};