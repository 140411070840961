"use strict";

var _interopRequireDefault = require("/var/jenkins_home/workspace/sihong_test_front_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.regexp.replace");
var _goods = require("@/api/goods");
var _category = require("@/api/category");
var _BackToTop = _interopRequireDefault(require("@/components/BackToTop"));
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _multiLanguageDisplay = require("@/utils/multiLanguageDisplay");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// Secondary package based on el-pagination
var _default = exports.default = {
  name: 'GoodsList',
  components: {
    BackToTop: _BackToTop.default,
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      activeId: '',
      authStatusList: [{
        id: 1,
        name: '待审核'
      }, {
        id: 2,
        name: '已上架'
      }, {
        id: 3,
        name: '审核不通过'
      }, {
        id: 4,
        name: '已下架'
      }],
      reviewDialogVisible: false,
      reviewStatus: true,
      removeDialogVisible: false,
      infoShow: false,
      reason: '',
      downReason: '',
      info: null,
      url: process.env.VUE_APP_API_URL,
      list: [],
      listLog: [],
      checkAll: [],
      // 选中的列表
      catL1: {},
      multiLanguageDisplay: _multiLanguageDisplay.multiLanguageDisplay,
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 20,
        goodsSn: undefined,
        name: undefined,
        sort: 'add_time',
        order: 'desc',
        enterpriseId: '',
        compositionL2Ids: '',
        supplierStyleNo: '',
        brand: '',
        isOnSale: '',
        categoryIdL1: '',
        hasPic: '',
        channel: undefined,
        authStatus: undefined
      },
      channelList: [{
        id: 0,
        label: '线上'
      }, {
        id: 1,
        label: '线下'
      }],
      compositionL2: [],
      goodsDetail: '',
      detailDialogVisible: false,
      logDialogVisible: false,
      downloadLoading: false,
      // 商品库存
      commonStock: '',
      deteledProducts: [],
      products: [],
      stockDialogVisible: false,
      // 商品库存日志
      logListLoading: false,
      logList: [],
      logTotal: 0,
      logListQuery: {
        goodsId: 0,
        page: 1,
        limit: 20,
        sort: 'id',
        order: 'desc'
      },
      // 修改商品售价
      priceForm: {
        id: null,
        counterPrice: 0,
        currency: 'USD'
      },
      unitOptions: ['USD', 'CNY'],
      priceRules: {
        counterPrice: [{
          required: true,
          message: '市场售价不能为空',
          trigger: 'blur'
        }]
      },
      priceDialogVisible: false,
      listComposition: [],
      listSupplierName: [],
      listBrand: []
    };
  },
  created: function created() {
    var paramsRoute = this.$route.query.saleStatus;
    if (paramsRoute != undefined) {
      console.log('paramsRoute', paramsRoute);
      if (paramsRoute == 'true') this.listQuery.isOnSale = true;else if (paramsRoute == 'false') this.listQuery.isOnSale = false;
    } else {
      this.listQuery.isOnSale = '';
    }
    this.getList();
    this.getSearchList();
    this.getCatL1();
  },
  methods: {
    // 确认上架
    onSureUp: function onSureUp() {
      console.log('11');
      this.setOnsale(2, '', '上架成功');
    },
    setOnsale: function setOnsale(status, reason, successMsg) {
      var _this = this;
      var data = {
        goodsId: this.activeId,
        authStatus: status,
        reason: reason
      };
      (0, _goods.setOnSaleApi)(data).then(function (res) {
        console.log('res8888', res);
        if (res.data.errno == 0) {
          _this.$message.success(successMsg);
          _this.getList();
          _this.reviewDialogVisible = false;
          _this.removeDialogVisible = false;
        }
      });
    },
    transStatus: function transStatus(val) {
      var reslut = '';
      switch (val) {
        case 0:
          reslut = '未上架';
          break;
        case 1:
          reslut = '待审核';
          break;
        case 2:
          reslut = '已上架';
          break;
        case 3:
          reslut = '审核不通过';
          break;
        case 4:
          reslut = '已下架';
          break;
        default:
          break;
      }
      return reslut;
    },
    handelDown: function handelDown(row) {
      this.activeId = row.id;
      this.downReason = '';
      this.removeDialogVisible = true;
    },
    oncancel: function oncancel() {
      this.reviewDialogVisible = false;
    },
    onRefuse: function onRefuse() {
      this.reviewStatus = false;
    },
    onRefuseReason: function onRefuseReason() {
      this.setOnsale(3, this.reason, '操作成功');
    },
    // 下架
    onDownProduct: function onDownProduct() {
      this.setOnsale(4, this.downReason, '下架成功');
    },
    handelReview: function handelReview(row) {
      this.activeId = row.id;
      this.reviewStatus = true;
      this.reviewDialogVisible = true;
      this.reason = '';
    },
    transForm: function transForm(info) {
      if (!info) return '';
      return info.replace(/\n/g, '<br>');
    },
    getDetail: function getDetail(row) {
      this.info = this.transForm(row.detail);
      this.infoShow = true;
    },
    openLog: function openLog(row) {
      var _this2 = this;
      this.logDialogVisible = true;
      this.logListLoading = true;
      var params = {
        goodsSn: row.goodsSn
      };
      // goodsId:1 测试数据
      (0, _goods.goodsLog)(params).then(function (data) {
        var res = data.data;
        console.log(res, 'data');
        if (res.errno == 0) {
          _this2.logListLoading = false;
          // res.data.map(item => item.diffData = JSON.parse(item.diffData))
          _this2.listLog = res.data;
        }
      });
    },
    // 批量打印
    print: function print() {
      if (this.checkAll.length == 0) return this.$message.error('至少勾选一款进行打印');
      var goodsIdList = this.checkAll.map(function (item) {
        return item.id;
      });
      try {
        // printUrl({ goodsIds:goodsIdList.join(',') }).then(res => {
        //   let printData = [res];
        //   let pdfUrl = window.URL.createObjectURL(
        //     new Blob(printData, { type: "application/pdf" })
        //   );
        //   window.open(pdfUrl);
        // })
        var _printUrl = this.url + '/wx/goods/getQrCodePdf?goodsIds=' + goodsIdList.join(',');
        window.open(_printUrl);
      } catch (err) {
        this.$message.error('PDF加载失败');
      }
    },
    // 获取选中商品
    selectAll: function selectAll(val) {
      this.checkAll = JSON.parse(JSON.stringify(val));
    },
    getSearchList: function getSearchList() {
      var _this3 = this;
      Promise.all([(0, _goods.listCompositionUrl)(), (0, _goods.listSupplierNameUrl)(), (0, _goods.listBrandUrl)()]).then(function (res) {
        _this3.listComposition = res[0].data.data.list;
        _this3.listSupplierName = res[1].data.data.list;
        _this3.listBrand = res[2].data.data.list;
      });
    },
    // 获取商品列表
    getList: function getList() {
      var _this4 = this;
      this.listLoading = true;
      this.listQuery.compositionL2Ids = this.compositionL2.join(',');
      (0, _goods.listGoods)(this.listQuery).then(function (response) {
        _this4.list = response.data.data.list;
        _this4.total = response.data.data.total;
        _this4.listLoading = false;
      }).catch(function () {
        _this4.list = [];
        _this4.total = 0;
        _this4.listLoading = false;
      });
    },
    // 展示商品详情
    showDetail: function showDetail(detail) {
      this.goodsDetail = detail;
      this.detailDialogVisible = true;
    },
    // 商品库存：显示商品库存弹窗
    showStock: function showStock(id) {
      var _this5 = this;
      this.commonStock = '';
      this.deteledProducts = [];
      (0, _goods.detailGoods)(id).then(function (response) {
        _this5.products = response.data.data.products;
      });
      this.logListQuery.goodsId = id;
      this.getLogList();
      this.stockDialogVisible = true;
    },
    // 商品库存：同步库存
    handleSynStock: function handleSynStock() {
      var self = this;
      this.products.forEach(function (item, index, array) {
        item.number = self.commonStock;
      });
    },
    // 商品库存：删除某库存
    handleDeleteStock: function handleDeleteStock(row) {
      var index = this.products.indexOf(row);
      this.products.splice(index, 1);
      this.deteledProducts.push(row);
    },
    // 商品库存：保存
    handleUpdateStock: function handleUpdateStock() {
      var _this6 = this;
      if (this.products.length === 0) {
        this.$message.error('商品库存不能为空');
        return false;
      }
      var productvar = [];
      this.products.forEach(function (item, index, array) {
        productvar.push({
          id: item.id,
          storeCount: item.number,
          deleted: item.deleted
        });
      });
      this.deteledProducts.forEach(function (item, index, array) {
        productvar.push({
          id: item.id,
          storeCount: item.number,
          deleted: true
        });
      });
      (0, _goods.editProducts)({
        productList: productvar
      }).then(function (response) {
        _this6.$notify.success({
          title: '成功',
          message: '操作成功'
        });
        _this6.stockDialogVisible = false;
      }).catch(function (response) {
        _this6.$notify.error({
          title: '失败',
          message: response.data.errmsg
        });
      });
    },
    // 获取库存操作日志
    getLogList: function getLogList() {
      var _this7 = this;
      this.logListLoading = true;
      (0, _goods.listStockLog)(this.logListQuery).then(function (response) {
        _this7.logList = response.data.data.list;
        _this7.logTotal = response.data.data.total;
        _this7.logListLoading = false;
      }).catch(function () {
        _this7.list = [];
        _this7.total = 0;
        _this7.logListLoading = false;
      });
    },
    // 修改商品售价:弹窗
    handleUpdateCounterPrice: function handleUpdateCounterPrice(row) {
      var _this8 = this;
      this.priceForm = Object.assign({}, row);
      this.priceDialogVisible = true;
      this.$nextTick(function () {
        _this8.$refs['priceForm'].clearValidate();
      });
    },
    // 修改商品售价
    updatePrice: function updatePrice() {
      var _this9 = this;
      this.$refs['priceForm'].validate(function (valid) {
        if (valid) {
          (0, _goods.editGoodsPrice)({
            goodsId: _this9.priceForm.id,
            counterPrice: _this9.priceForm.counterPrice,
            currency: _this9.priceForm.currency
          }).then(function () {
            _this9.getList();
            _this9.priceDialogVisible = false;
            _this9.$notify.success({
              title: '成功',
              message: '更新成功'
            });
          }).catch(function (response) {
            _this9.$notify.error({
              title: '失败',
              message: response.data.errmsg
            });
          });
        }
      });
    },
    // 修改商品状态
    handleProductStateChange: function handleProductStateChange(row) {
      var _this10 = this;
      (0, _goods.editGoodsStatus)({
        goodsId: row.id,
        saleState: row.onSale,
        newState: row.new,
        hotState: row.hot
      }).then(function (response) {
        console.log('response', response);
        _this10.$notify.success({
          title: '成功',
          message: '操作成功'
        });
        _this10.stockDialogVisible = false;
      }).catch(function (response) {
        _this10.$notify.error({
          title: '失败',
          message: response.data.errmsg
        });
        _this10.getList();
      });
    },
    // 查询
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    // 新增商品
    handleCreate: function handleCreate() {
      this.$router.push({
        path: '/goods/create'
      });
    },
    // 修改商品
    handleUpdate: function handleUpdate(row) {
      this.$router.push({
        path: '/goods/edit',
        query: {
          id: row.id
        }
      });
    },
    // 点击查看
    toDetail: function toDetail() {
      this.reviewDialogVisible = false;
      this.handleUpdate({
        id: this.activeId
      });
    },
    // 删除商品
    handleDelete: function handleDelete(row) {
      var _this11 = this;
      this.$confirm('是否要进行删除操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _goods.deleteGoods)(row).then(function (response) {
          _this11.$notify.success({
            title: '成功',
            message: '删除成功'
          });
          var index = _this11.list.indexOf(row);
          _this11.list.splice(index, 1);
        }).catch(function (response) {
          _this11.$notify.error({
            title: '失败',
            message: response.data.errmsg
          });
        });
      });
    },
    // 导出
    handleDownload: function handleDownload() {
      if (this.checkAll.length == 0) return this.$message.error('至少勾选一款进行输出');
      var goodsIdList = this.checkAll.map(function (item) {
        return item.id;
      });
      try {
        var _printUrl2 = this.url + '/admin/goods/exportGoods?ids=' + goodsIdList.join(',');
        window.open(_printUrl2);
      } catch (err) {
        this.$message.error('Excel加载失败');
      }
      // this.downloadLoading = true
      // import('@/vendor/Export2Excel').then(excel => {
      //   const tHeader = [
      //     '商品ID',
      //     '商品编号',
      //     '名称',
      //     '专柜价格',
      //     '当前价格',
      //     '是否新品',
      //     '是否热品',
      //     '是否在售',
      //     '首页主图',
      //     '宣传图片列表',
      //     '商品介绍',
      //     '详细介绍',
      //     '商品图片',
      //     '商品单位',
      //     '关键字',
      //     '类目ID',
      //     '品牌商ID'
      //   ]
      //   const filterVal = [
      //     'id',
      //     'goodsSn',
      //     'name',
      //     'counterPrice',
      //     'retailPrice',
      //     'new',
      //     'hot',
      //     'onSale',
      //     'listPicUrl',
      //     'gallery',
      //     'brief',
      //     'detail',
      //     'picUrl',
      //     'goodsUnit',
      //     'keywords',
      //     'categoryId',
      //     'brandId'
      //   ]
      //   excel.export_json_to_excel2(tHeader, listExcel, filterVal, '商品信息')
      //   this.downloadLoading = false
      // })
    },
    getRowKeys: function getRowKeys(row) {
      return row.id;
    },
    getCatL1: function getCatL1() {
      var _this12 = this;
      (0, _category.listCatL1)().then(function (response) {
        _this12.catL1 = response.data.data.list;
      });
    }
  }
};