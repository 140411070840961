var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.visible,
        "show-close": false,
        width: _vm.width,
        "close-on-click-modal": false,
        "destroy-on-close": true,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function () {
            return [
              _c("div", { staticClass: "title2" }, [
                _c("span", [_vm._v(_vm._s(_vm.title))]),
                _vm._v(" "),
                _c("i", {
                  staticClass: "el-icon-close",
                  on: { click: _vm.cancel2 },
                }),
              ]),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _c("div", [_vm._t("default")], 2),
      _vm._v(" "),
      _vm._t("footer", [
        _c(
          "div",
          { staticClass: "footer", attrs: { slot: "footer" }, slot: "footer" },
          [
            !_vm.showCancel
              ? _c(
                  "el-button",
                  {
                    staticClass: "btn",
                    attrs: { type: "primary", loading: _vm.loading },
                    on: { click: _vm.confirm },
                  },
                  [_vm._v(_vm._s(_vm.confirmText))]
                )
              : _c(
                  "div",
                  { staticClass: "cont" },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "cancel-left",
                        attrs: { disabled: _vm.loading },
                        on: { click: _vm.cancel },
                      },
                      [_vm._v(_vm._s(_vm.cancelText || "取消") + " ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        staticClass: "submit-right",
                        attrs: { type: "primary", loading: _vm.loading },
                        on: { click: _vm.confirm },
                      },
                      [_vm._v(_vm._s(_vm.confirmText) + "\n        ")]
                    ),
                  ],
                  1
                ),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }