"use strict";

var _interopRequireDefault = require("/var/jenkins_home/workspace/sihong_test_front_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.listLog = listLog;
var _request = _interopRequireDefault(require("@/utils/request"));
function listLog(query) {
  return (0, _request.default)({
    url: '/log/list',
    method: 'get',
    params: query
  });
}