"use strict";

var _interopRequireDefault = require("/var/jenkins_home/workspace/sihong_test_front_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.array.sort");
require("core-js/modules/es6.function.name");
var _selected = require("@/api/selected");
var _goods = require("@/api/goods");
var _storage = require("@/api/storage");
var _auth = require("@/utils/auth");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _elementUi = require("element-ui");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'MarketTemplateOne',
  components: {
    Pagination: _Pagination.default
  },
  directives: {
    focus: {
      inserted: function inserted(el) {
        el.getElementsByTagName('input')[0].focus();
      }
    }
  },
  data: function data() {
    return {
      // 图片上传路径
      uploadPath: _storage.uploadPath,
      // 商品基本信息
      dataForm: {
        id: undefined,
        name: null,
        role: 1,
        user: null,
        picUrl: null
      },
      // 字段验证
      rules: {
        name: [{
          required: true,
          message: '选集名称不能为空',
          trigger: 'blur'
        }],
        picUrl: [{
          required: true,
          message: '选集图片不能为空',
          trigger: 'blur'
        }]
      },
      status: '',
      loading: false,
      // 商品列表
      listLoading: false,
      list: [],
      total: 0,
      listQuery: {
        userId: undefined,
        page: 1,
        limit: 20,
        sort: 'id',
        order: 'desc'
      },
      // 选择商品
      categoryList: [],
      selectDialogVisiable: false,
      selectLoading: false,
      selectList: [],
      selectTotal: 0,
      selectQuery: {
        page: 1,
        limit: 20,
        sort: 'add_time',
        order: 'desc',
        goodsName: '',
        categoryId: null
      },
      idKey: 'id',
      multipleSelection: [],
      multipleSelectionAll: [],
      multipleSelectionAllConstStr: '[]'
    };
  },
  computed: {
    // 头部信息
    headers: function headers() {
      return {
        Authorization: (0, _auth.getToken)()
      };
    }
  },
  created: function created() {
    this.dataForm.id = this.$route.query.id;
    if (this.dataForm.id === undefined) {
      this.status = 'create';
    } else {
      var type = this.$route.query.type;
      if (type === '0') this.status = 'update';else this.status = 'look';
      this.selectedDetail();
      this.selectedGoodsList();
    }
  },
  methods: {
    // 操作：取消、保存、获取模板详情
    handleCancel: function handleCancel() {
      this.$router.push({
        path: '/select/anthology'
      });
    },
    handleSave: function handleSave() {
      var _this = this;
      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          if (_this.status === 'create') {
            (0, _selected.createSelected)({
              role: _this.dataForm.role,
              name: _this.dataForm.name,
              shareName: _this.dataForm.shareName,
              picUrl: _this.dataForm.picUrl
            }).then(function (response) {
              _this.$notify.success({
                title: '成功',
                message: '创建成功'
              });
              _this.$router.push({
                path: '/select/anthology'
              });
            }).catch(function (response) {
              _elementUi.MessageBox.alert('业务错误：' + response.errmsg, '警告', {
                confirmButtonText: '确定',
                type: 'error'
              });
            });
          } else if (_this.status === 'update') {
            (0, _selected.updateSelected)({
              selectedId: _this.dataForm.id,
              role: _this.dataForm.role,
              name: _this.dataForm.name,
              shareName: _this.dataForm.shareName,
              picUrl: _this.dataForm.picUrl
            }).then(function (response) {
              _this.$notify.success({
                title: '成功',
                message: '编辑成功'
              });
              _this.$router.push({
                path: '/select/anthology'
              });
            }).catch(function (response) {
              _elementUi.MessageBox.alert('业务错误：' + response.errmsg, '警告', {
                confirmButtonText: '确定',
                type: 'error'
              });
            });
          }
        }
      });
    },
    // 商品图片：上传成功回调函数
    uploadPicUrl: function uploadPicUrl(response) {
      this.dataForm.picUrl = response.data.url;
    },
    // 获取选集信息、选集商品列表
    selectedDetail: function selectedDetail() {
      var _this2 = this;
      (0, _selected.selectedDetail)({
        selectedId: this.dataForm.id
      }).then(function (response) {
        _this2.dataForm = response.data.data;
      }).catch(function (response) {});
    },
    selectedGoodsList: function selectedGoodsList() {
      var _this3 = this;
      this.listLoading = true;
      (0, _selected.selectedGoodsList)({
        page: this.listQuery.page,
        limit: this.listQuery.limit,
        // sort: this.listQuery.sort,
        // order: this.listQuery.order,
        selectedId: this.dataForm.id
      }).then(function (response) {
        _this3.list = response.data.data.list;
        _this3.total = response.data.data.total;
        _this3.listLoading = false;
      }).catch(function (response) {
        _this3.total = [];
        _this3.total = 0;
        _this3.listLoading = false;
      });
    },
    // 删除选集商品
    handleRemove: function handleRemove(row) {
      var _this4 = this;
      this.$confirm('是否要删除该商品?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _selected.selectedGoodsDelete)({
          selectedGoodsId: row.selectedGoodsId
        }).then(function (res) {
          _this4.selectedGoodsList();
          _this4.$notify.success({
            title: '成功',
            message: '操作成功'
          });
        }).catch(function (response) {
          _this4.$notify.error({
            title: '失败',
            message: response.errmsg
          });
        });
      });
    },
    // 排序：输入框失去焦点
    loseFcous: function loseFcous(index, row) {
      var _this5 = this;
      // index = this.list.indexOf(row)
      // row.seen = false
      // this.$set(this.list, index, row)
      (0, _selected.selectedGoodsSort)({
        selectedGoodsId: row.selectedGoodsId,
        order: row.order
      }).then(function (response) {
        _this5.selectedGoodsList();
      });
    },
    //  排序：点击事件
    cellClick: function cellClick(index, row) {
      index = this.list.indexOf(row);
      row.seen = true;
      this.$set(this.list, index, row);
    },
    // 选择商品弹窗
    selectGoods: function selectGoods() {
      var that = this;
      that.selectQuery.page = 1;
      that.selectList = [];
      that.selectTotal = 0;
      that.selectDialogVisiable = true;
      that.getSelectList();
      that.getCategoryList();
    },
    // 获取商品分类
    getCategoryList: function getCategoryList() {
      var _this6 = this;
      (0, _goods.listCatAndBrand)().then(function (response) {
        _this6.categoryList = response.data.data.categoryList;
      });
    },
    // 监听商品分类选择
    handleCategoryChange: function handleCategoryChange(value) {
      this.selectQuery.categoryId = value[value.length - 1];
    },
    // 选择商品：查询
    handleFilterSelect: function handleFilterSelect() {
      var that = this;
      // 改变页的时候调用一次
      that.changePageCoreRecordData().then(function (val) {
        that.selectQuery.page = 1;
        that.getSelectList();
      });
    },
    // 用户列表页码、页数改变
    handlePaginChange: function handlePaginChange() {
      var that = this;
      // 改变页的时候调用一次
      that.changePageCoreRecordData().then(function (val) {
        that.getSelectList();
      });
    },
    // 选择商品：获取导入新商品列表
    getSelectList: function getSelectList() {
      var _this7 = this;
      this.selectLoading = true;
      (0, _selected.goodsSelectedList)({
        page: this.selectQuery.page,
        limit: this.selectQuery.limit,
        sort: this.selectQuery.sort,
        order: this.selectQuery.order,
        goodsName: this.selectQuery.goodsName,
        categoryId: this.selectQuery.categoryId,
        selectedId: this.dataForm.id
      }).then(function (response) {
        _this7.selectList = response.data.data.list;
        _this7.selectTotal = response.data.data.total;
        _this7.selectLoading = false;
        setTimeout(function () {
          _this7.setSelectRow();
        }, 200);
      }).catch(function (response) {
        _this7.selectList = [];
        _this7.selectTotal = 0;
        _this7.selectLoading = false;
      });
    },
    // 多选框是否可用
    isDisabled: function isDisabled(row, index) {
      if (row.selected) {
        return 0;
      } else {
        return 1;
      }
    },
    // 选择商品：多选改变事件
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 选择商品：取消选择
    handleCancelSelect: function handleCancelSelect() {
      this.multipleSelectionAll = [];
      this.multipleSelectionAllConstStr = '[]';
      this.selectDialogVisiable = false;
    },
    // 选择商品：确定选择
    handleSubmitSelect: function handleSubmitSelect() {
      var _this8 = this;
      // 改变页的时候调用一次
      var that = this;
      this.changePageCoreRecordData().then(function (val) {
        that.multipleSelection = [];
        that.multipleSelectionAllConstStr = JSON.stringify(that.multipleSelectionAll);
        if (that.multipleSelectionAll.length === 0) {
          _this8.$message.error('请选择商品');
          return;
        }
        var goodsList = [];
        that.multipleSelectionAll.forEach(function (item, index, array) {
          goodsList.push(item.id);
        });
        // 接口返回成功后清空已选列表、关闭弹窗
        (0, _selected.selectedGoodsAdd)({
          goodsList: goodsList,
          selectedId: that.dataForm.id
        }).then(function (response) {
          that.multipleSelectionAll = [];
          that.multipleSelectionAllConstStr = '[]';
          that.selectDialogVisiable = false;
          that.selectedGoodsList();
        }).catch(function (response) {});
      });
    },
    setSelectRow: function setSelectRow() {
      if (!this.multipleSelectionAll || this.multipleSelectionAll.length <= 0) {
        return;
      }
      var idKey = this.idKey;
      var selectAllIds = [];
      this.multipleSelectionAll.forEach(function (row) {
        selectAllIds.push(row[idKey]);
      });
      this.$refs.multipleTable.clearSelection();
      for (var i = 0; i < this.selectList.length; i++) {
        if (selectAllIds.indexOf(this.selectList[i][idKey]) >= 0) {
          this.$refs.multipleTable.toggleRowSelection(this.selectList[i], true);
        }
      }
    },
    changePageCoreRecordData: function changePageCoreRecordData() {
      var _this9 = this;
      return new Promise(function (resolve, reject) {
        var idKey = _this9.idKey;
        if (_this9.multipleSelectionAll.length <= 0) {
          var selectGoodIds = [];
          _this9.multipleSelection.forEach(function (row) {
            selectGoodIds.push({
              id: row[idKey]
            });
          });
          _this9.multipleSelectionAll = selectGoodIds;
          resolve();
          return;
        }
        var selectAllIds = [];
        _this9.multipleSelectionAll.forEach(function (row) {
          selectAllIds.push(row[idKey]);
        });
        var selectIds = [];
        _this9.multipleSelection.forEach(function (row) {
          selectIds.push(row[idKey]);
          if (selectAllIds.indexOf(row[idKey]) < 0) {
            _this9.multipleSelectionAll.push({
              id: row[idKey]
            });
          }
        });
        var noSelectIds = [];
        _this9.selectList.forEach(function (row) {
          if (selectIds.indexOf(row[idKey]) < 0) {
            noSelectIds.push(row[idKey]);
          }
        });
        noSelectIds.forEach(function (id) {
          if (selectAllIds.indexOf(id) >= 0) {
            for (var i = 0; i < _this9.multipleSelectionAll.length; i++) {
              if (_this9.multipleSelectionAll[i][idKey] === id) {
                _this9.multipleSelectionAll.splice(i, 1);
                break;
              }
            }
          }
        });
        resolve();
      });
    }
  }
};