"use strict";

var _interopRequireDefault = require("/var/jenkins_home/workspace/sihong_test_front_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getUserInfo = getUserInfo;
exports.loginByUsername = loginByUsername;
exports.logout = logout;
var _request = _interopRequireDefault(require("@/utils/request"));
function loginByUsername(username, password) {
  var data = {
    username: username,
    password: password
  };
  return (0, _request.default)({
    url: '/auth/login',
    method: 'post',
    data: data
  });
}
function logout() {
  return (0, _request.default)({
    url: '/auth/logout',
    method: 'post'
  });
}
function getUserInfo(token) {
  return (0, _request.default)({
    url: '/auth/info',
    method: 'get',
    params: {
      token: token
    }
  });
}