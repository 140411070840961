"use strict";

var _interopRequireDefault = require("/var/jenkins_home/workspace/sihong_test_front_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.del = del;
exports.getList = getList;
exports.getListNo = getListNo;
exports.save = save;
exports.update = update;
var _request = _interopRequireDefault(require("@/utils/request"));
/*
 * @Author: 张月平 sam.zhang@neo-digitex.com
 * @Date: 2024-09-24 16:56:12
 * @LastEditors: 张月平 sam.zhang@neo-digitex.com
 * @LastEditTime: 2024-09-25 12:17:12
 * @FilePath: \webd:\shong\frontend\src\api\EmailEnv.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

/**
 * 获取邮件模板列表
 * @param {*} data
 * @returns
 */
function getList(params) {
  return (0, _request.default)({
    url: '/emailScene/pageOfList',
    method: 'get',
    params: params
  });
}
function getListNo(params) {
  return (0, _request.default)({
    url: '/emailScene/list',
    method: 'get',
    params: params
  });
}
/**
 * 保存邮件模板
 * @param {*} data
 * @returns
 */
function save(data) {
  return (0, _request.default)({
    url: '/emailScene/save',
    method: 'post',
    data: data
  });
}
/**
 * 删除邮件模板
 * @param {*} params
 * @returns
 */
function del(params) {
  return (0, _request.default)({
    url: '/emailScene/delete',
    method: 'get',
    params: params
  });
}
/**
 * 获取应用场景列表
 * @param {*} data
 * @returns
 */
function update(data) {
  return (0, _request.default)({
    url: '/emailScene/update',
    method: 'post',
    data: data
  });
}