"use strict";

var _interopRequireDefault = require("/var/jenkins_home/workspace/sihong_test_front_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.auditOrderReviewRecord = auditOrderReviewRecord;
exports.deleteOrder = deleteOrder;
exports.detailOrder = detailOrder;
exports.getOrderReviewRecord = getOrderReviewRecord;
exports.listChannel = listChannel;
exports.listOrder = listOrder;
exports.listShipGoods = listShipGoods;
exports.refundOrder = refundOrder;
exports.replyComment = replyComment;
exports.shipOrder = shipOrder;
exports.updateOrderAddress = updateOrderAddress;
exports.updateOrderPrice = updateOrderPrice;
var _request = _interopRequireDefault(require("@/utils/request"));
var _qs = _interopRequireDefault(require("qs"));
/*
 * @Author: 张月平 sam.zhang@neo-digitex.com
 * @Date: 2024-07-08 16:38:13
 * @LastEditors: 张月平 sam.zhang@neo-digitex.com
 * @LastEditTime: 2024-11-04 15:28:01
 * @FilePath: \frontend\src\api\order.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

function listOrder(query) {
  return (0, _request.default)({
    url: '/order/list',
    method: 'get',
    params: query,
    paramsSerializer: function paramsSerializer(params) {
      return _qs.default.stringify(params, {
        arrayFormat: 'repeat'
      });
    }
  });
}
function detailOrder(id) {
  return (0, _request.default)({
    url: '/order/detail',
    method: 'get',
    params: {
      id: id
    }
  });
}
function shipOrder(data) {
  return (0, _request.default)({
    url: '/order/ship',
    method: 'post',
    data: data
  });
}
function refundOrder(data) {
  return (0, _request.default)({
    url: '/order/refund',
    method: 'post',
    data: data
  });
}
function deleteOrder(data) {
  return (0, _request.default)({
    url: '/order/delete',
    method: 'post',
    data: data
  });
}
function replyComment(data) {
  return (0, _request.default)({
    url: '/order/reply',
    method: 'post',
    data: data
  });
}
function listChannel(id) {
  return (0, _request.default)({
    url: '/order/channel',
    method: 'get'
  });
}
function listShipGoods(data) {
  return (0, _request.default)({
    url: '/order/ship/goods/list',
    method: 'post',
    data: data
  });
}
function updateOrderPrice(data) {
  return (0, _request.default)({
    url: '/order/price/update',
    method: 'post',
    data: data
  });
}
function updateOrderAddress(data) {
  return (0, _request.default)({
    url: '/order/address/update',
    method: 'post',
    data: data
  });
}

// 查询情况
function getOrderReviewRecord(params) {
  return (0, _request.default)({
    url: '/order/getOrderReviewRecord',
    method: 'get',
    params: params
  });
}

// 审核

function auditOrderReviewRecord(data) {
  return (0, _request.default)({
    url: '/order/auditOrderReviewRecord',
    method: 'post',
    data: data
  });
}