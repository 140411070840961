"use strict";

var _interopRequireDefault = require("/var/jenkins_home/workspace/sihong_test_front_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("/var/jenkins_home/workspace/sihong_test_front_mall/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray.js"));
require("core-js/modules/es6.array.find");
require("core-js/modules/web.dom.iterable");
var _objectSpread2 = _interopRequireDefault(require("/var/jenkins_home/workspace/sihong_test_front_mall/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.number.constructor");
var _MultiLanguageInp = _interopRequireDefault(require("@/components/MultiLanguageInp"));
var _multiLanguageDisplay = require("@/utils/multiLanguageDisplay");
var _sortablejs = _interopRequireDefault(require("sortablejs"));
var _trends = require("@/api/cont/trends");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  name: 'DraggableTable',
  components: {
    MultiLanguageInp: _MultiLanguageInp.default
  },
  props: {
    tableData: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    className: {
      type: String,
      default: ''
    },
    pid: {
      type: String | Number,
      default: ''
    },
    type: {
      type: String | Number,
      default: ''
    }
  },
  watch: {
    tableData: {
      handler: function handler(arr) {
        this.data = JSON.parse(JSON.stringify(arr));
        console.log(this.data, 'dara132');
      },
      immediate: true
    }
  },
  data: function data() {
    return {
      multiLanguageDisplay: _multiLanguageDisplay.multiLanguageDisplay,
      oldIndex: null,
      newIndex: null,
      data: [],
      dialogVisible: false,
      selectRow: '',
      buttonLoading: false
    };
  },
  mounted: function mounted() {
    this.init();
  },
  methods: {
    // 修改
    handleUpdate: function handleUpdate(row) {
      this.$set(row, 'isEdit', false);
      row.isEdit = true;
    },
    handleDelete: function handleDelete(row, $index) {
      if (row.id) {
        this.selectRow = row.id;
        this.dialogVisible = true;
      } else {
        this.data.splice($index, 1);
      }
    },
    cancel: function cancel() {
      this.dialogVisible = false;
    },
    submit: function submit() {
      var _this2 = this;
      this.buttonLoading = true;
      (0, _trends.tagDelete)({
        id: this.selectRow,
        type: this.type
      }).then(function (res) {
        _this2.buttonLoading = false;
        _this2.dialogVisible = false;
        _this2.$emit('updateData', _this2.selectRow);
        _this2.$message.success('删除成功');
      }, function () {
        _this2.buttonLoading = false;
      });
    },
    handleAdd: function handleAdd(row, index) {
      var _this3 = this;
      (0, _trends.tagCreate)({
        name: row.name,
        priority: index,
        pid: this.pid,
        type: this.type,
        id: row.id ? row.id : ''
      }).then(function (res) {
        _this3.$set(row, 'isEdit', true);
        row.id = res.data.id;
        row.isEdit = false;
        var data = row.childList ? (0, _objectSpread2.default)((0, _objectSpread2.default)({}, res.data), {}, {
          childList: row.childList
        }) : res.data;
        _this3.$emit('pushChildren', data, Boolean(_this3.pid));
      });
    },
    handlePush: function handlePush() {
      this.data.push({
        name: '',
        isEdit: true
      });
    },
    init: function init() {
      // 1. 获取拖拽元素的父元素
      // 因为使用的element的table 所以可直接获取tbody
      var el = document.querySelector(".".concat(this.className, " .el-table__body-wrapper tbody"));
      var _this = this;
      // 2. 创建并配置相关参数
      var sortable = new _sortablejs.default(el, {
        // 此处进行配置 及 回调函数的使用
        handle: '.handle_move',
        // css选择器的字符串 若设置该值，则表示只有按住拖动手柄才能使列表单元进行拖动
        draggable: '.el-table__row',
        // 允许拖拽的项目类名
        // 因为拖拽过程中不尽兴位置调整，所以就要记录拖拽行初始位置以及目标位置
        // 拖拽中 回调函数
        onMove: function onMove(customEvent) {
          // 禁止在拖拽过程中交换位置
          // 可将初始位置及目标位置进行记录
          _this.oldIndex = customEvent.dragged.rowIndex; //  初始位置
          _this.newIndex = customEvent.related.rowIndex; // 目标位置
          console.log(_this.oldIndex, _this.newIndex, 's');
          return false;
        },
        // 拖拽结束，调整位置
        onEnd: function onEnd() {
          // 在拖拽结束后，获取初始及目标位置
          var newIndex = _this.newIndex,
            oldIndex = _this.oldIndex;
          var currRow = _this.data.splice(oldIndex, 1)[0];
          _this.data.splice(newIndex, 0, currRow);
          // 拖拽完成，初始位置及目标位置重置
          _this.newIndex = null;
          _this.oldIndex = null;
          var data = _this.data.map(function (item) {
            return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, item), {}, {
              type: _this.type,
              pid: _this.pid
            });
          });
          (0, _trends.updateOrSaveBatch)(data).then(function (res) {
            res.data.forEach(function (item) {
              item.isEdit = false;
            });
            // 遍历 this.data
            _this.data = _this.data.map(function (item) {
              // 找到 res.data 中对应的项
              var matchedItem = res.data.find(function (resItem) {
                return resItem.id === item.id;
              });
              if (matchedItem) {
                // 如果匹配成功，将 res.data 的数据更新到 this.data 中，并保留 childList
                return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, matchedItem), {}, {
                  childList: item.childList || [] // 保留原有的 childList
                });
              }
              // 如果没有匹配项，保留原数据
              return item;
            });
            // 遍历 res.data 中没有在 this.data 中的项，追加到 this.data
            var newItems = res.data.filter(function (resItem) {
              return !_this.data.some(function (item) {
                return item.id === resItem.id;
              });
            });
            _this.data = [].concat((0, _toConsumableArray2.default)(_this.data), (0, _toConsumableArray2.default)(newItems));
            _this.$emit('pushChildren', _this.data, Boolean(_this.pid));
          });
        }
      });
    },
    status: function status() {
      switch (+this.type) {
        case 1:
          return '潮流';
        case 2:
          return '时装秀';
        case 3:
          return '系列';
        default:
          return "-";
      }
    }
  }
};