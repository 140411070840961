"use strict";

var _interopRequireDefault = require("/var/jenkins_home/workspace/sihong_test_front_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createKeyword = createKeyword;
exports.deleteKeyword = deleteKeyword;
exports.listKeyword = listKeyword;
exports.readKeyword = readKeyword;
exports.updateKeyword = updateKeyword;
var _request = _interopRequireDefault(require("@/utils/request"));
function listKeyword(query) {
  return (0, _request.default)({
    url: '/keyword/list',
    method: 'get',
    params: query
  });
}
function createKeyword(data) {
  return (0, _request.default)({
    url: '/keyword/create',
    method: 'post',
    data: data
  });
}
function readKeyword(data) {
  return (0, _request.default)({
    url: '/keyword/read',
    method: 'get',
    data: data
  });
}
function updateKeyword(data) {
  return (0, _request.default)({
    url: '/keyword/update',
    method: 'post',
    data: data
  });
}
function deleteKeyword(data) {
  return (0, _request.default)({
    url: '/keyword/delete',
    method: 'post',
    data: data
  });
}