"use strict";

var _interopRequireDefault = require("/var/jenkins_home/workspace/sihong_test_front_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
var _objectSpread2 = _interopRequireDefault(require("/var/jenkins_home/workspace/sihong_test_front_mall/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _router = _interopRequireDefault(require("./router"));
var _store = _interopRequireDefault(require("./store"));
var _elementUi = require("element-ui");
var _nprogress = _interopRequireDefault(require("nprogress"));
require("nprogress/nprogress.css");
var _auth = require("@/utils/auth");
// progress bar
// progress bar style
// getToken from cookie

_nprogress.default.configure({
  showSpinner: false
}); // NProgress Configuration

// permission judge function
function hasPermission(perms, permissions) {
  if (perms.indexOf('*') >= 0) return true; // admin permission passed directly
  if (!permissions) return true;
  return perms.some(function (perm) {
    return permissions.indexOf(perm) >= 0;
  });
}
var whiteList = ['/login', '/auth-redirect']; // no redirect whitelist

_router.default.beforeEach(function (to, from, next) {
  _nprogress.default.start(); // start progress bar
  if ((0, _auth.getToken)()) {
    // determine if there has token
    /* has token*/
    if (to.path === '/login') {
      next({
        path: '/'
      });
      _nprogress.default.done(); // if current page is dashboard will not trigger	afterEach hook, so manually handle it
    } else {
      if (_store.default.getters.perms.length === 0) {
        // 判断当前用户是否已拉取完user_info信息
        _store.default.dispatch('GetUserInfo').then(function (res) {
          // 拉取user_info
          var perms = res.data.data.perms; // note: perms must be a array! such as: ['GET /aaa','POST /bbb']
          _store.default.dispatch('GenerateRoutes', {
            perms: perms
          }).then(function () {
            // 根据perms权限生成可访问的路由表
            _router.default.addRoutes(_store.default.getters.addRouters); // 动态添加可访问路由表
            next((0, _objectSpread2.default)((0, _objectSpread2.default)({}, to), {}, {
              replace: true
            })); // hack方法 确保addRoutes已完成 ,set the replace: true so the navigation will not leave a history record
          });
        }).catch(function (err) {
          _store.default.dispatch('FedLogOut').then(function () {
            if (err !== 'error') {
              _elementUi.Message.error(err || 'Verification failed, please login again');
            }
            next({
              path: '/'
            });
          });
        });
      } else {
        // 没有动态改变权限的需求可直接next() 删除下方权限判断 ↓
        if (hasPermission(_store.default.getters.perms, to.meta.perms)) {
          next();
        } else {
          next({
            path: '/401',
            replace: true,
            query: {
              noGoBack: true
            }
          });
        }
        // 可删 ↑
      }
    }
  } else {
    /* has no token*/
    if (whiteList.indexOf(to.path) !== -1) {
      // 在免登录白名单，直接进入
      next();
    } else {
      next({
        path: '/login',
        query: (0, _objectSpread2.default)({
          redirect: to.path
        }, to.query)
      }); // 否则全部重定向到登录页
      _nprogress.default.done(); // if current page is login will not trigger afterEach hook, so manually handle it
    }
  }
});
_router.default.afterEach(function () {
  _nprogress.default.done(); // finish progress bar
});