"use strict";

var _interopRequireDefault = require("/var/jenkins_home/workspace/sihong_test_front_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectWithoutProperties2 = _interopRequireDefault(require("/var/jenkins_home/workspace/sihong_test_front_mall/node_modules/@babel/runtime-corejs2/helpers/objectWithoutProperties.js"));
require("core-js/modules/es6.function.name");
var _objectSpread2 = _interopRequireDefault(require("/var/jenkins_home/workspace/sihong_test_front_mall/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/es6.array.find");
require("core-js/modules/web.dom.iterable");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _editDictionary = _interopRequireDefault(require("./editDictionary.vue"));
var _dictionary = require("@/api/dictionary");
var _category = require("@/api/category");
var _multiLanguageDisplay = require("@/utils/multiLanguageDisplay");
var _excluded = ["Id2List", "Id3List"]; //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'MallOrderType',
  components: {
    Pagination: _Pagination.default,
    editDictionary: _editDictionary.default
  },
  data: function data() {
    return {
      listLoading: false,
      dialogVisible: false,
      multiLanguageDisplay: _multiLanguageDisplay.multiLanguageDisplay,
      type: 1,
      total: 0,
      currentId: '',
      listQuery: {
        dictName: ''
      },
      categoryList: [],
      Id1List: [],
      list: [],
      name: ''
    };
  },
  mounted: function mounted() {
    this.getList(this.type);
    this.getCategory();
  },
  methods: {
    // 获取类目
    getCategory: function getCategory() {
      var _this = this;
      (0, _category.listCategory)().then(function (res) {
        if (res.data.errno == 0) {
          _this.Id1List = res.data.data.list;
        }
      });
    },
    // 类目回显
    categoryShow: function categoryShow() {
      var _this2 = this;
      this.categoryList.forEach(function (item) {
        if (item.category1Id) {
          var selectedCategory = _this2.Id1List.find(function (citem) {
            return citem.id == item.category1Id;
          });
          item.Id2List = selectedCategory.children || [];
        }
        if (item.category2Id) {
          var _selectedCategory = item.Id2List.find(function (citem) {
            return citem.id == item.category2Id;
          });
          item.Id3List = _selectedCategory.children || [];
        } else {
          item.category2Id = null;
        }
        if (!item.category3Id) {
          item.category3Id = null;
        }
      });
    },
    onCategory1: function onCategory1(val, index) {
      var selectedCategory = this.Id1List.find(function (item) {
        return item.id == val;
      });
      if (selectedCategory) {
        this.categoryList[index].Id2List = selectedCategory.children || [];
        this.categoryList[index].category2Id = null;
        this.categoryList[index].Id3List = [];
        this.categoryList[index].category3Id = null;
      }
    },
    onCategory2: function onCategory2(val, index) {
      var selectedCategory = this.categoryList[index].Id2List.find(function (item) {
        return item.id == val;
      });
      if (selectedCategory) {
        this.categoryList[index].Id3List = selectedCategory.children || [];
        this.categoryList[index].category3Id = null;
      }
    },
    onUpdate: function onUpdate() {
      this.getList();
    },
    onChange: function onChange(val) {
      this.type = val;
      this.getList();
    },
    getList: function getList() {
      var _this3 = this;
      var params = {
        type: this.type,
        dictName: this.listQuery.dictName
      };
      (0, _dictionary.listApi)(params).then(function (res) {
        if (res.data.errno == 0) {
          _this3.list = res.data.data;
        }
      });
    },
    handleClear: function handleClear() {
      this.listQuery.dictName = '';
      this.getList();
    },
    handleFilter: function handleFilter() {
      this.getList();
    },
    openEdit: function openEdit(item, isEdit) {
      this.$refs.editDictionary.open((0, _objectSpread2.default)((0, _objectSpread2.default)({}, item), {}, {
        isEdit: isEdit
      }));
    },
    handleClose: function handleClose() {
      this.dialogVisible = false;
    },
    openLink: function openLink(item) {
      var _this4 = this;
      this.currentId = item.id;
      this.dialogVisible = true;
      this.name = item.name;
      (0, _dictionary.getCategoryApi)({
        id: item.id
      }).then(function (res) {
        if (res.data.errno == 0) {
          _this4.categoryList = res.data.data;
          _this4.categoryShow();
        }
      });
    },
    onCategorySure: function onCategorySure() {
      var _this5 = this;
      var newList = this.categoryList.map(function (_ref) {
        var Id2List = _ref.Id2List,
          Id3List = _ref.Id3List,
          rest = (0, _objectWithoutProperties2.default)(_ref, _excluded);
        return rest;
      });
      var params = {
        id: this.currentId,
        categoryList: newList
      };
      (0, _dictionary.setCategoryApi)(params).then(function (res) {
        if (res.data.errno == 0) {
          _this5.$message({
            message: '设置类目成功',
            type: 'success'
          });
          _this5.getList();
          _this5.dialogVisible = false;
        }
      });
    },
    add: function add() {
      this.categoryList.push({
        category1Id: '',
        category2Id: '',
        category3Id: '',
        Id2List: [],
        Id3List: []
      });
    },
    onDelete: function onDelete(item, index) {
      console.log(index);
      this.categoryList.splice(index, 1);
    }
  }
};