"use strict";

var _interopRequireDefault = require("/var/jenkins_home/workspace/sihong_test_front_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.currencyListUrl = currencyListUrl;
exports.currencyRatesListUrl = currencyRatesListUrl;
exports.currencyRatesTodayUrl = currencyRatesTodayUrl;
exports.refreshCurrencyRatesUrl = refreshCurrencyRatesUrl;
exports.updateCurrencyRatesTodayUrl = updateCurrencyRatesTodayUrl;
var _request = _interopRequireDefault(require("@/utils/request"));
function currencyListUrl(query) {
  return (0, _request.default)({
    url: '/currency/currencyList',
    method: 'get',
    params: query
  });
}
function currencyRatesListUrl(query) {
  return (0, _request.default)({
    url: '/currency/currencyRatesList',
    method: 'get',
    params: query
  });
}
function currencyRatesTodayUrl(query) {
  return (0, _request.default)({
    url: '/currency/currencyRatesToday',
    method: 'get',
    params: query
  });
}
function refreshCurrencyRatesUrl(query) {
  return (0, _request.default)({
    url: '/currency/refreshCurrencyRates',
    method: 'get',
    params: query
  });
}
function updateCurrencyRatesTodayUrl(data) {
  return (0, _request.default)({
    url: '/currency/updateCurrencyRatesToday',
    method: 'post',
    data: data
  });
}