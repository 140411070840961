"use strict";

var _interopRequireDefault = require("/var/jenkins_home/workspace/sihong_test_front_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.aftersaleOffline = aftersaleOffline;
exports.batchReceptAftersale = batchReceptAftersale;
exports.batchRejectAftersale = batchRejectAftersale;
exports.detailAftersale = detailAftersale;
exports.getRefund = getRefund;
exports.listAftersale = listAftersale;
exports.recept = recept;
exports.receptAftersale = receptAftersale;
exports.refundAftersale = refundAftersale;
exports.refundDetail = refundDetail;
exports.refundList = refundList;
exports.refundRefund = refundRefund;
exports.reject = reject;
exports.rejectAftersale = rejectAftersale;
exports.rejectAndShip = rejectAndShip;
var _request = _interopRequireDefault(require("@/utils/request"));
function listAftersale(query) {
  return (0, _request.default)({
    url: '/aftersale/list',
    method: 'get',
    params: query
  });
}
function receptAftersale(data) {
  return (0, _request.default)({
    url: '/aftersale/recept',
    method: 'post',
    data: data
  });
}
function batchReceptAftersale(data) {
  return (0, _request.default)({
    url: '/aftersale/batch-recept',
    method: 'post',
    data: data
  });
}
function rejectAftersale(data) {
  return (0, _request.default)({
    url: '/aftersale/reject',
    method: 'post',
    data: data
  });
}
function batchRejectAftersale(data) {
  return (0, _request.default)({
    url: '/aftersale/batch-reject',
    method: 'post',
    data: data
  });
}
function refundAftersale(data) {
  return (0, _request.default)({
    url: '/aftersale/refund',
    method: 'post',
    data: data
  });
}
function detailAftersale(data) {
  return (0, _request.default)({
    url: '/aftersale/detail',
    method: 'post',
    data: data
  });
}
function rejectAndShip(data) {
  return (0, _request.default)({
    url: '/aftersale/rejectAndShip',
    method: 'post',
    data: data
  });
}
function refundList(data) {
  return (0, _request.default)({
    url: '/refund/list',
    method: 'post',
    data: data
  });
}
function refundDetail(data) {
  return (0, _request.default)({
    url: '/refund/detail',
    method: 'post',
    data: data
  });
}
function getRefund(data) {
  return (0, _request.default)({
    url: '/refund/getRefund',
    method: 'post',
    data: data
  });
}
function refundRefund(data) {
  return (0, _request.default)({
    url: '/refund/refund',
    method: 'post',
    data: data
  });
}
function aftersaleOffline(params) {
  return (0, _request.default)({
    url: '/aftersaleOffline/list',
    method: 'get',
    params: params
  });
}
function recept(data) {
  return (0, _request.default)({
    url: '/aftersaleOffline/recept',
    method: 'post',
    data: data
  });
}
function reject(data) {
  return (0, _request.default)({
    url: '/aftersaleOffline/reject',
    method: 'post',
    data: data
  });
}