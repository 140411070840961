var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "detail",
      attrs: {
        top: "6vh",
        visible: _vm.dialogVisible,
        width: "80%",
        "before-close": _vm.handleClose,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-row",
        [
          _c("el-col", { attrs: { span: _vm.col } }, [
            _c("span", [_vm._v("商品款号")]),
            _vm._v(" "),
            _c("span", [_vm._v(_vm._s(_vm.basic.styleNo))]),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: _vm.col } }, [
            _c("span", [_vm._v("商品名称")]),
            _vm._v(" "),
            _c("span", [
              _vm._v(_vm._s(_vm.multiLanguageDisplay(_vm.basic.goodsName))),
            ]),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: _vm.col } }, [
            _c("span", [_vm._v("商品品牌")]),
            _vm._v(" "),
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.basic.brandName
                    ? _vm.multiLanguageDisplay(_vm.basic.brandName)
                    : ""
                )
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: _vm.col } }, [
            _c("span", [_vm._v("商品单位")]),
            _vm._v(" "),
            _c("span", [_vm._v(_vm._s(_vm.basic.unit))]),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        [
          _c("el-col", { attrs: { span: _vm.col } }, [
            _c("span", [_vm._v("商品类别")]),
            _vm._v(" "),
            _c("span", [
              _vm._v(_vm._s(_vm.multiLanguageDisplay(_vm.basic.categoryName))),
            ]),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: _vm.col } }, [
            _c("span", [_vm._v("商品颜色")]),
            _vm._v(" "),
            _c("span", [
              _vm._v(_vm._s(_vm.multiLanguageDisplay(_vm.basic.color))),
            ]),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: _vm.col } }, [
            _c("span", [_vm._v("商品尺码")]),
            _vm._v(" "),
            _c("span", [
              _vm._v(_vm._s(_vm.multiLanguageDisplay(_vm.basic.size))),
            ]),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: _vm.col } }, [
            _c("span", [_vm._v("商品数量")]),
            _vm._v(" "),
            _c("span", [_vm._v(_vm._s(_vm.basic.inventoryCount))]),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "detailList" },
        _vm._l(_vm.basic.inventoryDetailList, function (item, index) {
          return _c(
            "el-row",
            { key: index },
            [
              _c("el-col", { attrs: { span: _vm.col } }, [
                _c("span", [_vm._v("入库仓库")]),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(item.warehouseName))]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: _vm.col } }, [
                _c("span", [_vm._v("库位号")]),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(item.locationName))]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: _vm.col } }, [
                _c("span", [_vm._v("商品数量")]),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(item.inventoryCount))]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: _vm.col } }, [
                _c("span", [_vm._v("借出数量")]),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(item.borrowCount))]),
              ]),
            ],
            1
          )
        }),
        1
      ),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v("确 认")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }