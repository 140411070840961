"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _multiLanguageDisplay = require("@/utils/multiLanguageDisplay");
var _aftersale = require("@/api/aftersale");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: {
    id: String | Number,
    dialogVisible: Boolean
  },
  data: function data() {
    return {
      refund: {
        fee: '',
        refundAmount: '',
        totalAmount: ''
      }
    };
  },
  mounted: function mounted() {
    this.handleRefund();
  },
  methods: {
    multiLanguageDisplay: _multiLanguageDisplay.multiLanguageDisplay,
    cancel: function cancel() {
      this.$emit("closeDialog", false);
    },
    // 获取金额
    handleRefund: function handleRefund() {
      var _this = this;
      (0, _aftersale.getRefund)({
        id: this.id
      }).then(function (data) {
        var res = data.data;
        if (res.errno == 0) {
          _this.refund = res.data;
        }
      });
    },
    submitOrderRefund: function submitOrderRefund() {
      var _this2 = this;
      (0, _aftersale.refundRefund)({
        id: this.id
      }).then(function (data) {
        var res = data.data;
        if (res.errno == 0) {
          _this2.$emit("closeDialog", true);
        }
      });
    }
  }
};