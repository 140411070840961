"use strict";

var _interopRequireDefault = require("/var/jenkins_home/workspace/sihong_test_front_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectWithoutProperties2 = _interopRequireDefault(require("/var/jenkins_home/workspace/sihong_test_front_mall/node_modules/@babel/runtime-corejs2/helpers/objectWithoutProperties.js"));
var _objectSpread2 = _interopRequireDefault(require("/var/jenkins_home/workspace/sihong_test_front_mall/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _moment = _interopRequireDefault(require("moment"));
var _warehouse = require("@/api/warehouse");
var _multiLanguageDisplay = require("@/utils/multiLanguageDisplay");
var _excluded = ["submit", "batchNo", "inoutId", "locationId"]; //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'MallInStorage',
  data: function data() {
    return {
      multiLanguageDisplay: _multiLanguageDisplay.multiLanguageDisplay,
      col: 6,
      dialogVisible: false,
      typeList: [],
      //入库类型
      wareHouseList: [],
      //入库仓库
      sourceList: [],
      //商品来源
      list: [{
        styleNo: '',
        goodsName: '',
        brandName: '',
        unit: '',
        categoryName: '',
        productList: []
      }],
      locationList: [],
      oldList: [],
      styleList: [],
      //商品款号

      form: {
        date: (0, _moment.default)().format('YYYY-MM-DD'),
        typeId: '',
        warehouseId: '',
        inoutId: '',
        //库位号
        sourceId: '',
        remark: '',
        locationId: '',
        submit: '默认账户名',
        batchNo: '系统自动生成'
      },
      rules: {
        date: [{
          required: true,
          message: ' ',
          trigger: 'blur'
        }],
        typeId: [{
          required: true,
          message: ' ',
          trigger: 'change'
        }],
        warehouseId: [{
          required: true,
          message: ' ',
          trigger: 'change'
        }],
        inoutId: [{
          required: true,
          message: ' ',
          trigger: 'blur'
        }],
        submit: [{
          required: true,
          message: ' ',
          trigger: 'blur'
        }],
        batchNo: [{
          required: true,
          message: ' ',
          trigger: 'blur'
        }]
      }
    };
  },
  mounted: function mounted() {},
  computed: {
    total: function total() {
      if (this.list.length == 0) return 0;
      return this.list.reduce(function (total, item) {
        var productList = Array.isArray(item.productList) ? item.productList : [];
        return total + productList.reduce(function (sum, product) {
          return sum + (typeof product.num === 'number' ? product.num : 0);
        }, 0);
      }, 0);
    }
  },
  methods: {
    init: function init() {
      this.list = [{
        styleNo: '',
        goodsName: '',
        brandName: '',
        unit: '',
        categoryName: '',
        productList: []
      }];
      this.form = {
        date: (0, _moment.default)().format('YYYY-MM-DD'),
        typeId: '',
        warehouseId: '',
        inoutId: '',
        //库位号
        sourceId: '',
        remark: '',
        submit: '默认账户名',
        batchNo: '系统自动生成'
      };
    },
    // 
    getLocation: function getLocation(val) {
      var _this = this;
      (0, _warehouse.listLocationListApi)({
        warehouseId: val
      }).then(function (res) {
        _this.locationList = res.data.data;
      });
    },
    onWareSelect: function onWareSelect(val) {
      this.getLocation(val);
    },
    getInType: function getInType() {
      var _this2 = this;
      var data = {
        type: 1
      };
      (0, _warehouse.operationApi)(data).then(function (data) {
        var res = data.data;
        if (res.errno == 0) {
          _this2.typeList = res.data;
        }
      });
    },
    // 仓库列表
    getHouse: function getHouse() {
      var _this3 = this;
      var data = {};
      (0, _warehouse.warehouseListApi)(data).then(function (data) {
        var res = data.data;
        if (res.errno == 0) {
          _this3.wareHouseList = res.data;
        }
      });
    },
    // 商品来源
    getSource: function getSource() {
      var _this4 = this;
      var data = {};
      (0, _warehouse.sourceApi)(data).then(function (data) {
        var res = data.data;
        if (res.errno == 0) {
          _this4.sourceList = res.data;
        }
      });
    },
    // 款号列表
    getStyleList: function getStyleList() {
      var _this5 = this;
      var data = {
        styleNo: ''
      };
      (0, _warehouse.syleleListApi)(data).then(function (data) {
        var res = data.data;
        if (res.errno == 0) {
          _this5.styleList = res.data;
        }
      });
    },
    // 根据款号查找规格
    getSpeApi: function getSpeApi(val, index) {
      var _this6 = this;
      var data = {
        brandName: '',
        categoryName: '',
        goodsId: '',
        goodsName: '',
        productList: [],
        styleNo: val,
        unit: ''
      };
      (0, _warehouse.querySpeApi)(data).then(function (data) {
        var res = data.data;
        console.log('res', res);
        res.data.productList.forEach(function (item) {
          item.locationName = _this6.form.inoutId;
        });
        _this6.$set(_this6.list, index, res.data);
      });
    },
    selectStyle: function selectStyle(styleNo, index) {
      this.getSpeApi(styleNo, index);
    },
    open: function open() {
      this.dialogVisible = true;
      this.init();
      this.getInType(); //入库类型
      this.getHouse(); //入库仓库
      this.getSource(); //商品来源
      this.getStyleList(); //款号列表
    },
    // blurWareNumber(val) {
    //   this.list.forEach(item => {
    //     if (item.productList) {
    //       item.productList.forEach(citem => {
    //         citem.locationName = val.target.value
    //       })
    //     }
    //   })
    // },
    onSelect: function onSelect(val) {
      var newVal = this.locationList.filter(function (item) {
        return item.id == val;
      });
      var publicVal = '';
      if (newVal.length == 0) {
        publicVal = val;
        this.form.locationId = '';
      } else if (newVal.length != 0) {
        this.form.locationId = val;
        publicVal = newVal[0].name;
      }
      this.form.inoutId = publicVal;

      // this.list.forEach(item => {
      //   if (item.productList) {
      //     item.productList.forEach(citem => {
      //       citem.locationName = val.target.value
      //     })
      //   }
      // })
      this.list.forEach(function (item) {
        if (item.productList) {
          item.productList.forEach(function (citem) {
            citem.locationName = publicVal;
          });
        }
      });
    },
    handleClose: function handleClose() {
      this.dialogVisible = false;
    },
    addNewList: function addNewList() {
      this.list.unshift({
        styleNo: '',
        goodsName: '',
        brandName: '',
        unit: '',
        categoryName: ''
      });
    },
    sure: function sure() {
      var _this7 = this;
      this.$refs.ruleForm.validate(function (valid) {
        if (valid) {
          // let status = false; // 用于记录是否找到空字段
          // this.list.forEach(item => {
          //   const fields = ['styleNo', 'goodsName', 'brandName', 'unit', 'categoryName'];
          //   const allFieldsFilled = fields.every(field => item[field]);
          //   if (!allFieldsFilled) {
          //     this.$message.warning('请填写必填项')
          //     status=true
          //     return false
          //   }
          // })
          // if(status) return false
          var newProductList = _this7.list.flatMap(function (item) {
            return item.productList.map(function (product) {
              return {
                location: product.locationName,
                num: product.num,
                productId: product.productId,
                inoutDetailId: item.inoutDetailId,
                remove: item.remove ? item.remove : false
              };
            });
          });

          // 过滤掉num为0的
          var isNumNewProductList = newProductList.filter(function (item) {
            return item.num != 0;
          }).map(function (citem) {
            return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, citem), {}, {
              locationId: _this7.form.locationId
            });
          });
          console.log('isNumNewProductList', isNumNewProductList);
          if (isNumNewProductList.length == 0) return;
          var _this7$form = _this7.form,
            submit = _this7$form.submit,
            batchNo = _this7$form.batchNo,
            inoutId = _this7$form.inoutId,
            locationId = _this7$form.locationId,
            data = (0, _objectWithoutProperties2.default)(_this7$form, _excluded);
          var params = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, data), {}, {
            productList: isNumNewProductList
          });
          (0, _warehouse.inboundApi)(params).then(function (data) {
            var res = data.data;
            if (res.errno == 0) {
              _this7.dialogVisible = false;
              _this7.$message.success('操作成功');
            }
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    onDelete: function onDelete(index, item) {
      this.list[index].productList.forEach(function (item) {
        item.num = 0;
      });
      this.$set(this.list[index], 'remove', true);
    }
  }
};