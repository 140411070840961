"use strict";

var _interopRequireDefault = require("/var/jenkins_home/workspace/sihong_test_front_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.activateSelected = activateSelected;
exports.createSelected = createSelected;
exports.deleteSelected = deleteSelected;
exports.fetchList = fetchList;
exports.goodsSelectedList = goodsSelectedList;
exports.selectedCompanyDetail = selectedCompanyDetail;
exports.selectedDetail = selectedDetail;
exports.selectedGoodsAdd = selectedGoodsAdd;
exports.selectedGoodsDelete = selectedGoodsDelete;
exports.selectedGoodsList = selectedGoodsList;
exports.selectedGoodsSort = selectedGoodsSort;
exports.selectedUserAdd = selectedUserAdd;
exports.selectedUserDelete = selectedUserDelete;
exports.selectedUserList = selectedUserList;
exports.updateSelected = updateSelected;
exports.updateSelectedCompany = updateSelectedCompany;
exports.userList = userList;
var _request = _interopRequireDefault(require("@/utils/request"));
function fetchList(query) {
  return (0, _request.default)({
    url: '/selected/list',
    method: 'get',
    params: query
  });
}
function createSelected(data) {
  return (0, _request.default)({
    url: '/selected/create',
    method: 'post',
    data: data
  });
}
function deleteSelected(data) {
  return (0, _request.default)({
    url: '/selected/delete',
    method: 'post',
    data: data
  });
}
function activateSelected(data) {
  return (0, _request.default)({
    url: '/selected/activate',
    method: 'post',
    data: data
  });
}
function updateSelected(data) {
  return (0, _request.default)({
    url: '/selected/update',
    method: 'post',
    data: data
  });
}
function selectedDetail(data) {
  return (0, _request.default)({
    url: '/selected/detail',
    method: 'post',
    data: data
  });
}
function selectedGoodsList(query) {
  return (0, _request.default)({
    url: '/selected/goods/list',
    method: 'get',
    params: query
  });
}
function selectedGoodsAdd(data) {
  return (0, _request.default)({
    url: '/selected/goods/add',
    method: 'post',
    data: data
  });
}
function selectedGoodsSort(data) {
  return (0, _request.default)({
    url: '/selected/goods/sort',
    method: 'post',
    data: data
  });
}
function selectedGoodsDelete(data) {
  return (0, _request.default)({
    url: '/selected/goods/delete',
    method: 'post',
    data: data
  });
}
function goodsSelectedList(query) {
  return (0, _request.default)({
    url: '/selected/goods/selectedList',
    method: 'get',
    params: query
  });
}
function selectedUserList(query) {
  return (0, _request.default)({
    url: '/selected/entry/user/list',
    method: 'get',
    params: query
  });
}
function selectedUserAdd(data) {
  return (0, _request.default)({
    url: '/selected/entry/user/add',
    method: 'post',
    data: data
  });
}
function selectedUserDelete(data) {
  return (0, _request.default)({
    url: '/selected/entry/user/delete',
    method: 'post',
    data: data
  });
}
function userList(query) {
  return (0, _request.default)({
    url: '/selected/entry/user/selectedList',
    method: 'get',
    params: query
  });
}
function selectedCompanyDetail(data) {
  return (0, _request.default)({
    url: '/selected/company/detail',
    method: 'post',
    data: data
  });
}
function updateSelectedCompany(data) {
  return (0, _request.default)({
    url: '/selected/company/update',
    method: 'post',
    data: data
  });
}