"use strict";

var _interopRequireDefault = require("/var/jenkins_home/workspace/sihong_test_front_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.categoryFilterList = categoryFilterList;
exports.createLadderPrice = createLadderPrice;
exports.createLevel = createLevel;
exports.deleteLadderPrice = deleteLadderPrice;
exports.deleteLevel = deleteLevel;
exports.listExpress = listExpress;
exports.listLadderPrice = listLadderPrice;
exports.listLevel = listLevel;
exports.listMall = listMall;
exports.listOrder = listOrder;
exports.listWx = listWx;
exports.updateExpress = updateExpress;
exports.updateLadderPrice = updateLadderPrice;
exports.updateLevel = updateLevel;
exports.updateMall = updateMall;
exports.updateOrder = updateOrder;
exports.updateWx = updateWx;
var _request = _interopRequireDefault(require("@/utils/request"));
function listMall() {
  return (0, _request.default)({
    url: '/config/mall',
    method: 'get'
  });
}
function updateMall(data) {
  return (0, _request.default)({
    url: '/config/mall',
    method: 'post',
    data: data
  });
}
function listExpress() {
  return (0, _request.default)({
    url: '/config/express',
    method: 'get'
  });
}
function updateExpress(data) {
  return (0, _request.default)({
    url: '/config/express',
    method: 'post',
    data: data
  });
}
function listOrder() {
  return (0, _request.default)({
    url: '/config/order',
    method: 'get'
  });
}
function updateOrder(data) {
  return (0, _request.default)({
    url: '/config/order',
    method: 'post',
    data: data
  });
}
function listWx() {
  return (0, _request.default)({
    url: '/config/wx',
    method: 'get'
  });
}
function updateWx(data) {
  return (0, _request.default)({
    url: '/config/wx',
    method: 'post',
    data: data
  });
}
// 等级配置
function listLevel(data) {
  return (0, _request.default)({
    url: '/goods/userlevel/list',
    method: 'POST',
    data: data
  });
}
function createLevel(data) {
  return (0, _request.default)({
    url: '/goods/userlevel/create',
    method: 'POST',
    data: data
  });
}
function updateLevel(data) {
  return (0, _request.default)({
    url: '/goods/userlevel/update',
    method: 'POST',
    data: data
  });
}
function deleteLevel(data) {
  return (0, _request.default)({
    url: '/goods/userlevel/delete',
    method: 'POST',
    data: data
  });
}

// 阶梯价格配置
function listLadderPrice(data) {
  return (0, _request.default)({
    url: '/goods/ladder/list',
    method: 'POST',
    data: data
  });
}
function createLadderPrice(data) {
  return (0, _request.default)({
    url: '/goods/ladder/create',
    method: 'POST',
    data: data
  });
}
function updateLadderPrice(data) {
  return (0, _request.default)({
    url: '/goods/ladder/update',
    method: 'POST',
    data: data
  });
}
function deleteLadderPrice(data) {
  return (0, _request.default)({
    url: '/goods/ladder/delete',
    method: 'POST',
    data: data
  });
}
function categoryFilterList() {
  return (0, _request.default)({
    url: '/categoryFilter/list',
    method: 'get'
  });
}