"use strict";

var _interopRequireDefault = require("/var/jenkins_home/workspace/sihong_test_front_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/var/jenkins_home/workspace/sihong_test_front_mall/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _objectWithoutProperties2 = _interopRequireDefault(require("/var/jenkins_home/workspace/sihong_test_front_mall/node_modules/@babel/runtime-corejs2/helpers/objectWithoutProperties.js"));
var _defineProperty2 = _interopRequireDefault(require("/var/jenkins_home/workspace/sihong_test_front_mall/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _warehouse = require("@/api/warehouse");
var _multiLanguageDisplay = require("@/utils/multiLanguageDisplay");
var _moment = _interopRequireDefault(require("moment"));
var _excluded = ["billNo"]; //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'MallInStorage',
  data: function data() {
    return (0, _defineProperty2.default)({
      multiLanguageDisplay: _multiLanguageDisplay.multiLanguageDisplay,
      editDate: (0, _moment.default)().format('YYYY-MM-DD'),
      col: 6,
      dialogVisible: false,
      rules: {
        date: [{
          required: true,
          message: ' ',
          trigger: 'blur'
        }]
      },
      selectList: [],
      wareHouseList: [],
      list: [],
      form: {
        date: (0, _moment.default)().format('YYYY-MM-DD'),
        remark: '',
        billNo: '',
        inoutId: ''
      },
      typeList: []
    }, "wareHouseList", []);
  },
  mounted: function mounted() {},
  methods: {
    init: function init(info, inoutId) {
      // this.form.date=info.bizDate
      this.form.remark = info.remark;
      this.form.billNo = info.billNo;
      this.form.inoutId = inoutId;
      this.list = info.detailList;
    },
    open: function open(info, inoutId) {
      this.dialogVisible = true;
      this.init(info, inoutId);
      this.getDestination();
    },
    // 出库方向列表
    getDestination: function getDestination() {
      var _this = this;
      var data = {
        typeId: ''
      };
      (0, _warehouse.outboundDestinationApi)(data).then(function (data) {
        var res = data.data;
        if (res.errno == 0) {
          _this.wareHouseList = res.data;
        }
      });
    },
    handleClose: function handleClose() {
      this.dialogVisible = false;
    },
    sure: function sure() {
      var _this2 = this;
      this.$refs.ruleForm.validate(function (valid) {
        if (valid) {
          console.log('this.list', _this2.list);
          var newProductList = _this2.list.map(function (product) {
            return {
              // location: product.locationName,
              locationId: product.locationId,
              num: product.num,
              productId: product.productId,
              destLocation: product.destLocation,
              destWarehouseId: product.wareHouse,
              inoutDetailId: product.inoutDetailId
            };
          });
          var _this2$form = _this2.form,
            billNo = _this2$form.billNo,
            data = (0, _objectWithoutProperties2.default)(_this2$form, _excluded);
          var params = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, data), {}, {
            productList: newProductList
          });
          (0, _warehouse.transferEditApi)(params).then(function (data) {
            var res = data.data;
            if (res.errno == 0) {
              _this2.dialogVisible = false;
              _this2.$message.success('操作成功');
            }
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  }
};