var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "160px" },
            attrs: { clearable: "", placeholder: "请输入商品名称" },
            model: {
              value: _vm.search.goodsName,
              callback: function ($$v) {
                _vm.$set(_vm.search, "goodsName", $$v)
              },
              expression: "search.goodsName",
            },
          }),
          _vm._v(" "),
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "160px" },
            attrs: { clearable: "", placeholder: "请输入商品款号" },
            model: {
              value: _vm.search.styleNo,
              callback: function ($$v) {
                _vm.$set(_vm.search, "styleNo", $$v)
              },
              expression: "search.styleNo",
            },
          }),
          _vm._v(" "),
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "160px" },
            attrs: { clearable: "", placeholder: "请输入商品颜色" },
            model: {
              value: _vm.search.color,
              callback: function ($$v) {
                _vm.$set(_vm.search, "color", $$v)
              },
              expression: "search.color",
            },
          }),
          _vm._v(" "),
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "160px" },
            attrs: { clearable: "", placeholder: "请输入商品尺码" },
            model: {
              value: _vm.search.size,
              callback: function ($$v) {
                _vm.$set(_vm.search, "size", $$v)
              },
              expression: "search.size",
            },
          }),
          _vm._v(" "),
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "160px" },
            attrs: { clearable: "", placeholder: "请输入商品类别" },
            model: {
              value: _vm.search.category,
              callback: function ($$v) {
                _vm.$set(_vm.search, "category", $$v)
              },
              expression: "search.category",
            },
          }),
          _vm._v(" "),
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "160px" },
            attrs: { clearable: "", placeholder: "请输入仓库" },
            model: {
              value: _vm.search.warehouse,
              callback: function ($$v) {
                _vm.$set(_vm.search, "warehouse", $$v)
              },
              expression: "search.warehouse",
            },
          }),
          _vm._v(" "),
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "160px" },
            attrs: { clearable: "", placeholder: "请输入库位号" },
            model: {
              value: _vm.search.location,
              callback: function ($$v) {
                _vm.$set(_vm.search, "location", $$v)
              },
              expression: "search.location",
            },
          }),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: { click: _vm.onSearch },
            },
            [_vm._v("查找")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { type: "primary" },
              on: { click: _vm.onClear },
            },
            [_vm._v("清空")]
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "el-button",
                {
                  staticClass: "filter-item",
                  attrs: { type: "primary" },
                  on: { click: _vm.toInStorage },
                },
                [_vm._v("入库")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "filter-item",
                  attrs: { type: "primary" },
                  on: { click: _vm.toOutStorage },
                },
                [_vm._v("出库")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "filter-item",
                  attrs: { type: "primary" },
                  on: { click: _vm.toTransfer },
                },
                [_vm._v("调拨")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "filter-item",
                  attrs: { type: "primary" },
                  on: { click: _vm.returnList },
                },
                [_vm._v("返回列表")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          attrs: {
            data: _vm.list,
            "element-loading-text": "正在查询中。。。",
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
          on: { "selection-change": _vm.handleSelectionChange },
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", type: "selection", width: "100" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "商品图片" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("el-image", {
                      staticStyle: { height: "65px" },
                      attrs: {
                        src: scope.row.imageUrl,
                        "preview-src-list": [scope.row.imageUrl],
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "商品款号" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.styleNo))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "商品名称",
              "class-name": "small-padding fixed-width",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.multiLanguageDisplay(scope.row.goodsName))
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "商品颜色",
              prop: "color",
              "class-name": "small-padding fixed-width",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.multiLanguageDisplay(scope.row.color))),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "商品尺码",
              "class-name": "small-padding fixed-width",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.multiLanguageDisplay(scope.row.size))),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "仓库",
              prop: "warehouseName",
              "class-name": "small-padding fixed-width",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "库位号",
              prop: "locationName",
              "class-name": "small-padding fixed-width",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "商品库存",
              prop: "inventoryCount",
              "class-name": "small-padding fixed-width",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "操作" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "span",
                      {
                        staticClass: "operate",
                        on: {
                          click: function ($event) {
                            return _vm.lookDetail(scope.row)
                          },
                        },
                      },
                      [_vm._v("查看详情")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total > 0",
          },
        ],
        attrs: { total: _vm.total, page: _vm.pageNo, limit: _vm.pageSize },
        on: {
          "update:page": function ($event) {
            _vm.pageNo = $event
          },
          "update:limit": function ($event) {
            _vm.pageSize = $event
          },
          pagination: _vm.getList,
        },
      }),
      _vm._v(" "),
      _c("InStorage", { ref: "instorage" }),
      _vm._v(" "),
      _c("OutStorage", { ref: "outStorage", on: { init: _vm.init } }),
      _vm._v(" "),
      _c("Transfer", { ref: "transfer", on: { init: _vm.init } }),
      _vm._v(" "),
      _c("HouseDetail", { ref: "houseDetail" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }