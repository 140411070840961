"use strict";

var _interopRequireDefault = require("/var/jenkins_home/workspace/sihong_test_front_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.search");
var _objectSpread2 = _interopRequireDefault(require("/var/jenkins_home/workspace/sihong_test_front_mall/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _multiLanguageDisplay = require("@/utils/multiLanguageDisplay");
var _houseDetail = _interopRequireDefault(require("@/components/wareHouse/houseDetail.vue"));
var _warehouse = require("@/api/warehouse");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    Pagination: _Pagination.default,
    HouseDetail: _houseDetail.default
  },
  data: function data() {
    return {
      multiLanguageDisplay: _multiLanguageDisplay.multiLanguageDisplay,
      search: {
        goodsName: '',
        styleNo: '',
        color: '',
        size: '',
        category: ''
      },
      list: [],
      listLoading: true,
      total: 0,
      pageNo: 1,
      pageSize: 10
    };
  },
  created: function created() {},
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    lookDetail: function lookDetail(row) {
      this.getListDetail(row.productId);
    },
    getListDetail: function getListDetail(productId) {
      var _this = this;
      var data = {
        productId: productId
      };
      (0, _warehouse.inventoryDetailApi)(data).then(function (data) {
        var res = data.data;
        if (res.errno == 0) {
          _this.$refs.houseDetail.open(res.data);
        }
      });
    },
    // 查找
    onSearch: function onSearch() {
      this.pageNo = 1;
      this.getList();
    },
    onClear: function onClear() {
      this.search = {
        goodsName: '',
        styleNo: '',
        color: '',
        size: '',
        category: ''
      };
      this.pageNo = 1;
      this.getList();
    },
    // 进入详情
    toDetail: function toDetail() {
      this.$router.push('/wareHouse/houseListDetail');
    },
    // 获取列表数据
    getList: function getList(val) {
      var _this2 = this;
      console.log(val);
      this.listLoading = true;
      var params = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.search), {}, {
        pageNo: this.pageNo,
        pageSize: this.pageSize
      });
      (0, _warehouse.inventoryListApi)(params).then(function (data) {
        var res = data.data;
        if (res.errno == 0) {
          _this2.listLoading = false, _this2.list = res.data.list;
          _this2.total = res.data.total;
        }
      });
    }
  }
};